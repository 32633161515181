import React,{useEffect,useState} from 'react';

const ActionButton = (props) => {
  let property = props;
  useEffect(()=>{
  },[property.activeStep])

  let handleNextSubmit = () => {
    props.handleNext();
  }

  let handleBackSubmit = () => {
    props.handleBack();
  }

  let handlSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
  }

  return (
    <div className="form-navigation">
      {props.activeStep > 1 ? 
      <React.Fragment>
      <button type="button" id="form_prev" className="previous btn btn-info pull-left form_prev_btn" onClick={handleBackSubmit}>
        Previous
      </button>
      </React.Fragment>:''}
      {props.activeStep != 4 ? 
      <React.Fragment>
      <button type="button" id="form_next" className="next btn btn-info pull-right form_next_btn" onClick={handleNextSubmit}>
        Next
      </button>
      </React.Fragment>:''}
      {props.activeStep == 4 ?
       <React.Fragment><input type="submit" className=
       {props.display? "btn btn-default pull-right form_next_btn disabled" :"btn btn-default pull-right form_next_btn"}
       onClick= {(e) => handlSubmit(e)}  />
       <span className="clearfix"></span></React.Fragment> : ''}
    </div>
  );
};

export default ActionButton;

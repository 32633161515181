import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ReplacementAndSaving from './ReplacementAndSaving';
import LandedTariff from './LandedTariff';
import UserDetails from './UserDetails';
import Assumptions from './Assumptions';
import * as queryString from 'query-string';
import * as CryptoJS from 'crypto-js';
import axios from '../../services/auth/config';
import { PDFViewer,PDFDownloadLink } from '@react-pdf/renderer';
import OutputFormPdf from '../OutputFormPdf/OutputFormPdf';
import { Circles } from 'react-loader-spinner';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{height:'460px'}}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const [strin, setStr] = useState();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [showLoader, setLoader] = useState(true);
  const [topMargin, setTopMargin] = useState('0px');
  // const [checkoutput, setCheckoutput] = useState(0);
  const [checkoutput, setCheckoutput] = useState(1);

  let params = queryString.parse(window.location.search);
  let str = decodeURIComponent(params.str);
    useEffect(() => {
      if(params.checkoutput) {
        setCheckoutput(1);
      }
      setInterval(() => {
        setIsDisplayed(true);
      }, 1000);
    }, []);
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(str, 'adsfghlkjwasdxfgyuiolkjhgfdsfghjklmjnb');
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  axios.post('/formdata/fetch', { user_id: decryptedData.uid }).then(({ data }) => {
    if (data.status == true) {
      setStr(data.data[0].output_url);
    }
  });
  let param = queryString.parse(strin);
  let stri = decodeURIComponent(param['/reform/result?str']);
  const StyledTabs = styled(props => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        style: { height: 7 },
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 400,
      width: '100%',

      backgroundColor: '#235F2E',
    },
  });
  const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }));

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const [showStyle,setShowStyle] = useState('block');

  const displayStyle = () => {
    setShowStyle('none');
    setLoader(false);
    setTopMargin('50px');
  }
  if (checkoutput == 1 && stri != str) {
    return (
      <Typography sx={{ m: 3, mt: 2 }} align="center" color="red" variant="h4" gutterBottom>
      {isDisplayed &&
        <>
        This Page is not Accessible anymore.
        </>
      }
      </Typography> 
    );
  } else if(checkoutput == 1) {
    return (
      <div style={{marginTop: topMargin}}>

        {showLoader == true ? (
        <div
          style={{
            position: 'absolute',
            left: '0%',
            zIndex: 3,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'lightGrey',
          }}>
          <Circles
            height="80"
            width="80"
            // color="#4fa94d"
            color="#72BF44"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        ):(
          <PDFDownloadLink document={<OutputFormPdf decryptedData={decryptedData} />} fileName="output.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <p style={{float: 'right', marginRight: '200px'}}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="Green" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg></p>)}
          </PDFDownloadLink>
          // <PDFViewer width="1000" height="600" className="app" >
          // <OutputFormPdf decryptedData={decryptedData} > </OutputFormPdf>
          // </PDFViewer>
         )}
        <div style={{display: showStyle}}>
          <ReplacementAndSaving decryptedData={decryptedData} displayStyle={displayStyle} showStyle={showStyle} />
        </div>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 20,
            width: '75%',
            borderRadius: '10px',
            alignSelf: 'center',
            height: '550px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
          <AppBar style={{ background: '#378243' }} sx={{ borderRadius: '10px' }} position="static">
            <StyledTabs value={value} onChange={handleChange} textColor="inherit" variant="fullWidth">
              <Tab
                sx={{
                  fontSize: '15px',
                  fontWeight: 'small',
                  textTransform: 'none',
                }}
                label="User Details"
                {...a11yProps(0)}
              />
              <Tab
                style={{ color: 'white' }}
                sx={{
                  fontSize: '15px',
                  fontWeight: 'small',
                  textTransform: 'none',
                }}
                label="Landed Tariff"
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  fontSize: '15px',
                  fontWeight: 'small',
                  textTransform: 'none',
                }}
                label="Replacement & Savings"
                {...a11yProps(2)}
              />
              <Tab
                sx={{
                  fontSize: '15px',
                  fontWeight: 'small',
                  textTransform: 'none',
                }}
                label="Assumptions"
                {...a11yProps(3)}
              />
              {/* <Tab sx={{ fontSize: '18px', fontWeight: 'bold' }} label="Output 4" {...a11yProps(3)} /> */}
            </StyledTabs>
          </AppBar>
          <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <UserDetails decryptedData={decryptedData} />  
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <LandedTariff decryptedData={decryptedData} /> 
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <ReplacementAndSaving decryptedData={decryptedData} displayStyle={displayStyle} showStyle={showStyle} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <Assumptions />
            </TabPanel>
          </SwipeableViews>
        </Box>      
      </div>
    );
  }else{
    return(
    <div>
      <img src="\imagesNew\maintenance.jpg" style={{width:'50%', marginLeft: 'auto',
       marginRight:'auto', marginTop: '10%', display: 'block'}}></img>
    </div>
    );
  }
}

import React,{useEffect,useState} from 'react';

const UserDetails = (props) => {
    const [activeClassName, setActiveClassName] = useState();
    const [form, setForm] = useState({});
    const [getDesignation, setDesignation] = React.useState({});
    const [designationToggle, setDesignationToggle] = useState(false);
    const [getSelectedDesignation, setSelectedDesignation] = useState('');
    let property = props;

    useEffect(() => {
        setActiveClassName('form-section '+ props.active)
        if(!!props.apiData.designations) {
          setDesignation(props.apiData.designations);
        }
    }, [property.active]);

    const setField = (field, value) => {
      setForm({
        ...form,
        [field]: value,
      });
      props.handleData(field,value);
    };

    const handleDropDown = (field,value,text) =>{
      if(field == 'designation') {
          setSelectedDesignation(text);
      }
      setDesignationToggle(false);
      setField(field,value);
  }
    return (
      <div className={activeClassName}>
        <h2 className="form_section_head">User Details</h2>
        <div className="form_wrapper_section_grid">
          <div className="form_wrapper_section_col" id="form_renew">
            <div className="label_lg">
              <img src={"../../imagesNew/calc_form_ico1.svg"} />
              User Name
            </div>
            <label htmlFor="firstname">FULL NAME</label>
            <input type="text" id="name_field" placeholder="Enter Full Name"
              className="form-control"
              value = {!!props.data.full_name ? props.data.full_name : form.full_name}
              onChange= {e => setField('full_name',e.target.value)}
             />
             <span className="errormessage">{props.error.full_name}</span>
          </div>

          <div className="form_wrapper_section_col">
            <div className="label_lg">
              <img src={"../../imagesNew/calc_form_ico1.svg"} />
              Designation
            </div>
            <label htmlFor="firstname">SELECT DESIGNATION</label>

            <div className="select-dropdown">
              <button  role="button" data-value="" className="select-dropdown__button"
              onClick={(e) => {e.preventDefault(); setDesignationToggle(!designationToggle)}} >
                <span className={!!getSelectedDesignation? 'place_custom2 place_custom_dark2': 'place_custom'}>{!!getSelectedDesignation? getSelectedDesignation: 'Select Designation'} </span> <i className="zmdi zmdi-chevron-down"></i>
                <div className="drop_cust_chev">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10.333" height="5.545" viewBox="0 0 10.333 5.545">
                    <path
                      id="Polygon_44"
                      data-name="Polygon 44"
                      d="M5.167,0l5.167,5.545H0Z"
                      transform="translate(10.333 5.545) rotate(180)"
                      fill="#707070"
                    />
                  </svg>
                </div>
              </button>
              {designationToggle&&<ul className="select-dropdown__list active"
              onClick={(e) => {handleDropDown('designation', e.target.value, e.target.id)}}>
                  {getDesignation.map((type, index) => {
                    return <li value={type.id} id={type.name} className={type.name == getSelectedDesignation? "select-dropdown__list-item active": "select-dropdown__list-item"}>
                      {type.name}
                    </li>;
                  })}
              </ul>}
            </div>
            <span className="errormessage">{props.error.designation}</span>
          </div>

          <div className="form_wrapper_section_col" id="form_renew">
            <div className="label_lg">
              <img src={"../../imagesNew/calc_form_ico1.svg"} />
              Contact Number
            </div>
            <label htmlFor="firstname">MOBILE NO</label>
            <input type="text" id="name_field" placeholder="xxxxxxxxxx"
              className="form-control"
              value = {!!props.data.mobile_no ? props.data.mobile_no : form.mobile_no}
              onChange= {e => setField('mobile_no',e.target.value)}
              />
              <span className="errormessage">{props.error.mobile_no}</span>
          </div>

          <div className="form_wrapper_section_col" id="form_renew">
            <div className="label_lg">
              <img src={"../../imagesNew/calc_form_ico1.svg"} />
              Contact Email
            </div>
            <label htmlFor="firstname">OFFICIAL EMAIL ID</label>
            <input type="text" id="name_field" placeholder="Enter Email ID"
              className="form-control"
              value = {!!props.data.email ? props.data.email : form.email}
              onChange= {e => setField('email',e.target.value)} />
            <span className="errormessage">{props.error.email}</span>
          </div>
        </div>
      </div>
    );
};

export default UserDetails;

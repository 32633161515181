import React, { memo } from 'react';
import Modal from '@material-ui/core/Modal';
import OEMListFormPopup from './Addform/OEMListFormPopup';
import AddEditUser from '../../routes/UserManagement/AddEditUser';
//import AddGroup from 'routes/GroupManagement/AddGroup';
//import AddEditCompany from 'routes/CompanyManagement/AddEditCompany';
import OptimaxFilter from '../UI/OptimaxFilter';
import AssignTicket from '../../routes/UserManagement/AssignTicket';
import RecommendationFilter from '../UI/RecommendationFilter';
import AddTag from '../../routes/UserManagement/AddTag';
import AddDataUpload from '../../routes/UserManagement/AddDataUpload';

const CustomModal = props => {
  const handleCallback = childData => {
    props.setVisible(false);
  };
  const handleCallbackOnClose = () => {
    props.setVisible(false);
  };

  return (
    <React.Fragment>
      <Modal open={props.visible}>
        {(() => {
          switch (props.scope) {
            case 'onboarding':
              return <OEMListFormPopup parentCallback={handleCallback} />;
            case 'add-user':
              return (
                <AddEditUser
                  action={props.action}
                  userId={props.userId}
                  open={props.visible}
                  onCloseDialog={handleCallbackOnClose}
                  setdata={props.setdata}
                  setcardData={props.setcardData}
                  type={props.type}
                  generatetableData={props.generatetableData}
                />
              );
            case 'optimax-filter':
              return (
                <OptimaxFilter
                  action={props.action}
                  userId={props.userId}
                  open={props.visible}
                  onRangeSave={props.onRangeSave}
                  chipFilterData={props.chipFilterData}
                  onCloseDialog={props.handleCallbackOnClose}
                  isDetailView={props.isDetailView}
                />
              );
            case 'recommendation-filter':
              return (
                <RecommendationFilter
                  action={props.action}
                  userId={props.userId}
                  open={props.visible}
                  onRangeSave={props.onRangeSave}
                  chipFilterData={props.chipFilterData}
                  onCloseDialog={props.handleCallbackOnClose}
                  isDetailView={props.isDetailView}
                />
              );
            // case 'add-group':
            //   return (
            //     <AddGroup
            //       action={props.action}
            //       groupId={props.groupId}
            //       open={props.visible}
            //       onCloseDialog={props.setVisible}
            //       setdata={props.setdata}
            //       setcardData={props.setcardData}
            //     />
            //   );
            case 'add-tag':
              return (
                <AddTag
                  action={props.action}
                  groupId={props.groupId}
                  open={props.visible}
                  onCloseDialog={props.setVisible}
                  setdata={props.setdata}
                  setcardData={props.setcardData}
                  callbackFunc={props.callbackFunc}
                />
              );
            // case 'add-company':
            //   return (
            //     <AddEditCompany
            //       action={props.action}
            //       companyId={props.companyId}
            //       open={props.visible}
            //       onCloseDialog={props.setVisible}
            //       setdata={props.setdata}
            //       type={props.type}
            //     />
            //   );
            case 'assign-ticket':
              return (
                <AssignTicket
                  action={props.action}
                  userId={props.userId}
                  open={props.visible}
                  onCloseDialog={props.handleCallbackOnClose}
                  ticketDataName={props.ticketDataName}
                  ticketDataIds={props.ticketDataIds}
                  setdata={props.setdata}
                />
              );
            case 'add-data-upload':
              return (
                <AddDataUpload
                  action={props.action}
                  companyId={props.companyId}
                  open={props.visible}
                  onCloseDialog={props.setVisible}
                  setdata={props.setdata}
                  type={props.type}
                />
              );
            default:
              return <div />;
          }
        })()}
      </Modal>
    </React.Fragment>
  );
};

export default memo(CustomModal);

import React, { useEffect, useState, Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import axios from '../../services/auth/config';
const borderColor = '#fff';

const styles = StyleSheet.create({
  container2: {
    flexDirection: 'row',
    borderBottomColor: '#6c757d',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 60,
    textAlign: 'center',
    color: '#6c757d',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  container3: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    backgroundColor: '#378243',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 30,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    marginBottom: 20,
    width: '100%',
  },
  topHead: {
    height: 20,
    width: '100%',
  },
  row1: {
    wordBreak: 'break-all',
  },
});

const Assumptions = () => {
  return (
    <Fragment>
      <View style={styles.container3}>
        <Text style={styles.topHead}>Assumptions</Text>
      </View>
      <View style={styles.container2}>
        <Text style={styles.row1}>* Please note that Power Purchase Agreement assumes a tenure of 25 years.</Text>
      </View>
      <View style={styles.container2}>
        <Text style={styles.row1}>
          * To learn more about Captive Investment, please contact the team at go.green@renew.com.
        </Text>
      </View>
      <View style={styles.container2} wrap={true}>
        <Text style={styles.row1}>
          * Carbon Emission Factor of 930.5g per kWh has been considered.Source:CEA database, Version 17.0
        </Text>
      </View>
    </Fragment>
  );
};

export default Assumptions;

import React, { useEffect, useState } from 'react';
import Company from './Company';
import TechDetail1 from './TechnicalDetail1';
import TechDetail2 from './TechnicalDetail2';
import UserDetails from './UserDetails';
import ActionButton from './ActionButton';
import axios from '../../services/auth/config';
import PreResult from '../../routes/preResult';
import { isPositiveNumber } from '../../constant/constant';
import { Circles } from 'react-loader-spinner';

const Main = prp => {
  const [activeStep, setActiveStep] = useState(1);
  let [companyTab, setCompanyTab] = useState('');
  let [tech1Tab, setTech1Tab] = useState('');
  let [tech2Tab, setTech2Tab] = useState('');
  let [userTab, setUserTab] = useState('');
  let [formData, setFormData] = useState({});
  let [errors, setErrors] = useState({});
  let [apiData, setApiData] = useState({});
  const [id, setId] = useState();
  let [compnt, setCompnt] = useState();
  const [showLoader, setLoader] = useState(false);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    axios.get('getformmasterdata', { params: { master_data_type: 0 } }).then(({ data }) => {
      setApiData(data.data);
      renderActiveTab(activeStep);
    });
  }, []);

  const renderActiveTab = step => {
    switch (step) {
      case 1:
        setCompanyTab('current');
        setTech1Tab('');
        setTech2Tab('');
        setUserTab('');
        break;
      case 2:
        setCompanyTab('');
        setTech1Tab('current');
        setTech2Tab('');
        setUserTab('');
        break;
      case 3:
        setCompanyTab('');
        setTech1Tab('');
        setTech2Tab('current');
        setUserTab('');
        break;
      case 4:
        setCompanyTab('');
        setTech1Tab('');
        setTech2Tab('');
        setUserTab('current');
        break;
    }
  };

  let handleNext = () => {
    let currentStep = activeStep;
    let result = validateFormData(currentStep);
    if (Object.keys(result).length > 0) {
      setErrors(result);
    } else {
      setErrors(result);
      prp.handleStepComplition(activeStep);
      setActiveStep(activeStep + 1);
      renderActiveTab(currentStep + 1);
    }
  };

  let handleBack = () => {
    let currentStep = activeStep;
    setActiveStep(activeStep - 1);
    renderActiveTab(currentStep - 1);
  };

  let handleInputData = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const callThirdPartyApi = async () => {
    try {
      setFormData({
        // Updating Formdata
        ...formData,
      });
      let updatedFormData;
      axios
        .post('api/getPayloadWithLabel', {
          data: formData,
        })
        .then(({ data }) => {
          updatedFormData = data;
          axios
            .post('api/thirdpartyapi', {
              data: updatedFormData,
            })
            .then(({ data }) => {
              if (data.status) {
                let resp = data.data;
                formData['tarifs'] = resp;
                setFormData({
                  // Updating Formdata
                  ...formData,
                });
                saveClientResponse(resp);
              } else {
                setLoader(false);
                setDisplay(false);
                alert('Facing some issue on backend api, please try again later.');
              }
            });
        });
    } catch (err) {
      setLoader(false);
      setDisplay(false);
      return false;
    }
  };

  const saveClientResponse = async tarifs => {
    try {
      axios
        .post('formdata', formData, {
          headers: {
            'Content-type': 'multipart/form-date',
          },
        })
        .then(({ data }) => {
          let response = data;
          if (response.status == true) {
            setId(response.id);
            setCompnt(<PreResult uid={response.id} str={response.str} email={response.email} />);
            axios.get('user/status/' + response.id).then(({ data }) => {
              if (data.user_status == false) {
                axios
                  .post('formdata/activationemail', {
                    to: formData.email,
                  })
                  .then(({ data }) => {});
              }
            });
            setLoader(false);
            setActiveStep(prevActiveStep => prevActiveStep + 1);
            prp.display('none');
          } else {
            setLoader(false);
            setDisplay(false);
            alert(response.message);
            return false;
          }
        });
    } catch (err) {
      return false;
    }
  };

  let handleSubmit = () => {
    setLoader(true);
    setDisplay(true);
    let currentStep = activeStep;
    let result = validateFormData(currentStep);
    if (Object.keys(result).length > 0) {
      setErrors(result);
      setDisplay(false);
      setLoader(false);
    } else {
      callThirdPartyApi();
    }
  };

  let validateFormData = currentStep => {
    const newErrors = {};
    if (currentStep == 1) {
      const { company_name, sector, plant_type, plant_location } = formData;
      // newErrors = {};
      if (!company_name || company_name === '') newErrors.company_name = 'Please Enter Company Name';
      if (typeof company_name !== 'undefined') {
        if (!company_name.match(/^[a-zA-Z 0-9]*$/)) {
          newErrors.company_name = 'Please Enter Correct Name.';
        }
      }
      if (!sector || sector === '') newErrors.sector = 'Please Select Sector';
      if (!plant_type || plant_type === '') newErrors.plant_type = 'Please Select Industry Type';
      if (!plant_location || plant_location === '') newErrors.plant_location = 'Please Select State';
    }
    if (currentStep == 2) {
      const {
        desired_replaced_quantum_mw,
        power_purchase_cost,
        desired_replaced_quantum_mu,
        connectivity_level,
        voltage_level,
        annual_energy_consumption,
      } = formData;
      // newErrors = {};
      let maxEnergy = 1 * 8.76;
      if (!!annual_energy_consumption) {
        if (
          !isPositiveNumber(annual_energy_consumption) ||
          parseFloat(annual_energy_consumption) < 0 ||
          parseFloat(annual_energy_consumption) == -0.0
        ) {
          newErrors.annual_energy_consumption = 'Please Enter positive Numerical Data';
        }
      } else {
        newErrors.annual_energy_consumption = 'Please Enter Annual Energy Consumption';
      }

      if (!!desired_replaced_quantum_mw) {
        if (!isPositiveNumber(desired_replaced_quantum_mw)) {
          newErrors.desired_replaced_quantum_mw = 'OA is not possible as per the Electricity Act for <1MW';
        } else if (desired_replaced_quantum_mw < 1) {
          newErrors.desired_replaced_quantum_mw = 'OA is not possible as per the Electricity Act for <1MW';
        } else {
          maxEnergy = parseFloat(desired_replaced_quantum_mw) * 8.76;
        }
      } else {
        newErrors.desired_replaced_quantum_mw = 'Please Enter Desired Replacement Quantum';
      }

      if (!!desired_replaced_quantum_mu) {
        if (
          !isPositiveNumber(desired_replaced_quantum_mu) ||
          parseFloat(desired_replaced_quantum_mu) < 0 ||
          parseFloat(desired_replaced_quantum_mu) == -0.0
        ) {
          newErrors.desired_replaced_quantum_mu = 'Please Enter positive Numerical Data';
        } else if (desired_replaced_quantum_mu < 0 || desired_replaced_quantum_mu > 999) {
          newErrors.desired_replaced_quantum_mu = 'Please Enter Between 0 to 999';
        } else {
          if (desired_replaced_quantum_mu > maxEnergy) {
            newErrors.desired_replaced_quantum_mu = ' Information mentioned is wrong/ incorrect';
          }
        }
      }

      if (parseInt(desired_replaced_quantum_mu) > parseInt(annual_energy_consumption)) {
        newErrors.desired_replaced_quantum_mu =
          'Desired Replaced Quantum(in MU) should be less than Annual Energy Consumption.';
      }

      if (!power_purchase_cost || power_purchase_cost === '')
        newErrors.power_purchase_cost = 'Please Enter Power Procurement Cost';
      if (typeof power_purchase_cost !== 'undefined') {
        if (
          !isPositiveNumber(power_purchase_cost) ||
          parseFloat(power_purchase_cost) < 0 ||
          parseFloat(power_purchase_cost) == -0.0
        ) {
          newErrors.power_purchase_cost = 'Please Enter positive Numerical Data';
        }
      }

      if (!connectivity_level || connectivity_level === '')
        newErrors.connectivity_level = 'Please Choose Connectivity Level';

      if (!voltage_level || voltage_level === '') newErrors.voltage_level = 'Please Choose Voltage Level';
    }
    if (currentStep == 3) {
      const { injection_level, overall_green_target, oa_mode, oa_duration } = formData;

      if (!injection_level || injection_level === '') newErrors.injection_level = 'Please Choose Connectivity Level';

      if (!overall_green_target || overall_green_target === '') handleInputData('overall_green_target', 0);

      if (!oa_mode || oa_mode === '') newErrors.oa_mode = 'Please Select OA Mode';
    }
    if (currentStep == 4) {
      const { full_name, designation, mobile_no, email } = formData;

      if (!full_name || full_name === '') newErrors.full_name = 'Please Enter Your Name';
      if (typeof full_name !== 'undefined') {
        if (!full_name.match(/^[a-zA-Z ]*$/)) {
          newErrors.full_name = 'Please Enter Correct Name.';
        }
      }

      if (!designation || designation === '') newErrors.designation = 'Please Enter Designation';

      if (!mobile_no || mobile_no === '') newErrors.mobile_no = 'Please Enter Mobile Number';
      if (typeof mobile_no !== 'undefined') {
        if (!mobile_no.match(/^[6789]\d{9}$/) || mobile_no.match(/0{5,}/)) {
          newErrors.mobile_no = 'Please Enter Valid Mobile No.';
        }
      }

      if (!email || email === '') newErrors.email = 'Please Enter Email';
      if (typeof email !== 'undefined') {
        //regular expression for email validation
        var pattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g);
        if (!pattern.test(email)) {
          newErrors.email = 'Please Enter Valid Email-ID.';
        }
        if (email.match(/^[\w.+\-]+@yahoo\.com$/)) {
          newErrors.email = 'yahoo Email-Domain not allowed';
        }
        if (email.match(/^[\w.+\-]+@hotmail\.com$/)) {
          newErrors.email = 'hotmail Email-Domain not allowed';
        }
      }
    }
    return newErrors;
  };

  return (
    <div>
      {showLoader == true && (
        <div
          style={{
            position: 'absolute',
            left: '0%',
            zIndex: 1,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Circles
            height="80"
            width="80"
            // color="#4fa94d"
            color="#72BF44"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {activeStep === 5 ? (
        <React.Fragment>{compnt}</React.Fragment>
      ) : (
        <div className="grid_col">
          <form className="demo-form" data-parsley-validate="">
            <Company active={companyTab} apiData={apiData} handleData={handleInputData} data={formData} error={errors} />
            <TechDetail1 active={tech1Tab} apiData={apiData} handleData={handleInputData} data={formData} error={errors} />
            <TechDetail2 active={tech2Tab} apiData={apiData} handleData={handleInputData} data={formData} error={errors} />
            <UserDetails active={userTab} apiData={apiData} handleData={handleInputData} data={formData} error={errors} />
            <ActionButton
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
              handleSubmit={handleSubmit}
              display={display}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default Main;

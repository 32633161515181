import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';

import AppTextInput from '../../@jumbo/components/Common/formElements/AppTextInput';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectDropdown from './MultiSelectDropdown';
//import CalendarModal from '../Common/CalendarModal';
import { requiredMessage } from '../../@jumbo/constants/ErrorMessages';
import { localStorage_getItem } from '../LocalStorage';
import { extractFilterOptionForModal } from '../ComponentConstant';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    //position: 'relative',
    marginLeft: '0px',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: '#505F79', //theme.palette.common.dark,
    },
    paddingLeft: '0px !important',
  },
  dialogSubTitleRoot: {
    fontSize: 12,
    color: '#505F79',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    //lineHeight:16,
    fontWeight: 500,
  },
  InputLabelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
    width: '130px',
    height: 15,
    lineHeight: '17px',
    paddingBottom: '10px',
  },
}));

const OptimaxFilter = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [stateArray, setStateArray] = useState([]);
  const [sitesArray, setSitesArray] = useState([]);
  const [state, site, dateRange] = extractFilterOptionForModal(props.chipFilterData);
  const [final_sites, setFinalSites] = useState([]);
  const [final_state, setFinalState] = useState([]);
  const [final_date_range, setFinalDateRange] = useState(dateRange);
  const [open, setOpen] = useState(props.open);
  const [stateNameToID, setStateNameToID] = useState({});
  const [siteNameToID, setSiteNameToID] = useState({});
  const [stateIdToName, setStateIdToName] = useState({});
  const [siteIdToName, setSiteIdToName] = useState({});
  const [siteError, setSiteError] = useState('');

  // generate state,site -> Name to id and id to name
  useEffect(() => {
    let access_list_local = localStorage_getItem('access_list');
    let stateArr = [];
    let siteArr = [];
    let state_nameToID = {};
    let state_IdToName = {};
    let site_nameToID = {};
    let site_IdToName = {};

    if (access_list_local.user_state.length > 0) {
      access_list_local.user_state.forEach(element => {
        stateArr.push({ id: element.state_id, value: element.state_name });
        state_nameToID[element.state_name] = element.state_id;
        state_IdToName[element.state_id] = element.state_name;
      });
    }
    if (access_list_local.user_site.length > 0) {
      access_list_local.user_site.forEach(element => {
        siteArr.push({ id: element.site_id, value: element.site_name });
        site_nameToID[element.site_name] = element.site_id;
        site_IdToName[element.site_id] = element.site_name;
      });
    }
    setStateArray(stateArr);
    setSitesArray(siteArr);
    setSiteIdToName(site_IdToName);
    setStateIdToName(state_IdToName);
    setStateNameToID(state_nameToID);
    setSiteNameToID(site_nameToID);
  }, []);

  // to load inital dropdown values of state and sites
  useEffect(() => {
    if (state.length !== 0 && site.length !== 0 && !isEmpty(stateIdToName) && !isEmpty(siteIdToName)) {
      let statesSelected = [];
      let sitesSelected = [];

      state.forEach(item => {
        statesSelected.push(stateIdToName[item]);
      });

      site.forEach(item => {
        sitesSelected.push(siteIdToName[item]);
      });
      setFinalState(statesSelected);
      setFinalSites(sitesSelected);
    }
  }, [stateIdToName, siteIdToName]);

  // to load sites on change of the states
  useEffect(() => {
    var access_list_local = localStorage_getItem('access_list');
    var siteArr = [];

    if (access_list_local.user_site.length > 0) {
      access_list_local.user_site.forEach(element => {
        if (final_state.includes(element.state_detail.state_name)) {
          siteArr.push({ id: element.site_id, value: element.site_name });
        }
      });
    }
    setFinalSites(prev => {
      let p = [];
      let siteArrValue = siteArr.map(item => {
        return item.value;
      });
      prev.forEach(item => {
        if (siteArrValue.includes(item)) {
          p.push(item);
        }
      });
      return p;
    });
    setSitesArray(siteArr);
  }, [final_state]);

  const onSubmitClick = e => {
    e.preventDefault();
    let isValidStep = true;
    if (final_sites.length === 0) {
      setSiteError(requiredMessage);
      isValidStep = false;
    }
    if (isValidStep) {
      var final_site_val = !!final_sites
        ? final_sites
            .map(item => {
              return siteNameToID[item];
            })
            .toString()
        : '';
      var final_state_val = !!final_state
        ? final_state
            .map(item => {
              return stateNameToID[item];
            })
            .toString()
        : '';
      const filterDetail = {
        final_date_range: final_date_range,
        final_state: final_state_val,
        final_sites: final_site_val,
      };
      //console.log(' filterDetail  props ->', props,' filterDetail->',filterDetail);
      props.onRangeSave(filterDetail);
      setOpen(false);
      setSiteError('');
    }
  };

  // const [calendarModal, setCalendarModal] = useState(false);
  // const calendarModalHandler = value => {
  //   setCalendarModal(value);
  // };
  const onRangeSave = e => {
    setFinalDateRange(e);
  };
  const [IsFilter, setIsFilter] = useState(true);

  return (
    <Box>
      <Dialog open={open}>
        <form>
          <DialogContent style={{ width: '311px' }}>
            <DialogTitle className={classes.dialogTitleRoot}>Add Filter</DialogTitle>
            <IconButton
              onClick={() => {
                props.onCloseDialog(false);
              }}
              size="small"
              style={{ position: 'absolute', right: '7.93%', top: '8.93%' }}>
              <CloseIcon />
            </IconButton>
          </DialogContent>
          <DialogContent>
            {/* <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Date Range</InputLabel>
              <AppTextInput
                type="text"
                onFocus={() => calendarModalHandler(true)}
                required
                fullWidth
                variant="outlined"
                value={final_date_range}
              />
            </Box> */}
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>State</InputLabel>
              <MultiSelectDropdown setSelected={setFinalState} selected={final_state} dropdown={stateArray} />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Sites</InputLabel>
              <MultiSelectDropdown
                setSelected={setFinalSites}
                selected={final_sites}
                dropdown={sitesArray}
                error={siteError}
              />
            </Box>
          </DialogContent>
          <DialogContent>
            <Box mb={{ xs: 0, md: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
                style={{ width: '100%', background: '#056C45', color: '#FFFFFF' }}>
                Add Filters
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
      {/* <CalendarModal
        visible={calendarModal}
        setVisible={setCalendarModal}
        title={'Change Monitor Period'}
        onRangeSave={onRangeSave}
        range={final_date_range}
        isFilterComponent={setIsFilter}
      /> */}
    </Box>
  );
});

export default OptimaxFilter;

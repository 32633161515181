import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';

export default function SearchInput(props) {
  const useStyles = makeStyles(theme => ({
    inputContainer: {
      display: 'flex',
      width: '100%',
      backgroundColor: '#fff',
      alignItems: 'center',
      gap: '10px',
      padding: '1px 4px',
    },
    inputField: {
      fontSize: '12px',
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.inputContainer}>
      <SearchIcon />
      <InputBase onChange={props.onChange} className={classes.inputField} />
    </div>
  );
}

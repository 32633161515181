import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Checkbox, FormControlLabel, FormGroup, RadioGroup, Radio } from '@mui/material';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from '../../../services/auth/config';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    inputtext: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    select: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  }),
);

const InjectionDetails = ({ handleNext, handleBack, handleFormData, values }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});
  var temp = '';

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    handleFormData(field, value);
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const {injection_level, overall_green_target, total_greening_achieved, oa_mode, oa_duration } = values;

    if (!oa_duration || oa_duration === '') setField('oa_duration', 1);
    const newErrors = {};

    if (!injection_level || injection_level === '') newErrors.injection_level = 'Please Choose Connectivity Level';

    if (!overall_green_target || overall_green_target === '') setField('overall_green_target', '0');;

    if (!oa_mode || oa_mode === '') newErrors.oa_mode = 'Please Select OA Mode';
    return newErrors;
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#8BD000',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }),
  );

  const handleSubmit = e => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      handleNext();
    }
  };

  const CardStyle = {
    card: {
      variant: 'outlined',
      borderColor: '#E1FFA3',
    },
    cardheader: {
      color: '#081f12',
      background: '#76c04e',
      // background: '#b2df8a',
    },
    primarytext: {
      color: '#4A5339',
      fontWeight: 'regular',
    },
    secondarytext: {
      color: '#38693E',
      fontStyle: 'oblique',
    },
    errormessage: {
      color: '#FF0000',
    },
    optional: {
      fontSize: '0.5rem',
    },
  };
  const [getInjectionState, setInjectionState] = React.useState([]);
  const [getInjectionLevel, setInjectionLevel] = React.useState([]);
  const [getOaMode, setOaMode] = React.useState([]);
  const [getOaDuration, setOaDuration] = React.useState([]);

  useEffect(() => {
    axios.get('getformmasterdata', { params: { master_data_type: 0 } }).then(({ data }) => {
      setInjectionState(data.data.plant_location);
      setInjectionLevel(data.data.injection_level);
      setOaMode(data.data.oa_modes);
      setOaDuration(data.data.oa_durations);
      
    });
  }, []); 

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 70,
      label: '70',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 90,
      label: '90',
    },
    {
      value: 100,
      label: '100 %',
    },
  ];
  function valuetext(value) {
    return `${value}°C`;
  }

  {getInjectionLevel.map((type, index) => {
    values.injection_level = values.plant_location_type == type.id ? type.id : values.injection_level;
  })}

  return (
    <Box m={2}>
      <Card
        style={CardStyle.card}
        variant="outlined"
        sx={{
          boxShadow: 20,
        }}>
        <CardHeader
          style={CardStyle.cardheader}
          component={Typography}
          // title={'Injection Details'}
          title={'Technical Details (2/2)'}
          color={'black'}
          sx={{
            boxShadow: 5,
          }}
        />
        <CardContent>
          <Grid container rowSpacing={2} spacing={1}>
            <Grid xs={6}>
                  <Box sx={{ ml: 2, mt: 2 }}>
                    <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                      OA Duration{' '}
                    </Typography>
                    <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                      Select the Duration Applicable
                    </Typography>
                  </Box>

                  <RadioGroup
                    sx={{ flexDirection: 'row', m: 1, pl: 3 }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    value={values.oa_duration ? values.oa_duration : form.oa_duration}
                    defaultValue="1"
                    onChange={e => setField('oa_duration', e.target.value)}
                    name="row-radio-buttons-group">
                    {getOaDuration.map((type, index) => {
                      return (
                        <>
                          <FormControlLabel
                            sx={{ pl: index == 0 ? 2 : 10, color: '#4A5339' }}
                            value={type.id}
                            disabled={type.id == 2 ? true : false}
                            control={<Radio sx={{ ' &.Mui-checked': { color: '#077D3E' } }} />}
                            label={type.name}
                          />
                          <LightTooltip title={type.description} arrow>
                            <Button>
                              <InfoIcon sx={{ color: '#8BD000' }} fontSize="medium" />
                            </Button>
                          </LightTooltip>
                        </>
                      );
                    })}
                  </RadioGroup>
                  <Box ml={4} style={CardStyle.errormessage}>
                    {errors.oa_duration}
                  </Box>
                </Grid>
            <Grid xs={6}>
              <Box sx={{ ml: 2, mt: 2 }}>
                <Typography style={CardStyle.primarytext} variant="h5" display="block" gutterBottom>
                  {' '}
                  Injection Level{' '}
                </Typography>
                <Typography sx={{ pl: 3 }} style={CardStyle.secondarytext} variant="caption" display="block">
                  Select Connectivity Level
                </Typography>
              </Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.injection_level ? values.injection_level : form.injection_level}
                onChange={e => setField('injection_level', e.target.value)}
                sx={{
                  flexDirection: 'row',
                  m: 1,
                  pl: 3,
                }}>
                {getInjectionLevel.map((type, index) => {
                  return (
                    <>
                      <FormControlLabel
                        sx={{ pl: index == 0 ? 2 : 3, color: '#4A5339' }}
                        value={type.id}
                        disabled={values.plant_location_type != type.id ? true : false}
                        checked={values.plant_location_type == type.id}
                        control={<Radio sx={{ ' &.Mui-checked': { color: '#077D3E' } }} />}
                        label={type.name}
                      />
                      {/* <LightTooltip title={type.description} arrow>
                        <Button>
                          <InfoIcon sx={{ color: '#8BD000' }} fontSize="medium" />
                        </Button>
                      </LightTooltip> */}
                    </>
                  );
                })}
              </RadioGroup>
              {values.plant_location_type == 1 ? (
                    <Box ml={4}>
                      **We are proposing an inter-state open access due to the absence of ReNew plant in this state.
                    </Box>
                  ) : (
                    <Box>**We are proposing an intra-state open access due to the presence of ReNew plant in this state</Box>
                  )
              }
              <Box ml={4} style={CardStyle.errormessage}>
                {errors.injection_level}
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ ml: 2, mt: 2 }}>
                <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                  OA mode{' '}
                </Typography>
                <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                  Select the mode of OA{' '}
                </Typography>
              </Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                sx={{ flexDirection: 'row', m: 1, pl: 3 }}
                value={values.oa_mode ? values.oa_mode : form.oa_mode}
                onChange={e => setField('oa_mode', e.target.value)}>
                {getOaMode.map((type, index) => {
                  return (
                    <FormControlLabel
                      sx={{ pl: index == 0 ? 2 : 3, color: '#4A5339' }}
                      control={<Radio sx={{ ' &.Mui-checked': { color: '#077D3E' } }} />}
                      label={type.name}
                      value={type.id}
                    />
                  );
                })}
              </RadioGroup>
              <Box ml={5} style={CardStyle.errormessage}>
                {errors.oa_mode}
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ ml: 2, mt: 4 }}>
                <Typography variant="h5" display="block" style={CardStyle.primarytext} gutterBottom>
                  What is the share of Existing Green Consumption?
                </Typography>
                <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                Select the current % of green power being procured
                </Typography>
              </Box>
              <Box sx={{ flexDirection: 'row', pl: 3 }}>
                <Slider
                  defaultValue={0}
                  value={values.overall_green_target ? (values.overall_green_target * 100).toFixed(2) : form.overall_green_target}
                  onChange={e => setField('overall_green_target', (e.target.value.toFixed(2))/100)}
                  valueLabelDisplay="auto"
                  step={1}
                  getAriaValueText={valuetext}
                  marks={marks}
                  min={0}
                  max={100}
                  sx={{
                    m: 1,
                    width: 500,
                    height: 10,
                    color: '#077D3E',
                  }}
                />
                <div sx={{ mt: 2 }} style={CardStyle.errormessage}>
                  {errors.overall_green_target}
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 3 }}>
            <Button
              style={{
                borderRadius: 20,
                borderColor: '#077D3E',
                padding: '8px 16px',
                fontSize: '15px',
                margin: '10px',
                color: '#077D3E',
              }}
              variant="outlined"
              onClick={handleBack}
              startIcon={<NavigateBeforeIcon />}>
              {'Back'}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button
              style={{
                borderRadius: 20,
                borderColor: '#077D3E',
                padding: '8px 16px',
                fontSize: '15px',
                margin: '10px',
                color: '#077D3E',
              }}
              variant="outlined"
              onClick={handleSubmit}
              endIcon={<NavigateNextIcon />}>
              {'Next'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default InjectionDetails;

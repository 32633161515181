import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import axios from '../../../services/auth/config';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import validator from 'validator';

const useStyles = makeStyles(theme =>
  createStyles({
    inputtext: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    select: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  }),
);

const UserAuth = ({ handleNext, handleBack, handleFormData, values }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [submitButtonColor,setSubmitButtonColor] = React.useState('#fff');

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    handleFormData(field, value);
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { full_name, designation, mobile_no, email } = values;
    const newErrors = {};

    if (!full_name || full_name === '') newErrors.full_name = 'Please Enter Your Name';
    if (typeof full_name !== 'undefined') {
      if (!full_name.match(/^[a-zA-Z ]*$/)) {
        newErrors.full_name = 'Please Enter Correct Name.';
      }
    }
    if (!designation || designation === '') newErrors.designation = 'Please Enter Designation';
    if (!mobile_no || mobile_no === '') newErrors.mobile_no = 'Please Enter Mobile Number';
    if (typeof mobile_no !== 'undefined') {
      if (!mobile_no.match(/^[6789]\d{9}$/) || mobile_no.match(/0{5,}/)) {
        newErrors.mobile_no = 'Please Enter Valid Mobile No.';
      }
    }
    if (!email || email === '') newErrors.email = 'Please Enter Email';
    if (typeof email !== 'undefined') {
      //regular expression for email validation
      var pattern = new RegExp(
        // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g,
      );
      if (!pattern.test(email)) {
        newErrors.email = 'Please Enter Valid Email-ID.';
      }
      if (email.match(/^[\w.+\-]+@yahoo\.com$/)) {
        newErrors.email = 'yahoo Email-Domain not allowed';
      }
      if (email.match(/^[\w.+\-]+@hotmail\.com$/)) {
        newErrors.email = 'hotmail Email-Domain not allowed';
      }
    }
    return newErrors;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitButtonColor('greenyellow');
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setSubmitButtonColor('#fff');
    } else {
      const result = await handleNext();
      if (result !== undefined) {
        // Handle Backend Errors
        if (result.status == false) {
          if (Array.isArray(result.fieldName)) {
            // For Required Validation
            result.fieldName.forEach(errorObj => {
              setErrors({
                ...errors,
                [errorObj.fieldName]: errorObj.message,
              });
            });
          } else {
            // For Unique Validation [Email/Mobile]
            setErrors({
              ...errors,
              [result.fieldName]: result.message,
            });
          }
          setSubmitButtonColor('#fff');
        }
      }
   
    }
   
  };

  const CardStyle = {
    card: {
      variant: 'outlined',
      borderColor: '#E1FFA3',
    },
    cardheader: {
      color: '#081f12',
      background: '#76c04e',
      // background: '#b2df8a',
    },
    primarytext: {
      color: '#4A5339',
      fontWeight: 'regular',
    },
    secondarytext: {
      color: '#38693E',
      fontStyle: 'oblique',
    },
    errormessage: {
      color: '#FF0000',
    },
  };
  const [getDesignation, setDesignation] = React.useState([]);
  useEffect(() => {
    axios.get('getformmasterdata', { params: { master_data_type: 0 } }).then(({ data }) => {
      setDesignation(data.data.designations);
    });
  }, []);
  return (
    <Box m={2}>
      <Card
        style={CardStyle.card}
        variant="outlined"
        sx={{
          boxShadow: 20,
        }}>
        <CardHeader
          style={CardStyle.cardheader}
          component={Typography}
        //  title={'User'}
          title={'User Details'}
          color={'black'}
          sx={{
            boxShadow: 5,
          }}
        />
        <CardContent>
          <Grid container rowSpacing={2} spacing={1}>
            <Grid xs={6}>
              <Box
                sx={{
                  m: 2,
                }}>
                <Typography style={CardStyle.primarytext} variant="h6" display="block" gutterBottom>
                  Full Name{' '}
                </Typography>
                <TextField
                  InputProps={{ className: classes.inputtext }}
                  sx={{ width: 500, height: 40, mb: 2 }}
                  id="outlined-basic"
                  value={values.full_name ? values.full_name : form.full_name}
                  onChange={e => setField('full_name', e.target.value)}
                  placeholder="Enter Full name"
                  variant="outlined"
                />
                <div style={CardStyle.errormessage}>{errors.full_name}</div>
              </Box>
            </Grid>

            <Grid xs={6}>
              <Box sx={{ m: 2 }}>
                <Typography variant="h6" display="block" style={CardStyle.primarytext} gutterBottom>
                  {' '}
                  Designation{' '}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    className={classes.select}
                    size="small"
                    value={values.designation ? values.designation : form.designation}
                    onChange={e => setField('designation', e.target.value)}
                    sx={{ width: 500, height: 50 }}>
                    {getDesignation.map((type, index) => {
                      return <MenuItem value={type.id}>{type.name}</MenuItem>;
                    })}
                  </Select>
                  <div style={CardStyle.errormessage}>{errors.designation}</div>
                </FormControl>
              </Box>
            </Grid>

            <Grid xs={6}>
              <Box
                sx={{
                  m: 2,
                }}>
                <Typography style={CardStyle.primarytext} variant="h6" display="block" gutterBottom>
                  {' '}
                  Mobile No{' '}
                </Typography>
                <TextField
                  InputProps={{ className: classes.inputtext }}
                  sx={{ width: 500, height: 40, mb: 2 }}
                  id="outlined-basic"
                  placeholder="+91 xxxxxxxxxx"
                  value={values.mobile_no ? values.mobile_no : form.mobile_no}
                  onChange={e => setField('mobile_no', e.target.value)}
                  variant="outlined"
                />
                <div style={CardStyle.errormessage}>{errors.mobile_no}</div>
              </Box>
            </Grid>

            <Grid xs={6}>
              <Box
                sx={{
                  m: 2,
                }}>
                <Typography style={CardStyle.primarytext} variant="h6" display="block" gutterBottom>
                  Official Email Id{' '}
                </Typography>
                <TextField
                  InputProps={{ className: classes.inputtext }}
                  sx={{ width: 500, height: 40, mb: 2 }}
                  id="outlined-basic"
                  value={values.email ? values.email : form.email}
                  onChange={e => setField('email', e.target.value)}
                  placeholder="email@gmail.com"
                  variant="outlined"
                />
                <div style={CardStyle.errormessage}>{errors.email}</div>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              style={{
                borderRadius: 20,
                borderColor: '#077D3E',
                padding: '8px 16px',
                fontSize: '15px',
                margin: '10px',
                color: '#077D3E',
              }}
              variant="outlined"
              onClick={handleBack}
              startIcon={<NavigateBeforeIcon />}>
              {'Back'}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button
              style={{
                borderRadius: 20,
                borderColor: '#077D3E',
                padding: '8px 16px',
                fontSize: '15px',
                margin: '10px',
                // backgroundColor: submitButtonColor,
                color: '#077D3E',
              }}
              variant="outlined"
              onClick={handleSubmit}>
              {'submit'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserAuth;

import { localStorage_getItem, localStorage_removeItem, localStorage_setItem } from '../../@custom/LocalStorage';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import axios from '../../services/auth/config';

const UserManagement = {
  userList: (setdata, company_id, user_type) => {
    return dispatch => {
      //dispatch(fetchStart());
      const token = localStorage.getItem('token');

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post('user/list', config)
        .then(({ data }) => {
          if (data) {
            //dispatch(fetchSuccess(data['message']));
            setdata(data['data']);
          } else {
            setdata([]);
            //dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          setdata([]);
          //dispatch(fetchError(error.response.data.message));
        });
    };
  },

  userListAccessWise: (setdata, year, company_id, user_type) => {
    return dispatch => {
      //dispatch(fetchStart());
      axios
        .get('users/operations?company_id=' + company_id)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            //dispatch(fetchSuccess(data['message']));
            setdata(data['data']);
          } else {
            setdata([]);
            //dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          setdata([]);
          //dispatch(fetchError(error.response.data.message));
        });
    };
  },

  overalllUserInfo: (setdata, year, company_id, user_type) => {
    return dispatch => {
      //dispatch(fetchStart());
      axios
        .get('users/count?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setdata(data['data']);
            //dispatch(fetchSuccess(data['message']));
          } else {
            setdata({
              total: '-',
              active: '-',
              inactive: '-',
              blocked: '-',
            });
            //dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          setdata({
            total: '-',
            active: '-',
            inactive: '-',
            blocked: '-',
          });
          //dispatch(fetchError(error.response.data.message));
        });
    };
  },

  deleteUser: id => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .post(
          'user/delete',
          {
            id: id,
          },
          config,
        )
        .then(({ data }) => {
          // if (data['status'] === 'success') {
          dispatch(fetchSuccess(data['message']));
          // } else {
          //   dispatch(fetchError(data['message']));
          // }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  changeStatus: (user_id, status) => {
    if (status == 'Active') {
      status = 1;
    } else {
      status = 0;
    }
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put('/user/change-status/' + user_id + '/' + status)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            // setmsg(data['message']);
            dispatch(fetchSuccess(data['message']));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  getCurrentUser: (userId, setCurrentUser, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .get('user/get/' + userId, config)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setCurrentUser(data.data[0]);
            setIsUpdated(0);
            dispatch(fetchSuccess(data['message']));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  addNewUser: (user, seteditedUserId, seteditedUserComapny, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('users/set', user)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(fetchSuccess(data.message));
            seteditedUserId(data['data']['id']);
            seteditedUserComapny(data['data']['company_name']);
            setIsUpdated(1);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.message));
          }
        });
    };
  },

  updateUser: (userId, user, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .put('user/update/' + userId, user, config)
        .then(({ data }) => {
          if (data.status === 'success') {
            // change user_name in local storage
            if (userId === localStorage_getItem('user_id') && user.user_name !== localStorage_getItem('user_name')) {
              localStorage_setItem('user_name', user.user_name);
            }
            setIsUpdated(1);
            dispatch(fetchSuccess(data.message));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.message));
          }
        });
    };
  },

  layoutChange: (key, layout) => {
    let userId = localStorage_getItem('user_id');
    let companyId = localStorage_getItem('company_id');
    let obj = {};
    obj[key] = layout;
    obj['company_id'] = companyId;
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put('users/set/' + userId, obj)
        .then(({ data }) => {
          if (data.status === 'success') {
            dispatch(fetchSuccess('Layout updated'));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  saveInLocalStorage: async userId => {
    return new Promise(resolve => {
      axios
        .get('users/get/' + userId)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            localStorage_setItem('user_id', userId);
            localStorage_setItem('analysisLayout', data['data']['analysis_layout']);
            localStorage_setItem('company_id', data['data']['company_id']);
            localStorage_setItem('email', data['data']['email']);
            localStorage_setItem('monitorLayout', data['data']['monitor_layout']);
            localStorage_setItem('user_name', data['data']['user_name']);
            localStorage_setItem('user_type', data['data']['user_type']);
            localStorage_setItem('company_slug', data['data']['slug'].split('.optimax.ai')[0]);
            localStorage_setItem('portfolio_type', data['data']['portfolio_type']);
            resolve('true');
          } else {
          }
        })
        .catch(function(error) {});
    });
  },
};

export default UserManagement;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from '../../services/auth/config';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

theme.typography.body2 = {
  fontSize: '0.7rem',
  color: '#000',
};
theme.typography.h6 = {
  fontSize: '0.875rem',
  color: '#000',
};

//function createData(particulars, uom, fat, carbs, protein, price) {
function createData(particulars, uom, solar, wind, hybrid1, hybrid2, history) {
  return {
    particulars,
    uom,
    solar,
    wind,
    hybrid1,
    hybrid2,
    history,
  };
}
function Row(props) {
  const { row, tableRows } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{ color: '#000' }}>
        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{ fontSize: '12px !important', width: '15%', color: '#000',height: '37px' }}>
          <Typography variant="body2">
            {' '}
            {row.particulars}
            {row.particulars == 'Tariff' ? (
              <IconButton aria-label="expand row" size="small" style={{ padding: '3px' }} onClick={() => setOpen(!open)}>
                {open ? (
                  <KeyboardArrowUpIcon
                    size="small"
                    style={{
                      backgroundColor: '#b2df8a',
                      color: 'black',
                      width: '0.5em',
                      height: '0.5em',
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    size="small"
                    style={{
                      backgroundColor: '#b2df8a',
                      color: 'black',
                      width: '0.5em',
                      height: '0.5em',
                    }}
                  />
                )}
              </IconButton>
            ) : (
              ''
            )}
          </Typography>
        </TableCell>
        <TableCell style={{ backgroundColor: '#fff', color: 'black', width: '15%',height: '37px' }} align="center">
          <Typography variant="body2"> {row.uom}</Typography>
        </TableCell>
        <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
          <Typography variant="body2"> {row.solar}</Typography>
        </TableCell>
        <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
          <Typography variant="body2"> {row.wind}</Typography>
        </TableCell>
        <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
          <Typography variant="body2"> {row.hybrid1}</Typography>
        </TableCell>
        <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
          <Typography variant="body2"> {row.hybrid2}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingRight: 0,
            paddingLeft: 0,
          }}
          colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  {/* <TableRow>
                    <TableCell style={{ backgroundColor: '#b2df8a', width: '100px' }} component="td" align="center">
                      Particulars
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#b2df8a', width: '100px' }} component="td" align="center">
                      UoM
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#b2df8a', width: '50px' }} component="td" align="center">
                      Solar
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#b2df8a', width: '50px' }} component="td" align="center">
                      Wind
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#b2df8a', width: '50px' }} component="td" align="center">
                      Hybrid Low CUF
                    </TableCell>
                    <TableCell style={{ backgroundColor: '#b2df8a', width: '50px' }} component="td" align="center">
                      Hybrid High CUF
                    </TableCell>
                  </TableRow> */}
                </TableHead>
                <TableBody>
                  {tableRows.map(historyRow => (
                    <TableRow key={historyRow.particulars}>
                      <TableCell
                        style={{ backgroundColor: '#fff', width: '18%',height: '37px' }}
                        component="th"
                        //  scope="row"
                        align="left">
                        <Typography variant="body2"> {historyRow.particulars}</Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
                        <Typography variant="body2"> {historyRow.uom}</Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
                        <Typography variant="body2"> {historyRow.solar}</Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
                        <Typography variant="body2"> {historyRow.wind}</Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
                        <Typography variant="body2"> {historyRow.hybrid1}</Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#fff', width: '15%',height: '37px' }} align="center">
                        <Typography variant="body2"> {historyRow.hybrid2}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable({ decryptedData }) {
  let [rows, setRows] = useState([]);
  let [tableRows, setTableRows] = useState([]);
  let temp = [];
  useEffect(() => {
    axios.get('/formdata/get-ilt-calculator-data/' + decryptedData.uid).then(({ data }) => {
      if (data.status == true) {
        let InputUserData = data.data;
        if (InputUserData.length != 0) {
          let InputUserData1 = InputUserData[0];
          let InputUserData2 = InputUserData[1];
          setTableRows(
            InputUserData2.map(function(element) {
              return createData(element['name'], element['unit'], element['Solar'],element['Wind'],
              element['Hybrid Low CUF'],element['Hybrid High CUF'], []);
            }),
          );
          setRows(
            InputUserData1.map(function(element) {
              return createData(element['name'], element['unit'], element['Solar'],element['Wind'],
              element['Hybrid Low CUF'],element['Hybrid High CUF'], []);
            }),
          );
        }
      }
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 1000,
          maxWidth: 800,
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '30px',
        }}>
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell style={{ backgroundColor: '#98D066', width: '18%' }} component="td" align="center">
                {' '}
                <Typography variant="h6" fontWeight="bold">
                  Particulars{' '}
                </Typography>
              </TableCell>
              <TableCell style={{ backgroundColor: '#98D066', width: '15%' }} component="td" align="center">
                {' '}
                <Typography variant="h6" fontWeight="bold">
                  UoM{' '}
                </Typography>
              </TableCell>
              <TableCell style={{ backgroundColor: '#98D066', width: '15%' }} component="td" align="center">
                {' '}
                <Typography variant="h6" fontWeight="bold">
                  Solar{' '}
                </Typography>
              </TableCell>
              <TableCell style={{ backgroundColor: '#98D066', width: '15%' }} component="td" align="center">
                {' '}
                <Typography variant="h6" fontWeight="bold">
                  Wind{' '}
                </Typography>
              </TableCell>
              <TableCell style={{ backgroundColor: '#98D066', width: '15%' }} component="td" align="center">
                {' '}
                <Typography variant="h6" fontWeight="bold">
                  Hybrid Low CUF{' '}
                </Typography>
              </TableCell>
              <TableCell style={{ backgroundColor: '#98D066', width: '15%' }} component="td" align="center">
                {' '}
                <Typography variant="h6" fontWeight="bold">
                  Hybrid High CUF{' '}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow />
          </TableHead>
          <TableBody>
            {rows.length == 0 ? (
              <TableRow align="center">Data not found.</TableRow>
            ) : (
              rows.map(row => <Row key={row.name} row={row} tableRows={tableRows} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}


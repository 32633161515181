import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../services/auth/index';
import { makeStyles } from '@material-ui/core/styles';
import OptimaxTable from '../../@custom/UI/OptimaxTable';
import { Select, MenuItem, Box, Paper, Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { isNull } from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CustomModal from '../../@custom/commom/CustomModal';
import moment from 'moment';
import SearchInput from '../../@custom/UI/SearchInput';
import MultiSelectTable from '../../@custom/UI/MultiselectTable';
import Edit from '@material-ui/icons/Edit';
import { Delete } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { checkPageLevelAccess } from '../../@custom/ComponentConstant';
import { localStorage_getItem } from '../../@custom/LocalStorage';
import CustomButton from '../../@custom/UI/CustomButton';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal);
const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '32px',
    lineHeight: '38px',
    color: '#505F79',
  },
}));

const UserManagement = () => {
  const dispatch = useDispatch();
  const dropDownData = ['Active', 'Inactive'];
  // const breadcrumbsArray = [];
  // useEffect(() => {
  //   props.setBreadcrumbs(breadcrumbsArray);
  //   props.setHeaderText('User Management');
  // }, []);
  const classes = useStyles();
  let method = 'jwtUserMngement';

  const status_arr = ['Inactive', 'Active'];

  const [tableData, settableData] = useState();
  const [data, setData] = useState(null);
  const [cardData, setcardData] = useState({
    total: '-',
    active: '-',
    inactive: '-',
    blocked: '-',
  });
  //const [year, setyear] = useState(current_fn_year());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scope, setScope] = useState('');
  const [action, setAction] = useState('');
  const [userId, setUserId] = useState('');
  const company_id = localStorage_getItem('company_id');
  const user_type = localStorage_getItem('user_type');
  var access_list_local = localStorage_getItem('access_list');
  var user_list_local = localStorage_getItem('user_type');
  let pageLevelAccess = true; //checkPageLevelAccess(access_list_local, user_list_local, 'User Management');
  const getData = () => {
    dispatch(AuhMethods[method].userList(setData, company_id, user_type));
    //dispatch(AuhMethods[method].overalllUserInfo(seta, company_id, user_type));
  };
  const generatetableData = () => {
    if (!isNull(data)) {
      let temp_data = [];
      data.map((item, index) => {
        temp_data.push({
          'Sr. No.': index + 1,
          'User Id': item.id,
          Name: item.full_name,
          Email: item.email,
          'Super Admin': item.super_admin,
          'Output Link': item.output_url,
          'Created on': item.created_at ? item.created_at : '-',
          //'Recovered on': item.recovered_on,
          'Updated on': item.updated_at ? item.updated_at : '-',
          //'Blocked on': item.blocked_on,
          Status: status_arr[item.status],
        });
      });

      settableData(temp_data);
    }
  };

  let columns = [
    { title: 'Sr. No.', field: 'Sr. No.', filtering: false },
    // {
    //   title: 'User Id',
    //   field: 'User Id',
    //   filtering: true,
    //   filterComponent: ({ columnDef, onFilterChanged }) => (
    //     <SearchInput
    //       // placeholder="Search By User ID"
    //       onChange={e => {
    //         onFilterChanged(columnDef.tableData.id, e.target.value);
    //       }}
    //     />
    //   ),
    // },
    {
      title: 'Name',
      field: 'Name',
      filtering: true,
      filterComponent: ({ columnDef, onFilterChanged }) => (
        <SearchInput
          // placeholder="Search By User ID"
          onChange={e => {
            onFilterChanged(columnDef.tableData.id, e.target.value);
          }}
        />
      ),
    },
    {
      title: 'Email',
      field: 'Email',
      filtering: true,
      filterComponent: ({ columnDef, onFilterChanged }) => (
        <SearchInput
          // placeholder="Search By User ID"
          onChange={e => {
            onFilterChanged(columnDef.tableData.id, e.target.value);
          }}
        />
      ),
    },
    {
      title: 'Output Link',
      field: 'Output Link',
      filtering: false,
      render: rowData => {
        let url = rowData['Output Link'];
        if (url) {
          return (
            <Link to={url}>
              {url != null ? url.substring(19, 30) : '--'}
              {/* <button type="button" className="btn btn-info">
                Link
              </button> */}
            </Link>
          );
        }
      },
      searchable: false,
    },
    {
      title: 'Created on',
      field: 'Created on',
      filtering: false,
      type: 'date',
      render: rowData => moment(rowData['Created on']).format('DD/MM/YYYY'),
      searchable: false,
    },
    // {
    //   title: 'Recovered on',
    //   field: 'Recovered on',
    //   filtering: false,
    //   type: 'date',
    //   render: rowData => (isNull(rowData['Recovered on']) ? '-' : moment(rowData['Recovered on']).format('DD/MM/YYYY')),
    //   searchable: false,
    // },
    {
      title: 'Updated on',
      field: 'Updated on',
      filtering: false,
      type: 'date',
      render: rowData => moment(rowData['Updated on']).format('DD/MM/YYYY'),
      searchable: false,
    },
    // {
    //   title: 'Blocked on',
    //   field: 'Blocked on',
    //   filtering: false,
    //   type: 'date',
    //   render: rowData => (isNull(rowData['Blocked on']) ? '-' : moment(rowData['Blocked on']).format('DD/MM/YYYY')),
    //   searchable: false,
    // },
    {
      title: 'Status',
      field: 'Status',
      searchable: false,
      customFilterAndSearch: (data, rowData) => {
        return data.includes(rowData.Status);
      },
      filterComponent: ({ columnDef, onFilterChanged }) => (
        <MultiSelectTable dropdown={status_arr} columnDef={columnDef} onFilterChanged={onFilterChanged} />
      ),
      render: rowData => {
        return (
          <FormControl variant="filled">
            <Select
              disabled={pageLevelAccess ? false : true}
              style={{ width: '88px', fontSize: '12px' }}
              onChange={event => {
                let user_id = rowData['User Id'];
                let curr_user_id = localStorage_getItem('user_id');
                if (curr_user_id == user_id) {
                  MySwal.fire('Permision Denied', 'you dont have the permission to change the status of this user', 'error');
                } else {
                  dispatch(AuhMethods[method].changeStatus(rowData['User Id'], event.target.value));
                  let today = new Date();
                  setData(prev => {
                    prev[rowData.tableData.id]['updatedAt'] = today;
                    if (event.target.value.toLowerCase() === 'active') {
                      prev[rowData.tableData.id]['status'] = 1;
                      // prev[rowData.tableData.id]['recovered_on'] = today;
                    } else {
                      prev[rowData.tableData.id]['status'] = 0;
                      // prev[rowData.tableData.id]['blocked_on'] = today;
                    }
                    return [...prev];
                  });
                  //change the data of the cardData
                  // active or inactive --;
                  setcardData(prev => {
                    if (event.target.value.toLowerCase() === 'active') {
                      prev['active']++;
                      prev['inactive']--;
                    } else {
                      prev['active']--;
                      prev['inactive']++;
                    }
                    return { ...prev };
                  });
                }
              }}
              value={rowData.Status}>
              {status_arr.map(status => (
                <MenuItem key={status} value={status}>
                  <p
                    style={{
                      fontSize: '12px',
                      color: status === 'Active' ? 'green' : 'red',
                    }}>
                    {status}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
  ];

  // let current_user_type = {
  //   title: 'Company Name',
  //   field: 'Company Name',
  //   filtering: true,
  //   filterComponent: ({ columnDef, onFilterChanged }) => (
  //     <SearchInput
  //       // placeholder="Search By Company Name"
  //       onChange={e => {
  //         onFilterChanged(columnDef.tableData.id, e.target.value);
  //       }}
  //     />
  //   ),
  // };

  function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
  }

  // if (user_type === '1') {
  //   insertAt(columns, 3, current_user_type);
  // }
  // console.log(columns);

  const sweetAlerts = (event, rowData) => {
    MySwal.fire({
      title: 'You are about to delete user account!',
      text: "If you delete this user you can't recover it for next 14 days",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        MySwal.fire('Deleted!', 'User Deleted.', 'success');
        let id = rowData.tableData.id;
        let user_id = rowData['User Id'];
        //api call to delete user
        dispatch(AuhMethods[method].deleteUser(user_id));
        //deleting from the table
        setData(prev => {
          prev.splice(id, 1);
          return [...prev];
        });

        //change the data of the cardData
        //in case of delete total --;
        // active or inactive --;
        setcardData(prev => {
          let status = rowData['Status'];
          if (status.toLowerCase() === 'active') {
            prev['active']--;
          } else {
            prev['inactive']--;
          }
          prev['total']--;
          return { ...prev };
        });
      }
    });
  };

  const editfun = (event, rowData) => {
    setIsModalOpen(true);
    setScope('add-user');
    setAction('edit');
    setUserId(rowData['User Id']);
    // generatetableData();
  };

  const delfun = (event, rowData) => {
    let user_id = rowData['User Id'];
    let curr_user_id = localStorage_getItem('user_id');

    if (curr_user_id == user_id) {
      MySwal.fire('Permision Denied', 'you dont have the permission to deleted this user', 'error');
    } else {
      sweetAlerts(event, rowData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    generatetableData();
  }, [data]);

  // const handleModalOpen = value => {

  //   setIsModalOpen(value);
  //   setScope('add-user');
  //   setAction('add');
  //   // console.log(' isModalOpen ->', isModalOpen);
  // };

  // const disablefun = rowData => {
  //   return rowData.id === 1;
  // };
  return (
    <div>
      <Box display="flex" pb={4} flexWrap="wrap" alignItems="flex-end">
        <Box p={1} flexGrow={1}>
          <h1 className={classes.title}>User Management</h1>
        </Box>
        <Box display="flex" alignItems="flex-end">
          {/* <YearFilter startYear={2020} year={year} setyear={setyear} financial={true} /> */}
          <Box pl={2} pr={1}>
            {/* {pageLevelAccess && ( */}
            {/* <CustomButton
                variant={'contained'}
                color={'secondary'}
                icon={'getAddIcon'}
                onClick={() => handleModalOpen(true)}>
                Add New User
              </CustomButton> */}
            {/* // )} */}
          </Box>
        </Box>

        <CustomModal
          visible={isModalOpen}
          action={action}
          setVisible={setIsModalOpen}
          scope={scope}
          userId={userId}
          setdata={setData}
          setcardData={setcardData}
          generatetableData={generatetableData}
        />
      </Box>
      {cardData ? (
        <Box pb={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Paper />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <OptimaxTable
        title={'User Lists'}
        data={tableData}
        column={columns}
        editFunction={editfun}
        deleteFunction={delfun}
        editText={'Edit User'}
        deleteText={'Delete User'}
        // disableDeleteCondition={disablefun}
        filter={true}
        search={false}
        actions={
          pageLevelAccess !== true
            ? []
            : [
                {
                  icon: () => {
                    return (
                      <IconButton
                        component="span"
                        size="small"
                        style={{
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontSize: '14px',
                          fontWeight: 500,
                          fontStyle: 'normal',
                          color: '#22334A',
                          lineHeight: '16px',
                        }}>
                        <Edit fontSize="small" />
                      </IconButton>
                    );
                  },
                  tooltip: 'Edit User',
                  onClick: editfun,
                },
                rowData => ({
                  icon: () => {
                    return (
                      <IconButton
                        component="span"
                        size="small"
                        style={{
                          fontFamily: 'Roboto',
                          fontStyle: 'normal',
                          fontSize: '14px',
                          fontWeight: 500,
                          fontStyle: 'normal',
                          color: '#A30A0C',
                          lineHeight: '16px',
                        }}>
                        <Delete fontSize="small" />
                      </IconButton>
                    );
                  },
                  tooltip: 'Delete User',
                  onClick: delfun,
                  // disabled: props.disableDeleteCondition,
                }),
              ]
        }
      />
    </div>
  );
};
export default UserManagement;

import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import { isUndefined } from 'lodash';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    height: '40px',
    borderRadius: '4px',
    paddingLeft: '10px',
    border: '1px solid #D1DEEB',
  },

  indeterminateColor: {
    color: '#f50057',
  },

  selectAllText: {
    fontWeight: 500,
    fontSize: '14px',
  },

  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },

  menuItems: {
    padding: '0px',
  },

  text: {
    fontSize: '14px',
  },
}));

const ITEM_HEIGHT = 45;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export default function MultiSelectDropdown(props) {
  let selected = props.selected;
  let setSelected = props.setSelected;
  const [options, setOptions] = useState(props.dropdown);

  useEffect(() => {
    setOptions(props.dropdown);
  }, [props.dropdown]);

  const classes = useStyles();
  const isAllSelected = options.length > 0 && selected.length === options.length;

  const handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(
        selected.length === options.length
          ? []
          : options.map(item => {
              return item.value;
            }),
      );
      if (!isUndefined(props.onChangeFun)) {
        props.onChangeFun();
      }
      return;
    }

    if (isUndefined(props.limit)) {
      setSelected(value);
    } else {
      if (value.length <= props.limit) {
        setSelected(value);
      }
      if (value.length === props.limit) {
        // disable all remaining options
        setOptions(prev => {
          prev.forEach(item => {
            if (!value.includes(item.value)) {
              item.disabled = true;
            }
          });
          // console.log(prev);
          return prev;
        });
      }
      if (value.length === props.limit - 1) {
        // enable all options
        setOptions(prev => {
          prev.forEach(item => {
            item.disabled = false;
          });
          return prev;
        });
      }
    }

    if (!isUndefined(props.onChangeFun)) {
      props.onChangeFun();
    }
  };

  return (
    <FormControl className={props.className ? props.className : classes.formControl}>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={selected => {
          if (selected.length >= 5) return selected.length + ' Selected';
          else return selected.join(', ');
        }}
        MenuProps={MenuProps}>
        {isUndefined(props.limit) ? (
          <MenuItem
            className={classes.menuItems}
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}>
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={selected.length > 0 && selected.length < options.length}
              />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
          </MenuItem>
        ) : null}
        {isUndefined(props.limit)
          ? options.map((option, index) => (
              <MenuItem className={classes.menuItems} key={index} value={option.value}>
                <ListItemIcon>
                  <Checkbox checked={selected.indexOf(option.value) > -1} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.text }} primary={option.value} />
              </MenuItem>
            ))
          : options.map((option, index) => (
              <MenuItem className={classes.menuItems} key={index} value={option.value} disabled={option.disabled}>
                <ListItemIcon>
                  <Checkbox checked={selected.indexOf(option.value) > -1} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.text }} primary={option.value} />
              </MenuItem>
            ))}
      </Select>
      {props.error !== '' && (
        <FormHelperText error style={{ marginTop: 9 }}>
          {props.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

import React, { useEffect, useState } from 'react';

const TechDetail1 = props => {
  const [activeClassName, setActiveClassName] = useState();
  const [form, setForm] = useState({});
  const [getConnectedAt, setConnectedAt] = useState({});
  const [getVoltageLevel, setVoltageLevel] = useState({});
  let property = props;
  let voltage = {};

  useEffect(() => {
    setActiveClassName('form-section ' + props.active);
    if (!!props.apiData.voltage_level) {
      voltage = props.apiData.voltage_level;
      voltage = voltage.map((type, index) => {
        type.disabled = false;
        return type;
      });
      setVoltageLevel(voltage);
    }
    if (!!props.apiData.connectivity_level) {
      setConnectedAt(props.apiData.connectivity_level);
    }
  }, [property.active]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    props.handleData(field, value);
  };

  const onChangeConectivityLevel = value => {
    setField('connectivity_level', value);
    if (value == '1') {
      let voltage = getVoltageLevel.map((type, index) => {
        if (type.name == 11 || type.name == 33 || type.name == 66) {
          type.disabled = true;
          setField('voltage_level', '');
        }
        return type;
      });
      setVoltageLevel(voltage);
    } else {
      let voltage = getVoltageLevel.map((type, index) => {
        if (type.name == 11 || type.name == 33 || type.name == 66) {
          type.disabled = false;
        }
        return type;
      });
      setVoltageLevel(voltage);
    }
  };

  return (
    <div className={activeClassName}>
      <h2 className="form_section_head">Technical Details (1/2)</h2>
      <div className="form_wrapper_section_grid">
        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/calc_form_ico1.svg'} />
            Annual Energy Consumption
          </div>
          <label htmlFor="firstname">ENTER INPUT IN MUS(MILLION UNITS)</label>
          <input
            type="number"
            id="form2a"
            placeholder="Enter The Value"
            className="form-control"
            value={
              !!props.data.annual_energy_consumption ? props.data.annual_energy_consumption : form.annual_energy_consumption
            }
            onChange={e => setField('annual_energy_consumption', e.target.value)}
          />
          <span className="errormessage">{props.error.annual_energy_consumption}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/calc_form_ico1.svg'} />
            Desired Replacement Quantum
          </div>
          <label htmlFor="firstname">ENTER INPUT IN MW</label>
          <input
            type="number"
            id="form2b"
            placeholder="Enter The Value"
            className="form-control"
            value={
              !!props.data.desired_replaced_quantum_mw
                ? props.data.desired_replaced_quantum_mw
                : form.desired_replaced_quantum_mw
            }
            onChange={e => setField('desired_replaced_quantum_mw', e.target.value)}
          />
          <span className="errormessage">{props.error.desired_replaced_quantum_mw}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/calc_form_ico1.svg'} />
            Desired Replaced Quantum
          </div>
          <label htmlFor="firstname">ENTER INPUT IN MUS(MILLION UNITS)</label>
          <input
            type="number"
            id="form2c"
            placeholder="Enter The Value"
            className="form-control"
            value={
              !!props.data.desired_replaced_quantum_mu
                ? props.data.desired_replaced_quantum_mu
                : form.desired_replaced_quantum_mu
            }
            onChange={e => setField('desired_replaced_quantum_mu', e.target.value)}
          />
          <span className="errormessage">{props.error.desired_replaced_quantum_mu}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/calc_form_ico1.svg'} />
            Average Cost of Power Procurement
          </div>
          <label htmlFor="firstname">ENTER INPUT IN RS./KWH</label>
          <input
            type="number"
            id="form2d"
            placeholder="Enter The Value"
            className="form-control"
            data-parsley-maxlength="100"
            data-parsley-required
            data-parsley-required-message="Please Average Cost of Power Procurement"
            value={!!props.data.power_purchase_cost ? props.data.power_purchase_cost : form.power_purchase_cost}
            onChange={e => setField('power_purchase_cost', e.target.value)}
          />
          <span className="errormessage">{props.error.power_purchase_cost}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/calc_form_ico1.svg'} />
            Connected at
          </div>
          <label htmlFor="firstname">SELECT CONNECTIVITY LEVEL</label>
          <div className="radio_inpt_grid">
            {Object.keys(getConnectedAt).length > 0
              ? getConnectedAt.map((type, index) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        name="radio"
                        value={type.id}
                        onChange={e => onChangeConectivityLevel(e.target.value)}
                      />
                      <span>
                        {type.name} <img src={'../../imagesNew/info-circle.svg'} />
                        <span className="tooltip_hover_form">{type.description}</span>
                      </span>
                    </label>
                  );
                })
              : ''}
          </div>
          <span className="errormessage">{props.error.connectivity_level}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src="../../imagesNew/calc_form_ico1.svg" />
            Connectivity Voltage Level
          </div>
          <label htmlFor="firstname">SELECT VOLTAGE LEVEL (KV)</label>
          <div className="radio_inpt_grid2">
            {Object.keys(getVoltageLevel).length > 0
              ? getVoltageLevel.map((type, index) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        name="radio2"
                        value={type.id}
                        disabled={type.disabled}
                        onChange={e => setField('voltage_level', e.target.value)}
                      />
                      <span className={type.disabled ? 'disabled' : ''}>{type.name}</span>
                    </label>
                  );
                })
              : ''}
          </div>
          <span className="errormessage">{props.error.voltage_level}</span>
        </div>
      </div>
    </div>
  );
};

export default TechDetail1;

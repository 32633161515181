import React, { useEffect, useState } from 'react';
import { RENEW_URL,RENEW_CAREER } from 'v2/constant/constant';

const Header = (props) => {
  return (
    <header id="header" style={{display:props.getDisplay}}>
      <div className="top-bar-header" >
        <div className="container-fluid padding-7 line-height-normal">
          <div className="row m-0 top-hedd">
            <div className="old-website">
              {' '}
              <a href={RENEW_URL} target="_blank">
                {' '}
                Old Website{' '}
              </a>{' '}
            </div>
            <div className="nav nav1">
              <nav>
                <a href="" className="mobile-menu-trigger">
                  Open
                </a>
                <ul className="menu menu-bar">
                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link" aria-haspopup="true">
                      Work With Us
                    </a>
                    <ul className="mega-menu  menu-ul fadeIn">
                      <div className="work-with-us-top">
                        <div className="work-with-us-inner">
                          <h1 className="menubar-title">Work With Us</h1>
                          <p className="menubar-desc">
                            Be a part of the clean energy transition while exploring learning and growth opportunities
                          </p>
                        </div>
                        <div className="work-with-us-btn">
                          <a href={RENEW_CAREER} target="_blank" className="">
                            Apply Now
                          </a>
                        </div>
                      </div>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li className="submenu-title-ul display-show">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Life At ReNew
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/culture-at-renew" className="menu-link menu-list-link">
                                Culture @ ReNew
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/continuous-learning" className="menu-link menu-list-link">
                                Continuous Learning
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/employee-engagement" className="menu-link menu-list-link">
                                Employee Engagement
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/growth-opportunities" className="menu-link menu-list-link">
                                Growth Opportunities
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Campus Engagemement
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/renews-programs" className="menu-link menu-list-link">
                                ReNews Programs
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/re-lead" className="menu-link menu-list-link">
                                Re-Lead
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Diversity, Equity & Integrity
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/diversity-inclusion" className="menu-link menu-list-link">
                                Diversity & Inclusion{' '}
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/disclosuers-and-reporting" className="menu-link menu-list-link">
                                Policies
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/safety-culture-renew" className="menu-link menu-list-link">
                                Safety Culture At ReNew
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link" aria-haspopup="true">
                      RE- Insights
                    </a>
                    <ul className="mega-menu mega-menu--flat fadeIn lower-txt">
                      <li>
                        <h1 className="menubar-title">RE-Insights</h1>
                        <p className="menubar-desc">
                          Meaningful industry insights and in-depth information to help you understand the renewable energy
                          space
                        </p>
                        <hr className="menu-divide"></hr>
                        <a href="https://www.renew.com/re-glossary" className="menu-link mega-menu-link mega-menu-header txt">
                          RE Glossary
                        </a>
                        <a href="https://www.renew.com/for-corporates" className="menu-link mega-menu-link mega-menu-header txt">
                          For Corporates
                        </a>
                        <a
                          href="https://www.renew.com/whitepapers-resources"
                          className="menu-link mega-menu-link mega-menu-header txt">
                          Whitepapers & Resources
                        </a>
                      </li>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link" aria-haspopup="true">
                      Business Enquiries
                    </a>
                    <ul className="mega-menu mega-menu--flat fadeIn lower-txt">
                      <li>
                        <h1 className="menubar-title">Business Enquiries</h1>
                        <p className="menubar-desc">
                          Contact us to know more about how our business can bring you closer to your Net-Zero goals
                        </p>
                        <hr className="menu-divide"></hr>
                        <a href="https://www.renew.com/contact-us" className="menu-link mega-menu-link mega-menu-header txt">
                          Send Your Enquiry
                        </a>
                      </li>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="main-header">
        <div className="container-fluid padding-7 line-height-normal">
          <div className="row m-0">
            <div className="nav">
              <a className="brand-logo color-logo-img" href="https://www.renew.com">
                <img src={'../.../../imagesNew/renew_logo.png'} className="img-fluid" />
              </a>
              <a className="brand-logo white-logo-img" href="https://www.renew.com">
                <img src={'../../imagesNew/ReNew-logo-pride-m.png'} className="img-fluid" />
              </a>
              <nav className="navbar navbar-default m-0 p-0">
                <div id="nav-icon1" className="mobile-menu-trigger">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <ul className="menu menu-bar">
                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link temp14-item" aria-haspopup="true">
                      About Us
                    </a>
                    <ul className="mega-menu menu-ul fadeIn temp14-model">
                      <h1 className="menubar-title">About Us</h1>
                      <p className="menubar-desc">A walkthrough of the what, why, and how of our business</p>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li className="submenu-title-ul display-show">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Our Entrepreneurial Journey
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/who-we-are" className="menu-link menu-list-link">
                                Who We Are
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/the-future-of-energy" className="menu-link menu-list-link">
                                The Future of Energy
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/over-decade-of-transformation" className="menu-link menu-list-link">
                                Over A Decade Of Transformation
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/global-presence" className="menu-link menu-list-link">
                                Global Footprint
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Our Entrepreneurial Journey
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/who-we-are" className="menu-link menu-list-link">
                                Who We Are
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/the-future-of-energy" className="menu-link menu-list-link">
                                The Future of Energy
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/over-decade-of-transformation" className="menu-link menu-list-link">
                                A Decade Of Transformation
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/global-presence" className="menu-link menu-list-link">
                                Global Footprint
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Our Team
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/leadership" className="menu-link menu-list-link">
                                Leadership
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Our Team
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/leadership" className="menu-link menu-list-link">
                                Leadership
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Research & Development
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/our-partner" className="menu-link menu-list-link">
                                Our Partners
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/centre-of-excellence" className="menu-link menu-list-link">
                                Center Of Excellence
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Research & Development
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/our-partner" className="menu-link menu-list-link">
                                Our Partners
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/centre-of-excellence" className="menu-link menu-list-link">
                                Center Of Excellence
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Press Room
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/press-release" className="menu-link menu-list-link">
                                Press Releases
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/news" className="menu-link menu-list-link">
                                News
                              </a>
                            </li>

                            <li>
                              <a href="https://www.renew.com/coverage-and-events" className="menu-link menu-list-link">
                                Events
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/awards" className="menu-link menu-list-link">
                                Awards
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/email-alert" className="menu-link menu-list-link">
                                Email Alert
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Press Room
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/press-release" className="menu-link menu-list-link">
                                Press Releases
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/news" className="menu-link menu-list-link">
                                News
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/coverage-and-events" className="menu-link menu-list-link">
                                Events
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/awards" className="menu-link menu-list-link">
                                Awards
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/email-alert" className="menu-link menu-list-link">
                                Email Alert
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link temp14-item" aria-haspopup="true">
                      Decarbonization Solutions
                    </a>
                    <ul className="mega-menu menu-ul fadeIn temp14-model">
                      <h1 className="menubar-title">Decarbonization Solutions</h1>
                      <p className="menubar-desc">Building a carbon-free world through holistic solutions</p>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul decarb">
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Energy Generation
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/wind-energy" className="menu-link menu-list-link">
                                Utility-Scale Wind
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/solar-energy" className="menu-link menu-list-link">
                                Utility-Scale Solar
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/hydropower-energy" className="menu-link menu-list-link">
                                Hydropower
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/hybrid-energy" className="menu-link menu-list-link">
                                Firm Power - Round-The-Clock
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Energy Generation
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/wind-energy" className="menu-link menu-list-link">
                                Utility-Scale Wind
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/solar-energy" className="menu-link menu-list-link">
                                Utility-Scale Solar
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/hydropower-energy" className="menu-link menu-list-link">
                                Hydropower
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/hybrid-energy" className="menu-link menu-list-link">
                                Firm Power - Round-The-Clock
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Net Zero Solutions For Corporates
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://www.renew.com/corporate-power-purchase-agreements-ppa"
                                className="menu-link menu-list-link">
                                Innovative PPA
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/green-credits" className="menu-link menu-list-link">
                                Green Credits
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/energy-management-solutions" className="menu-link menu-list-link">
                                Energy Management Solutions
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/energy-battery-storage" className="menu-link menu-list-link">
                                Storage
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Net Zero Solutions For Corporates
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://www.renew.com/corporate-power-purchase-agreements-ppa"
                                className="menu-link menu-list-link">
                                Innovative PPA
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/green-credits" className="menu-link menu-list-link">
                                Green Credits
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/energy-management-solutions" className="menu-link menu-list-link">
                                Energy Management Solution
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/energy-battery-storage" className="menu-link menu-list-link">
                                Storage
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Green Hydrogen
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/green-hydrogen" className="menu-link menu-list-link">
                                Overview
                              </a>
                            </li>
                          </ul>
                          <a className="urji-calc" href="https://lcc.renew.com/reform" target="_blank">
                            <img src={'../../imagesNew/calc-urji.png'} alt="GENERATED" className="img-fluid" />
                            <br />
                            <p className="decab-link">
                              Urji Calculator <i className="fa fa-arrow-right" aria-hidden="true"></i>
                            </p>
                          </a>
                          <a
                            href="https://renew.com/sustainability-calculator"
                            target="_blank"
                            className="menu-link menu-list-link decab-link border-0">
                            Begin Your Decarbonization Journey
                          </a>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Transmission
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/transmission" className="menu-link menu-list-link">
                                Overview
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Green Hydrogen
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/green-hydrogen" className="menu-link menu-list-link">
                                Overview
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Transmission
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/transmission" className="menu-link menu-list-link">
                                Overview
                              </a>
                            </li>
                          </ul>
                          <a
                            href="https://renew.com/sustainability-calculator"
                            target="_blank"
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Begin Your Decarbonization Journey
                          </a>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link temp14-item" aria-haspopup="true">
                      Leading Sustainably
                    </a>
                    <ul className="mega-menu menu-ul fadeIn temp14-model">
                      <h1 className="menubar-title">Leading Sustainably</h1>
                      <p className="menubar-desc">
                        Commitment to the planet and its future forms the core of our sustainability journey.
                      </p>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Our Approach
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://www.renew.com/esg-environment-social-governance"
                                className="menu-link menu-list-link">
                                Approach to sustainability
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/focused-initiative" className="menu-link menu-list-link">
                                Focused Initiatives
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Advocacy & Partnership
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/advocacy-and-partnership" className="menu-link menu-list-link">
                                Advocacy & Partnership
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Social
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://www.renew.com/csr-corporate-social-responsibility"
                                className="menu-link menu-list-link">
                                Social Responsibility
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Reports & Disclosures
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://www.renew.com/disclosuers-and-reporting" className="menu-link menu-list-link">
                                Reports & Disclosures
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Our Approach
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://www.renew.com/esg-environment-social-governance"
                                className="menu-link menu-list-link">
                                Approach To Sustainability
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/focused-initiative" className="menu-link menu-list-link">
                                Focused Initiatives
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Advocacy & Partnership
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/advocacy-and-partnership" className="menu-link menu-list-link">
                                Advocacy & Partnership
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Social
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://www.renew.com/csr-corporate-social-responsibility"
                                className="menu-link menu-list-link">
                                Social Responsibility
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Reports & Disclosures
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/disclosuers-and-reporting" className="menu-link menu-list-link">
                                Reports & Disclosures
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="navbar-menu-title">
                    <a href="" className="menu-link menu-bar-link temp14-item" aria-haspopup="true">
                      Investor Relations
                    </a>
                    <ul className="mega-menu  menu-ul fadeIn temp14-model">
                      <h1 className="menubar-title">Investor Relations</h1>
                      <p className="menubar-desc">Innovating to build a greener future responsibly</p>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Governance
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a href="https://investor.renewpower.in/" className="menu-link menu-list-link">
                                Overview
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/corporate-profile" className="menu-link menu-list-link">
                                Company Profile
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/leadership" className="menu-link menu-list-link">
                                leadership
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/governance" className="menu-link menu-list-link">
                                Corporate Governance
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Financing/Debt Capital Market
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://renewpower.in/bondholder-information/"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Bondholder Information
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            IR Materials
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://investor.renewpower.in/financials/sec-filings"
                                target="_blank"
                                className="menu-link menu-list-link">
                                SEC Filings
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/quarterly-results" className="menu-link menu-list-link">
                                Quarterly Results
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/annual-reports" className="menu-link menu-list-link">
                                Annual Reports{' '}
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/other-presentations" className="menu-link menu-list-link">
                                Other Presentations
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/investor-faqs" className="menu-link menu-list-link">
                                Investor FAQs
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/historical-financials" className="menu-link menu-list-link">
                                Historical Financials{' '}
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/annual-meeting" className="menu-link menu-list-link">
                                Annual Meeting
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Stock
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://investor.renewpower.in/stock-information"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Stock Quote and Chart
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://investor.renewpower.in/stock-information/analyst-coverage"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Analyst Coverage
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul ">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            News and Events
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://investor.renewpower.in/news-events/news-releases"
                                target="_blank"
                                className="menu-link menu-list-link">
                                News
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://investor.renewpower.in/news-events/events"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Events
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-show submenu-title-ul">
                          <a href="" className="menu-link mega-menu-link mega-menu-header pe-none">
                            Alerts and Contact US
                          </a>
                          <ul className="menu menu-list">
                            <li>
                              <a
                                href="https://investor.renewpower.in/resources/email-alerts"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Email Alerts{' '}
                              </a>
                            </li>

                            <li>
                              <a href="https://www.renew.com/contact-ir" className="menu-link menu-list-link">
                                Contact IR
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Governance
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://investor.renewpower.in/" className="menu-link menu-list-link">
                                Overview
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/corporate-profile" className="menu-link menu-list-link">
                                Company Profile
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/leadership" className="menu-link menu-list-link">
                                Leadership
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/governance" className="menu-link menu-list-link">
                                Corporate Governance
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Financing/Debt Capital Market
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://renewpower.in/bondholder-information/" className="menu-link menu-list-link">
                                Bondholder Information
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            IR Materials
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://investor.renewpower.in/financials/sec-filings"
                                target="_blank"
                                className="menu-link menu-list-link">
                                SEC Filings
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/quarterly-results" className="menu-link menu-list-link">
                                Quarterly Results
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/annual-reports" className="menu-link menu-list-link">
                                Annual Reports
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/other-presentations" className="menu-link menu-list-link">
                                Other Presentations
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/investor-faqs" className="menu-link menu-list-link">
                                Investor FAQs
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/historical-financials" className="menu-link menu-list-link">
                                Historical Financials
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/annual-meeting" className="menu-link menu-list-link">
                                Annual Meeting
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Stock
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://investor.renewpower.in/stock-information"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Stock Quote And Chart
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://investor.renewpower.in/stock-information/analyst-coverage"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Analyst Coverage
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            News And Events
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://investor.renewpower.in/news-events/news-releases"
                                target="_blank"
                                className="menu-link menu-list-link">
                                News
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://investor.renewpower.in/news-events/events"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Events
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Alerts And Contact US
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a
                                href="https://investor.renewpower.in/resources/email-alerts"
                                target="_blank"
                                className="menu-link menu-list-link">
                                Email Alerts{' '}
                              </a>
                            </li>

                            <li>
                              <a href="https://www.renew.com/contact-ir" className="menu-link menu-list-link">
                                Contact IR
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="top-header-mobile mt-8">
                    <a href="" className="menu-link menu-bar-link temp14-item" aria-haspopup="true">
                      Work With Us
                    </a>
                    <ul className="mega-menu  menu-ul fadeIn temp14-model">
                      <div className="work-with-us-top">
                        <div className="work-with-us-inner">
                          <h1 className="menubar-title">Work With Us</h1>
                          <p className="menubar-desc">
                            Be a part of the clean energy transition while exploring learning and growth opportunities
                          </p>
                        </div>
                        <div className="work-with-us-btn">
                          <a href="https://careers.renewpower.in/" target="_blank" className="">
                            Apply Now
                          </a>
                        </div>
                      </div>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Life At ReNew
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/culture-at-renew" className="menu-link menu-list-link">
                                Culture @ ReNew
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/continuous-learning" className="menu-link menu-list-link">
                                Continuous Learning
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/employee-engagement" className="menu-link menu-list-link">
                                Employee Engagement
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/growth-opportunities" className="menu-link menu-list-link">
                                Growth Opportunities
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Campus Engagemement
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/renews-programs" className="menu-link menu-list-link">
                                ReNew's Programs
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/re-lead" className="menu-link menu-list-link">
                                Re-Lead
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="display-hide">
                          <a
                            href=""
                            className="menu-link mega-menu-link mega-menu-header menu-list-link temp11-head"
                            aria-haspopup="true">
                            Diversity, Equity & Integrity
                          </a>
                          <ul className="menu menu-list temp11-content">
                            <li>
                              <a href="https://www.renew.com/diversity-inclusion" className="menu-link menu-list-link">
                                Diversity & Inclusion{' '}
                              </a>
                            </li>
                            <li>
                              <a href="" className="menu-link menu-list-link">
                                Policies
                              </a>
                            </li>
                            <li>
                              <a href="" className="menu-link menu-list-link">
                                Alumni Connect
                              </a>
                            </li>
                            <li>
                              <a href="https://www.renew.com/safety-culture-renew" className="menu-link menu-list-link">
                                Safety Culture At ReNew
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="top-header-mobile">
                    <a href="" className="temp14-item menu-link menu-bar-link" aria-haspopup="true">
                      RE-Insights
                    </a>
                    <ul className="mega-menu  menu-ul fadeIn temp14-model">
                      <h1 className="menubar-title">RE-Insights</h1>
                      <p className="menubar-desc">
                        Meaningful industry insights and in-depth information to help you understand the renewable energy
                        space
                      </p>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li>
                          <a href="https://www.renew.com/re-glossary" className="menu-link mega-menu-link mega-menu-header">
                            RE Glossary
                          </a>
                        </li>
                        <li>
                          <a href="https://www.renew.com/for-corporates" className="menu-link mega-menu-link mega-menu-header">
                            For Corporates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.renew.com/whitepapers-resources"
                            className="menu-link mega-menu-link mega-menu-header">
                            Whitepapers & Resources
                          </a>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="top-header-mobile">
                    <a href="" className="menu-link menu-bar-link temp14-item" aria-haspopup="true">
                      Business Enquiries
                    </a>
                    <ul className="mega-menu  menu-ul fadeIn temp14-model">
                      <h1 className="menubar-title">Business Enquiries</h1>
                      <p className="menubar-desc">
                        Contact us to know more about how our business can bring you closer to your Net-Zero goals
                      </p>
                      <hr className="menu-divide"></hr>
                      <ul className="submenu-ul">
                        <li>
                          <a href="https://www.renew.com/contact-us" className="menu-link mega-menu-link mega-menu-header">
                            Send Your Enquiry
                          </a>
                        </li>
                      </ul>
                      <li className="mobile-menu-back-item">
                        <a href="" className="menu-link mobile-menu-back-link">
                          Back
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Checkbox, FormControlLabel, FormGroup, RadioGroup, Radio } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import axios from '../../../services/auth/config';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@mui/material/ListSubheader';

const useStyles = makeStyles(theme =>
  createStyles({
    inputtext: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    select: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  }),
);

const CompanyDetails = ({ handleNext, handleFormData, values }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});
  var temp = '';
  var tempIndex = '';
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    handleFormData(field, value);
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { company_name, sector, plant_type, plant_location } = values;
    const newErrors = {};
    if (!company_name || company_name === '') newErrors.company_name = 'Please Enter Company Name';
    if (typeof company_name !== 'undefined') {
      if (!company_name.match(/^[a-zA-Z 0-9]*$/)) {
        newErrors.company_name = 'Please Enter Correct Name.';
      }
    }
    if (!sector || sector === '') newErrors.sector = 'Please Select Sector';
    if (!plant_type || plant_type === '') newErrors.plant_type = 'Please Select Industry Type';
    if (!plant_location || plant_location === '') newErrors.plant_location = 'Please Select State';
    return newErrors;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      handleNext();
    }
  };

  const CardStyle = {
    card: {
      variant: 'outlined',
      borderColor: '#E1FFA3',
    },
    cardheader: {
      color: '#081f12',
      background: '#76c04e',
      // background: '#b2df8a',
    },
    primarytext: {
      color: '#4A5339',
      fontWeight: 'regular',
    },
    secondarytext: {
      color: '#38693E',
      fontStyle: 'oblique',
    },
    errormessage: {
      color: '#FF0000',
    },
  };
  const [getSectors, setSectors] = React.useState([]);
  const [getPlantType, setPlantType] = React.useState([]);
  const [getPlantLocations, setPlantLocations] = React.useState([]);
  useEffect(() => {
    axios.get('getformmasterdata', { params: { master_data_type: 0 } }).then(({ data }) => {
      setSectors(data.data.sectors);
      setPlantType(data.data.plant_types);
      setPlantLocations(data.data.plant_location);
    });
  }, []);
  let changeCompanyState = (e) => {
    setField('plant_location', e.target.value);
    getPlantLocations.map((type, index) => {
      if (type.id == e.target.value) {
        setField('plant_location_type', type.type)
      }
    });
  }
  return (
    <Box m={2}>
      <Card style={CardStyle.card} variant="outlined" sx={{ boxShadow: 20 }}>
        <CardHeader component={Typography} title="Company Details" boxShadow={5} style={CardStyle.cardheader} />

        <CardContent>
          <Grid container rowSpacing={2} spacing={1}>
            <Grid xs={6}>
              <Box>
                <Box sx={{ ml: 2, mt: 2 }}>
                  <Typography variant="h6" display="block" style={CardStyle.primarytext} gutterBottom>
                    Company Name
                  </Typography>
                  <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                    Enter the Name of the Company
                  </Typography>
                </Box>
                <TextField
                  InputProps={{ className: classes.inputtext }}
                  sx={{ width: 500, height: 40, m: 1, pl: 4 }}
                  value={values.company_name ? values.company_name : form.company}
                  onChange={e => setField('company_name', e.target.value)}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Company Name"
                />
                <Box ml={5} mt={1} style={CardStyle.errormessage}>
                  {errors.company_name}
                </Box>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ ml: 2, mt: 2 }}>
                <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                  Sector
                </Typography>
                <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                  Select the sector applicable
                </Typography>
              </Box>
              <FormControl
                size="large"
                sx={{
                  width: 500,
                  height: 50,
                  m: 1,
                  pl: 4,
                }}
                fullWidth>
                {/* <InputLabel id="demo-simple-select-error-label">--Select Sector--</InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={values.sector ? values.sector : ''}
                  title="Test"
                  onChange={e => setField('sector', e.target.value)}>
                  {getSectors.map((type, index) => {
                    return <MenuItem value={type.id}>{type.name}</MenuItem>;
                  })}
                </Select> */}
                <Select
                  className={classes.select}
                  value={values.sector ? values.sector : ''}
                  onChange={e => setField('sector', e.target.value)}
                  displayEmpty
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>--Select Sector--</em>;
                  //   }
                  //   return selected;
                  // }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {getSectors.map((type, index) => {
                    return <MenuItem value={type.id}>{type.name}</MenuItem>;
                  })}
                </Select>
                <div style={CardStyle.errormessage}>{errors.sector}</div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ ml: 2, mt: 2 }}>
                <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                  Type
                </Typography>
                <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                  Select the type of industry applicable
                </Typography>
              </Box>
              <FormControl
                size="large"
                sx={{
                  width: 500,
                  height: 50,
                  m: 1,
                  pl: 4,
                }}
                fullWidth>
                {/* <InputLabel id="demo-simple-select-error-label">--Select Industry Type--</InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={values.plant_type ? values.plant_type : ''}
                  onChange={e => setField('plant_type', e.target.value)}>
                  {getPlantType.map((type, index) => {
                    return <MenuItem value={type.id}>{type.name}</MenuItem>;
                  })}
                </Select> */}
                <Select
                  className={classes.select}
                  value={values.plant_type ? values.plant_type : ''}
                  onChange={e => setField('plant_type', e.target.value)}
                  displayEmpty
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>--Select Industry Type--</em>;
                  //   }
                  //   return selected
                  // }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {getPlantType.map((type, index) => {
                    return <MenuItem value={type.id}>{type.name}</MenuItem>;
                  })}
                </Select>

                <div style={CardStyle.errormessage}>{errors.plant_type}</div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <Box sx={{ ml: 2, mt: 2 }}>
                <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                  State
                </Typography>
                <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                  Select the location of plant applicable
                </Typography>
              </Box>
              <FormControl
                size="large"
                sx={{
                  width: 500,
                  height: 50,
                  m: 1,
                  pl: 4,
                }}
                fullWidth>
                {/* <InputLabel id="demo-simple-select-error-label">--Select State--</InputLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  value={values.plant_location ? values.plant_location : ''}
                  onChange={e => setField('plant_location', e.target.value)}>
                  {getPlantLocations.map((type, index) => {
                    return <MenuItem value={type.id}>{type.name}</MenuItem>;
                  })}
                </Select> */}
                <Select id="grouped-select" label="Grouping"
                  // value={plantName}
                  className={classes.select}
                  value={values.plant_location ? values.plant_location : ''}
                  onChange={changeCompanyState} 
                  name=''
                  displayEmpty
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>--Select State--</em>;
                  //   }
                  //   return selected
                  // }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {
                  getPlantLocations.map((type, index) => {
                    if (type.type != temp) {
                       temp = type.type;
                       let str;
                       if (temp == 1) {
                        str = "Non Renew";
                       } else {
                        str = "Renew";
                       }
                       return [
                      //  <ListSubheader>{str}</ListSubheader>, 
                       <MenuItem  data-name={type.type} value={type.id} >{type.name}</MenuItem>
                      ];
                    } else {
                      return <MenuItem  data-name="{type.type}" value={type.id} >{type.name}</MenuItem>;
                    } 
                  })
                  }
                </Select>
                <div style={CardStyle.errormessage}>{errors.plant_location}</div>
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button
              style={{
                borderRadius: 20,
                borderColor: '#077D3E',
                padding: '8px 16px',
                fontSize: '15px',
                margin: '10px',
                color: '#077D3E',
              }}
              variant="outlined"
              onClick={handleSubmit}
              endIcon={<NavigateNextIcon />}>
              {'Next'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CompanyDetails;

import React from "react";

const Windmil = (props) => {
    return(
    <video autoPlay={true} loop={true} muted={true} playsInline={false} className="calc_windmill_gif" 
    style={{display:props.getDisplay}}>
		<source src="../../imagesNew/calc_gif.mp4" type="video/mp4"/>
	</video>
    );
}

export default Windmil;
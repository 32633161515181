import React, { useEffect, useState,Fragment } from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import axios from '../../services/auth/config';
const borderColor = '#fff'

const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      backgroundColor: '#378243',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
      marginBottom: 20,
      width: '100%',
    },
    topHead: {
        height: 20,
        width: '100%',
    },
});

const ReplacementSaving = () => {
    let [greenUri, setgreenUri] = useState('');
    let [biAxialUri, setbiAxialUri] = useState('');

  useEffect(() => {
    setgreenUri(localStorage.getItem('green_acheived_bar'));
    setbiAxialUri(localStorage.getItem('biaxial_bar'));
  }, []);

  return (
    <Fragment>
      <View style={styles.container}>
          <Text style={styles.topHead}>Replacement & Savings</Text>
      </View>
       <View>
         <Image src={`${biAxialUri}`} />
      </View>
      <View style={{top: '40px'}}>
         <Image src={`${greenUri}`} />
      </View>
      
    </Fragment>
  );
};

export default ReplacementSaving;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AuhMethods } from '../../services/auth/index';
import Dialog from '@material-ui/core/Dialog';
import AppTextInput from '../../@jumbo/components/Common/formElements/AppTextInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { emailNotValid, requiredMessage } from '../../@jumbo/constants/ErrorMessages';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidEmail } from '../../@jumbo/utils/commonHelper';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectDropdown from '../../@custom/UI/MultiSelectDropdown';
import { isUndefined } from 'lodash';
import AppSelectBox from '../../@jumbo/components/Common/formElements/AppSelectBox';
import { OutlinedInput, InputLabel, Box, Button, IconButton, InputAdornment, FormHelperText } from '@material-ui/core';
import { localStorage_getItem } from '../../@custom/LocalStorage';

const useStyles = makeStyles(() => ({
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: '#505F79',
    },
    paddingLeft: '0px !important',
  },

  dialogSubTitleRoot: {
    fontSize: 12,
    color: '#505F79',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    //lineHeight:16,
    fontWeight: 500,
  },

  InputLabelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
    width: '130px',
    height: 15,
    lineHeight: '17px',
    paddingBottom: '10px',
  },
}));

const AddEditUser = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [currentUser, setUpdateUser] = useState('');
  const [isUpdated, setIsUpdated] = useState('');
  const [full_name, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordvis, setpasswordvis] = useState(false);
  // const [passwordType, setPasswordType] = useState('password');
  const [emailError, setEmailError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [groupError, setGroupError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupNameToID, setGroupNameToID] = useState({});
  const [groupIDToName, setGroupIDToName] = useState({});
  const [group_id, setGroup_id] = useState([]);
  const [groupDropdownData, setGroupDropdownData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyDropdownData, setCompanyDropdownData] = useState([]);
  const [company_id, setCompany_id] = useState(localStorage_getItem('company_id'));
  const [formData, updateFormData] = React.useState();
  const [userDetail, setuserDetail] = useState({});
  const [user_type, setUser_type] = useState(localStorage_getItem('user_type'));
  const [companyIdtoName, setcompanyIdtoName] = useState({});

  const [open, setOpen] = React.useState(props.open);
  const [userId, setUserId] = React.useState(props.userId);
  const dispatch = useDispatch();
  let type = isUndefined(props.type) ? '' : props.type;
  const [editedUserId, seteditedUserId] = useState(null);
  const [editedUserCompany, seteditedUserComapny] = useState(null);
  const current_user_type = localStorage_getItem('user_type');
  const access_list = localStorage_getItem('access_list');
  let isUserMgmtAccess = access_list && access_list.edit_page_level_access.indexOf('User Management') !== -1;
  let isGroupMgmtAccess = access_list && access_list.edit_page_level_access.indexOf('Group Management') !== -1;

  //console.log(' access_list ->',access_list,' & current_user_type ->',current_user_type,' isUserMgmtAccess ->',isUserMgmtAccess,' & isGroupMgmtAccess ->',isGroupMgmtAccess,' & access ->',(current_user_type === '1') || (isGroupMgmtAccess===true))

  let method = 'jwtUserMngement';

  useEffect(() => {
    // dispatch(AuhMethods['jwtCompanyManagement'].companyList(setCompanyData, ''));

    if (props.action === 'edit') {
      dispatch(AuhMethods[method].getCurrentUser(userId, setUpdateUser, setIsUpdated));
    }
  }, []);
  // useEffect(() => {
  //   dispatch(AuhMethods['jwtGroupManagement'].groupWithStatus(setGroupData, '', company_id, 1));
  // }, [company_id]);

  useEffect(() => {
    // console.log(groupData);
    let groups = [];
    let groups_id = [];
    let obj_nameToID = {};
    let obj_idToName = {};
    if (groupData.length > 0) {
      groupData.forEach(element => {
        let group = { label: element.id, value: element.name };
        let group_id = element.id;
        obj_nameToID[element.name] = element.id;
        obj_idToName[element.id] = element.name;
        groups.push(group);
        groups_id.push(group_id);
      });
    }
    // console.log(obj_nameToID);
    setGroupDropdownData(groups);
    setGroupNameToID(obj_nameToID);
    setGroupIDToName(obj_idToName);
  }, [groupData]);

  useEffect(() => {
    // console.log(selectedGroup);
    // console.log(groupNameToID);
    setGroupError('');
    let groups_id = [];
    if (selectedGroup.length > 0) {
      selectedGroup.forEach(element => {
        // console.log(element);
        groups_id.push(groupNameToID[element]);
      });
    }
    // console.log(groups_id.toString());
    setGroup_id(groups_id.toString());
  }, [selectedGroup]);

  useEffect(() => {
    if (currentUser !== '') {
      let groups_name = [];
      setUserName(currentUser.full_name ? currentUser.full_name : '');
      setEmail(currentUser.email ? currentUser.email : '');
      // if (!isUndefined(currentUser.data.group_id)) {
      //   if (currentUser.data.group_id.length > 0) {
      //     let current_group_id = currentUser.data.group_id.split(',');
      //     // console.log(' current_group_id ->', current_group_id);
      //     current_group_id.forEach(element => {
      //       // console.log(' element ->', element);
      //       if (element) {
      //         if (groupIDToName[element]) {
      //           groups_name.push(groupIDToName[element]);
      //         }
      //       }
      //     });
      //   }
      //   // console.log(' groups_name -> ', groups_name);
      //   setSelectedGroup(groups_name);
      // }
    }
  }, [currentUser, groupIDToName]);

  useEffect(() => {
    // console.log(companyData);
    let companies = [];
    let obj = {};
    if (companyData.length > 0) {
      companyData.forEach(element => {
        if (element.status === '1') {
          let company = { id: element.id, name: element.name };
          obj[element.id] = element.name;
          companies.push(company);
        }
      });
    }
    // console.log(company_nameToID);
    setcompanyIdtoName(obj);
    setCompanyDropdownData(companies);
  }, [companyData]);

  const options = [
    { typeID: '1', name: 'Super Admin' },
    { typeID: '2', name: 'Admin' },
  ];

  const onClcikPasswordHide = () => {
    setpasswordvis(prev => {
      return !prev;
    });
  };

  useEffect(() => {
    if (currentUser) {
      if (props.action === 'add') {
        if (currentUser && currentUser.status === 'success') {
        } else if (currentUser.data && currentUser.data.status === 'error') {
          let userObj = JSON.parse(currentUser.config.data);
          setUserName(userObj.full_name);
          setEmail(userObj.email);
          setPassword(userObj.password);
        } else {
          setUserName(currentUser.full_name);
          setEmail(currentUser.email);
          setPassword(currentUser.password);
        }
      } else {
        if (currentUser && currentUser.data && currentUser.status === 'success') {
          // console.log(currentUser);
          setCompany_id(currentUser.data.company_id);
          seteditedUserId(currentUser.data.id);
          setUserName(currentUser.data.full_name);
          setEmail(currentUser.data.email);
          setUser_type(currentUser.data.user_type);
        } else if (currentUser.data && currentUser.data.status === 'error') {
          let userObj = JSON.parse(currentUser.config.data);
          setUserName(userObj.full_name);
          setEmail(userObj.email);
          //setPassword(userObj.password);
        } else {
          setUserName(currentUser.full_name);
          setEmail(currentUser.email);
          //setPassword(currentUser.password);
        }
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (isUpdated === 1) {
      if (type !== 'header') {
        if (props.action === 'edit') {
          props.setdata(prev => {
            let p = [];
            prev.forEach(item => {
              // if (item.id === editedUserId) {
              if (item.id === userId) {
                p.push({
                  ...item,
                  full_name: full_name,
                  email: email,
                  // company_name: companyIdtoName[company_id],
                  updatedAt: new Date(),
                  //  group: selectedGroup.join(','),
                });
              } else {
                p.push(item);
              }
            });
            console.log(p);
            return p;
          });
        } else {
          props.setdata(prev => {
            let p = [...prev];
            p.push({
              blocked_on: null,
              createdAt: new Date(),
              email: userDetail.email,
              id: editedUserId,
              phone_number: null,
              recovered_on: null,
              status: '1',
              updatedAt: new Date(),
              full_name: userDetail.full_name,
              company_name: editedUserCompany,
              group: selectedGroup.join(','),
            });
            return p;
          });
          props.setcardData(prev => {
            prev['active']++;
            prev['total']++;
            return { ...prev };
          });
        }
      }
      props.onCloseDialog(false);
      props.generatetableData();
    }
  }, [isUpdated]);

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitClick = e => {
    e.preventDefault();
    // console.log(formData);
    let isValid = true;
    if (!full_name) {
      setUserNameError(requiredMessage);
      isValid = false;
    }
    if (!email) {
      setEmailError(requiredMessage);
      isValid = false;
    } else {
      if (!isValidEmail(email)) {
        setEmailError(emailNotValid);
        isValid = false;
      }
    }
    //console.log(' props.action ->',props.action,' & group_id ->',group_id)
    if ((props.action === 'add' || props.action === 'edit') && !password) {
      setPasswordError(requiredMessage);
      isValid = false;
    }
    // if (!group_id) {
    //   setGroupError(requiredMessage);
    //   isValid = false;
    // }
    if (isValid) {
      onUserSave();
    }
  };

  const generatePassword = () => {
    const string = 'abcdefghijklmnopqrstuvwxyz';
    const numeric = '0123456789';
    const punctuation = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
    const length = 10;
    let character = '';
    let password = '';
    while (password.length < length) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
      let hold = string.charAt(entity1);
      hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split('')
      .sort(() => {
        return 0.5 - Math.random();
      })
      .join('');
    setPassword(password.substr(0, length));
    setpasswordvis(true);
  };

  const onUserSave = () => {
    let data = {
      full_name,
      email,
      password,
      company_id,
      group_id,
      user_type,
    };
    setuserDetail(data);
    if (props.action === 'edit') {
      if (password === '') {
        delete data.password;
      }

      dispatch(AuhMethods[method].updateUser(userId, data, setIsUpdated));
    } else {
      dispatch(AuhMethods[method].addNewUser(data, seteditedUserId, seteditedUserComapny, setIsUpdated));
    }
  };

  return (
    <Box>
      <Dialog open={open}>
        <form>
          <DialogContent>
            <DialogTitle className={classes.dialogTitleRoot}>
              {props.action === 'edit' ? 'Edit User' : 'Add New User'}
            </DialogTitle>
            <IconButton
              size="small"
              style={{ position: 'absolute', right: '7.93%', top: '6.93%' }}
              onClick={() => {
                props.onCloseDialog(false);
              }}>
              <CloseIcon />
            </IconButton>
          </DialogContent>
          <DialogContent>
            {current_user_type === '1' && (
              <Box mb={{ xs: 6, md: 5 }}>
                <InputLabel className={classes.InputLabelText}>Select Company</InputLabel>
                <AppSelectBox
                  name="company"
                  fullWidth
                  variant="outlined"
                  data={companyDropdownData}
                  labelKey="name"
                  valueKey="id"
                  value={company_id}
                  onChange={e => {
                    setCompany_id(e.target.value);
                    // console.log(e.target.value);
                  }}
                  required
                />
              </Box>
            )}
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Full Name *</InputLabel>
              <AppTextInput
                type="text"
                name="full_name"
                fullWidth
                variant="outlined"
                placeholder="Full Name"
                value={full_name}
                onChange={e => {
                  setUserName(e.target.value);
                  setUserNameError('');
                  handleChange(e);
                }}
                helperText={userNameError}
                required
              />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Email *</InputLabel>
              <AppTextInput
                type="email"
                name="email"
                fullWidth
                variant="outlined"
                placeholder="User Email Address"
                value={email}
                onChange={e => {
                  setEmail(e.target.value.trim());
                  setEmailError('');
                  handleChange(e);
                }}
                helperText={emailError}
                // disabled={current_user_type === '1' || isUserMgmtAccess === true ? false : true}
                disabled
              />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Password *</InputLabel>
              <OutlinedInput
                type={passwordvis ? 'text' : 'password'}
                name="password"
                fullWidth
                variant="outlined"
                placeholder="User Password"
                required
                value={password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={onClcikPasswordHide}>
                      {passwordvis ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <IconButton
                      size="small"
                      style={{
                        color: '#056C45',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                        textDecorationLine: 'underline',
                      }}
                      onClick={() => generatePassword()}>
                      Generate
                    </IconButton>
                  </InputAdornment>
                }
                onChange={e => {
                  setPassword(e.target.value);
                  handleChange(e);
                  setPasswordError('');
                }}
              />
              <FormHelperText error id="password-error" style={{ marginLeft: 10 }}>
                {passwordError}
              </FormHelperText>
            </Box>
            {(current_user_type === '1' || isGroupMgmtAccess === true) && (
              <Box mb={{ xs: 6, md: 5 }}>
                <InputLabel className={classes.InputLabelText}>Select Group *</InputLabel>
                <MultiSelectDropdown
                  setSelected={setSelectedGroup}
                  selected={selectedGroup}
                  dropdown={groupDropdownData}
                  error={groupError}
                />
              </Box>
            )}
            {current_user_type === '1' && (
              <Box mb={{ xs: 6, md: 5 }}>
                <InputLabel className={classes.InputLabelText}>Select User Type *</InputLabel>
                <AppSelectBox
                  name="userType"
                  fullWidth
                  variant="outlined"
                  data={options}
                  labelKey="name"
                  valueKey="typeID"
                  value={user_type}
                  onChange={e => {
                    setUser_type(e.target.value);
                    // console.log(e.target.value);
                  }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogContent>
            <Box mb={{ xs: 0, md: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
                style={{ width: '100%', background: '#056C45', color: '#FFFFFF' }}>
                {props.action === 'edit' ? 'Update' : 'Submit'}
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Box>
  );
});

export default AddEditUser;

AddEditUser.prototype = {
  open: PropTypes.bool.isRequired,
  //onCloseDialog: PropTypes.func,
  onCloseDialog: PropTypes.bool.isRequired,
};

import React from 'react';
import { Box, Button, Divider, IconButton } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px',
  },
  customButton: {
    color: 'white',
    backgroundColor: 'black',
    fontSize: '16px',
  },
  loginSection: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiDivider-root': {
      height: 14,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

const HeaderLogin = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <MailOutlineIcon style={{ fontSize: '16px' }} />
        <a href="mailto: go.green@renew.com" className="ml-3" style={{ color: 'white' }}>
          go.green@renew.com
        </a>
      </Box>
      <div className={classes.loginSection}>
        <IconButton href="https://www.linkedin.com/company/renew-green-solutions" className={classes.customButton}>
          <LinkedInIcon className={classes.customButton} />
        </IconButton>
        <Divider className="Cmt-divider" orientation="vertical" />
        <IconButton href="https://twitter.com/ReNewB2B" className={classes.customButton}>
          <TwitterIcon className={classes.customButton} />
        </IconButton>
      </div>
    </div>
  );
};

export default HeaderLogin;

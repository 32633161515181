import React, { useEffect, useState,Fragment } from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import axios from '../../services/auth/config';
const borderColor = '#fff'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#98D066',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 30,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    container2: {
      flexDirection: 'row',
      borderBottomColor: '#6c757d',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
      textAlign: 'center',
      color: '#6c757d',
      flexGrow: 1,
    },
    container3: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      backgroundColor: '#378243',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
      marginBottom: 20,
      width: '100%',
    },
    topHead: {
      // backgroundColor: '#378243',
      height: 20,
      width: '100%',
    },
    head1: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    head2: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    subHead1: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    subHead2: {
      width: '40%',
    },
    subHead3: {
      width: '25%',
    },
    subHead4: {
      width: '25%',
    },
    col1: {
      width: '10%',
      // borderRightColor: '#6c757d',
      // borderRightWidth: 1,
    },
    col2: {
      width: '40%',
    },
    col3: {
      width: '25%',
    },
    col4: {
      width: '25%',
    },
    logo: {
      maxHeight: 150,
      maxWidth: 80,
      marginBottom: 10,
    },
    thankuUp: {
      marginBottom: 8,
      textTransform: 'capitalize',
    },
    thankuDown: {
      marginBottom: 10,
      marginLeft: 3
    },
    text1: {
      marginBottom: 5,
      marginLeft: 3
    },
    text2: {
      marginBottom: 5,
      marginLeft: 3
    },

});
function createData(tname, tDetails, ntname, ntDetails) {
  return {
    tname,
    tDetails,
    ntname,
    ntDetails,
  };
}
const UserDetails = ({ decryptedData }) => {
  let [rows, setRows] = useState([]);
  let [name, setName] = useState('');
  useEffect(() => {
    axios.post('formdata/fetch', { user_id: decryptedData.uid }).then(({ data }) => {
      if (data.status == true) {
        let InputUserData = data.data;
        InputUserData = InputUserData[0];
        let network = InputUserData.network;
        let state = InputUserData.injection_state;
        let injection_level = 'STU';
        if (network == 'Inter-state') {
          state = 'Maharashtra';
          injection_level = 'CTU';
        }
        setName(InputUserData.full_name);
        setRows([
          createData(
            'Annual Energy Consumption',
            InputUserData.annual_energy_consumption,
            'Plant Location',
            InputUserData.plant_location,
          ),
          createData('Average Cost of Power procurement', InputUserData.power_purchase_cost, 'Injection State', state),
          createData('Connected at', InputUserData.connectivity_level, 'Injection Network', InputUserData.network),
          createData('Connectivity Voltage Level', InputUserData.voltage_level, 'Injection Level', injection_level),
          createData('Desired Replacement Quantum (in MU)', InputUserData.desired_replaced_quantum_mu, '-', '-'),
          createData('Desired Replacement Quantum (in MW)', InputUserData.desired_replaced_quantum_mw, '-', '-'),
          createData('Open Acess Mode', InputUserData.oa_mode, '-', '-'),
        ]);
      }
    });
  }, []);

  return (
    <Fragment>
      <View>
        <Text style={styles.thankuUp}>Thank you {`${name}`}</Text>
        <Text style={styles.text1}>Please find the details of the output/solutions per the requirement you shared.</Text>
        <Text style={styles.text2}>We are keen to discuss the finer details at length and will be happy to connect.Please feel free to drop an email to go.green@renew.com and our specialist 
           will be in touch with you suitably.</Text>
        <Text style={styles.thankuDown}>Thank you once again!</Text>
        <Image src='/images/B2B_GreenSolutionslogo-01.jpg' style={styles.logo}/>
      </View>
      <View style={styles.container3}>
          <Text style={styles.topHead}>User Details</Text>
      </View>
      <View style={styles.container}>
          <Text style={styles.head1}>Technical</Text>
          <Text style={styles.head2}>Non Technical</Text>
      </View>
      <View style={styles.container}>
          <Text style={styles.subHead2}>Input Data</Text>
          <Text style={styles.subHead1}>Details</Text>
          <Text style={styles.subHead3}>Input Data</Text>
          <Text style={styles.subHead4}>Details</Text>
      </View>
      {rows.map(row => (
          <View style={styles.container2}>
            <Text style={styles.col2}>{row.tname}</Text>
            <Text style={styles.col1}>{row.tDetails}</Text>
            <Text style={styles.col3}>{row.ntname}</Text>
            <Text style={styles.col4}>{row.ntDetails}</Text>
          </View>
      ))}
    </Fragment>
  );
};

export default UserDetails;

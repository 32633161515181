import React from "react";

const Urji = (props) =>{
    return(
        <div id="urji-calc" style={{display:props.getDisplay}}>
        <div className="float-sm">
            <div className="fl-fl float-fb">
                <a href="https://lcc.renew.com/reform"><img src="../../../imagesNew/calc-urji.png"/>Urji Calculator</a>
            </div>
        </div>
    </div>
    );
}

export default Urji;

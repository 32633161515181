import './style.css';
import React from 'react';
import Chart from "react-apexcharts";

const BiaxialBarChartApexChart = ({ data }) => (
    <Chart options={data.options} series={data.series} type="line" width="100%" height={350}/>
    // <ReactApexChart options={data.options} series={data.series} type="line" height={350} />
);

export default BiaxialBarChartApexChart;


import JWTAuth from './jwt';
import BasicAuth from './Basic';
import UserManagement from '../user/index';

export const AuhMethods = {
  /*firebase: Firebase,*/
  jwtAuth: JWTAuth,
  basic: BasicAuth,
  jwtUserMngement: UserManagement,
};

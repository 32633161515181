import React, { useEffect, useState } from 'react';
import VerticalComposedChart from './VerticalComposedChart';
import BiaxialBarChart from './BiaxialBarChart';
import BiaxialBarChartApexChart from './BiaxialBarChartApexChart';
import StackedBarGraphApexChart from './StackedBarGraph';
import axios from '../../services/auth/config';
import { Card, Grid } from '@material-ui/core';
import { CardContent, makeStyles } from '@material-ui/core';
import { Box } from '@mui/system';

function OutputFormtwo({ decryptedData }) {
  let [greeningAcheivedGraph, setGreeningAcheived] = useState([]);
  let [biaxialBarChartData, setBiaxialBarChartData] = useState({});
  function createData(name, greening_acheived,existing) {
    return {
      name,
      greening_acheived,
      existing
    };
  }
  function createData1(name, carbon_saved, saving) {
    return {
      name,
      carbon_saved,
      saving,
    };
  }
  useEffect(() => {
    axios.get('/outputform/getgraphdata/' + decryptedData.uid).then(({ data }) => {
      if (data.status == true) {
        let InputUserData = data.data;
        if (InputUserData.length != 0) {
          let greeningAcheived = InputUserData.greening_acheived[0];
          let savingOutput = InputUserData.savings[0];
          let carbonSavedOutput = InputUserData.carbonSaved[0];
          // setGreeningAcheived(
          //   Object.keys(greeningAcheived).map(function(key) {
          //     if (key != 'name' && key != 'Existing' ) {
          //       return createData(key, greeningAcheived[key],greeningAcheived['Existing']);
          //     }
          //     return 0;
          //   }),
          // );
          
          // setBiaxialBarChartData(
          //   Object.keys(savingOutput).map(function(key) {
          //     if (key != 'name' && key != 'unit') {
          //       return createData1(key, carbonSavedOutput[key], savingOutput[key]);
          //     }
          //     return 0;
          //   }),
          // );
          greeningAcheived['Solar'] = parseInt(greeningAcheived['Solar']) > parseInt(greeningAcheived['Existing'])?
          parseInt(greeningAcheived['Solar']) - parseInt(greeningAcheived['Existing']) : 
          parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Solar']);

          greeningAcheived['Wind'] = parseInt(greeningAcheived['Wind']) > parseInt(greeningAcheived['Existing'])?
          parseInt(greeningAcheived['Wind']) - parseInt(greeningAcheived['Existing']) : 
          parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Wind']);

          greeningAcheived['Hybrid Low CUF'] = parseInt(greeningAcheived['Hybrid Low CUF']) > parseInt(greeningAcheived['Existing'])?
          parseInt(greeningAcheived['Hybrid Low CUF']) - parseInt(greeningAcheived['Existing']) : 
          parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Hybrid Low CUF']);

          greeningAcheived['Hybrid High CUF'] = parseInt(greeningAcheived['Hybrid High CUF']) > parseInt(greeningAcheived['Existing'])?
          parseInt(greeningAcheived['Hybrid High CUF']) - parseInt(greeningAcheived['Existing']) : 
          parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Hybrid High CUF']);
          setGreeningAcheived(
            {
            series: [{
            name: 'Existing',
            data: [greeningAcheived['Existing'], greeningAcheived['Existing'],
            greeningAcheived['Existing'], greeningAcheived['Existing']]
          }, {
            name: 'Calculated',
            data: [greeningAcheived['Solar'],greeningAcheived['Wind'],greeningAcheived['Hybrid Low CUF'],
            greeningAcheived['Hybrid High CUF']]
          }],
          options: {
            chart: {
            type: 'bar',
            height: 350,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                },
                formatter: function (val) {
                  return val + "%"
                }
              }
            },
          },
          dataLabels: {   
            formatter: function (val) {
              return val + "%"
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          title: {
            text: 'Greening Achieved(%)',
            align: 'center',
          },
          xaxis: {
            categories: ["Solar", "Wind", "Hybrid Low CUF","Hybrid High CUF"],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "%"
              }
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            markers : {
              radius: 12
            }
          }
        }})
          

          setBiaxialBarChartData({
            series: [{
              name: 'Savings(Rs Cr)',
              type: 'column',
              data: [savingOutput['Solar'],savingOutput['Wind'],savingOutput['Hybrid Low CUF'],
              savingOutput['Hybrid High CUF']]
            }, {
              name: 'Carbon Saved(Tonnes/Y)',
              type: 'line',
              data: [carbonSavedOutput['Solar'],carbonSavedOutput['Wind'],carbonSavedOutput['Hybrid Low CUF'],
              carbonSavedOutput['Hybrid High CUF']]
            }],
            options: {
              plotOptions: {
                bar: {
                  dataLabels: {
                    position: 'bottom',
                  },
                  columnWidth: '50%',
                  barHeight: '90%'
                }
              },
              colors: ['#008FFB', '#ED7D31'],
              chart: {
                height: 550,
                type: 'line',
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
              stroke: {
                width: [0, 4]
              },
              title: {
                text: 'Economic & Carbon Savings',
                align: 'center'
              },
              dataLabels: {
                enabled: true,
                formatter: function (value) {
                  return value.toLocaleString('en-US');
                }
              },
              labels: ["Solar", "Wind", ["Hybrid Low","CUF"],["Hybrid High","CUF"]],
              xaxis: {
                type: 'string',
                
              },
              yaxis: [{
                title: {
                  text: 'Savings',
                },
                style: {
                  fontWeight: '500'
                },
                labels: {
                  formatter: function (value) {
                    return value.toLocaleString('en-US');
                  }
                },
              }, {
                opposite: true,
                title: {
                  text: 'Carbon Saved',
                  rotate: 90,
                },
                labels: {
                  formatter: function (value) {
                    return value.toLocaleString('en-US');
                  },
                  
                },
                
              }]
            },
          })
        }
      }
    });
  }, []);
  // greeningAcheivedGraph = greeningAcheivedGraph.filter(function(el) {
  //   return el != 0;
  // });
  // sort the data in specified order start   
  // const importOrder = ["Hybrid High CUF", "Hybrid Low CUF", "Wind", "Solar", "Existing"];
  // const sortByObject = importOrder
  // .reduce((obj, item, index) => {
  //   return {
  //     ...obj,
  //     [item]: index,
  //   };
  // }, {});
  // greeningAcheivedGraph = greeningAcheivedGraph.sort((a, b) => sortByObject[a.name] - sortByObject[b.name]);
  // sort the data in specified order start
  // biaxialBarChartData = biaxialBarChartData.filter(function(el) {
  //   return el != 0;
  // });
  // biaxialBarChartData = biaxialBarChartData.sort((a, b) => sortByObject[a.name] - sortByObject[b.name]);
  // biaxialBarChartData = biaxialBarChartData.reverse();
  return (
    <Box style={{marginTop:'40px'}}>
      <Grid container spacing={2}>
        { Object.keys(biaxialBarChartData).length != 0 ? (
          <Grid item xs={6}>
            <BiaxialBarChartApexChart data={biaxialBarChartData} />
          </Grid>
          ) : (
            <Grid item xs={6}></Grid>
          )
        }
        { Object.keys(greeningAcheivedGraph).length != 0 ? (
          <Grid item xs={6}>
            <StackedBarGraphApexChart data={greeningAcheivedGraph} />
          </Grid>
          ) : (
            <Grid item xs={6}></Grid>
          )
        }
      </Grid>
    </Box>
  );
}

export default OutputFormtwo;

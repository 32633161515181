import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Grid, Paper, Card, Button, List } from '@material-ui/core';
import CmtImage from '../../@coremat/CmtImage';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { multipleFilesUpload } from '../../@jumbo/constants/ErrorMessages';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

var count = 15;

const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};
const FileUploadProgress = props => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = React.useState(0);
  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: props.acceptType ? props.acceptType : '', //'image/*',
    maxFiles: props.isDataUpload ? 1 : 6, //props.maxFiles,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      setProgress(0);
    },
  });

  useEffect(() => {
    if (files.length !== 0) {
      // console.log(files);
      props.getCsv(files);
      setProgress(100);
    } else {
      setProgress(0);
      props.getCsv(files);
    }
  }, [files]);

  const removeFile = value => {
    // console.log(value);
    const newFiles = files.filter(element => element.path !== value);
    setFiles(newFiles);
    // console.log(document.getElementById(value));
    document.getElementById(value).style.display = 'none';
  };

  const files1 = acceptedFiles.map((file, i) => (
    <Box
      style={{
        backgroundColor: '#F5F7F9',
        border: '1px solid #D8E6F4',
        boxSizing: 'border-box',
        borderRadius: 6,
        marginTop: 10,
        position: 'relative',
      }}
      key={file.path}
      id={file.path}>
      <Box>
        <Card
          style={{
            backgroundColor: '#F5F7F9',
            overflow: 'visible',
            boxShadow: 'none',
          }}>
          <div style={{ width: '100%', marginTop: '5px' }}>
            <Box display="flex" p={1} style={{ backgroundColor: '#F5F7F9' }}>
              <Box p={1}>
                <CmtImage src={'/images/onboarding/image.png'} />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: 'Helvetica',
                    color: '#000',
                  }}>
                  <dfn data-title={file.path}>{file.path.slice(0, count) + (file.path.length > count ? '...' : '')}</dfn>
                </Typography>
              </Box>
              <Box p={1}>
                <Typography
                  style={{
                    fontSize: 12,
                    fontFamily: 'Helvetica',
                    color: '#000',
                  }}>
                  {bytesToSize(file.size)}
                </Typography>
              </Box>
            </Box>

            <Box
              style={{
                minWidth: '30%',
                maxWidth: '85%',
                marginLeft: '40px',
                marginBottom: '5px',
                marginRight: '10px',
              }}>
              <LinearProgress variant="determinate" value={progress} color="secondary" label={`${progress}%`} />
            </Box>
          </div>
        </Card>
        <Box>
          {progress && progress === 100 && (
            <IconButton
              onClick={() => {
                removeFile(file.path, i);
              }}
              style={{
                backgroundColor: 'rgb(15, 145, 210)',
                color: '#FFF',
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                width: '24px',
                height: '24px',
              }}>
              <CloseIcon viewBox={'0 0 40 40'} style={{ marginTop: 10, marginLeft: 10 }} />
            </IconButton>
          )}
        </Box>

        <List style={{ position: 'absolute', top: 134 }}>{fileRejectionItems}</List>
      </Box>
    </Box>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }, key) => {
    if (key === 0) {
      //   props.setIsLogoError(true);
      return (
        <li key={file.path}>
          <p style={{ color: 'red', fontSize: '12px', fontWeight: 400 }}>{multipleFilesUpload}</p>
        </li>
      );
    }
  });

  return (
    <React.Fragment>
      <Box
        display={'flex'}
        justifyContent={'center'}
        style={{
          width: props.width,
          height: props.height,
          background: '#F6F8FA',
          border: '1px dashed #A9C2DB',
          boxSizing: 'border-box',
          borderRadius: '6px',
        }}
        {...getRootProps({ className: 'dropzone' })}>
        <Box>
          <input {...getInputProps()} name="logo" />
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontSize: '14px',
            }}>
            {props.isDataUpload && props.isDataUpload === true ? (
              <React.Fragment>
                <Typography align="center">Add File/Drag and Upload</Typography>
                <Grid item className={'elementOR'}>
                  <Typography className="or_fup">OR</Typography>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography align="center">Drag your documents, photos, or videos here to start uploading</Typography>
                <Grid item className={'elementOR'}>
                  <Typography className="or">OR</Typography>
                </Grid>
              </React.Fragment>
            )}
          </Typography>
          <Box display={'flex'} justifyContent={'center'}>
            <Button variant="contained" type="button" color="secondary" onClick={open}>
              Browse Files
            </Button>
            <input {...getInputProps()} name="logo" />
          </Box>
        </Box>
        {props.isDataUpload && <p style={{ color: 'red', fontSize: '12px', fontWeight: 400 }}>{props.error}</p>}
        <aside style={{ width: '100%' }}>{files1}</aside>
      </Box>
    </React.Fragment>
  );
};

export default FileUploadProgress;

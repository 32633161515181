import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    // height: '40px',
    borderRadius: '4px',
    border: '1px solid #D1DEEB',
    background: '#ffffff',
    paddingLeft: '10px',
  },

  indeterminateColor: {
    color: '#f50057',
  },

  selectAllText: {
    fontWeight: 500,
    fontSize: '14px',
  },

  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },

  menuItems: {
    padding: '0px',
  },

  text: {
    fontSize: '12px',
  },
}));

const ITEM_HEIGHT = 45;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export default function MultiSelectTable(props) {
  let options = props.dropdown;
  const onFilterChanged = props.onFilterChanged;
  const columnDef = props.columnDef;
  const [selected, setSelected] = useState(props.dropdown);

  const classes = useStyles();
  const isAllSelected = options.length > 0 && selected.length === options.length;

  const handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(
        selected.length === options.length
          ? []
          : options.map(item => {
              return item;
            }),
      );
      return;
    }
    setSelected(value);
  };
  useEffect(() => {
    onFilterChanged(columnDef.tableData.id, selected);
    // console.log(selected);
  }, [selected]);

  return (
    <FormControl className={props.className ? props.className : classes.formControl}>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={selected => (isAllSelected ? 'All' : selected.join(', '))}
        MenuProps={MenuProps}
        className={classes.text}>
        <MenuItem
          className={classes.menuItems}
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}>
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={selected.length > 0 && selected.length < options.length}
            />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem className={classes.menuItems} key={index} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.text }} primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React, { useEffect, useState,Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import axios from '../../services/auth/config';
const borderColor = '#fff'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        backgroundColor: '#98D066',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 30,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    container2: {
      flexDirection: 'row',
      borderBottomColor: '#6c757d',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
      textAlign: 'center',
      color: '#6c757d',
      flexGrow: 1,
    },
    container3: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      backgroundColor: '#378243',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
      marginBottom: 20,
      width: '100%',
    },
    topHead: {
      height: 20,
      width: '100%',
    },
    head1: {
        width: '25%',
    },
    head2: {
        width: '13%',
    },
    head3: {
        width: '17%',
    },
    head4: {
        width: '19%',
    },
    col1: {
        width: '25%', 
    },
    col2: {
        width: '13%', 
    },
    col3: {
        width: '17%',
    },
    col4: {
        width: '19%',
    },

});
function createData(particulars, uom, solar, wind, hybrid1, hybrid2, history) {
    return {
      particulars,
      uom,
      solar,
      wind,
      hybrid1,
      hybrid2,
      history,
    };
  }
const LandedTariff = ({ decryptedData }) => {
    let [rows, setRows] = useState([]);
    
    function merge(a, b, i=0) {
      return a.slice(0, i).concat(b, a.slice(i));
    }
    useEffect(() => {
        axios.get('/formdata/get-ilt-calculator-data/' + decryptedData.uid).then(({ data }) => {
            if (data.status == true) {
            let InputUserData = data.data;
            if (InputUserData.length != 0) {
                let InputUserData1 = InputUserData[0];
                let InputUserData2 = InputUserData[1];
                var result = merge(InputUserData1, InputUserData2, 3);
                setRows(
                  result.map(function(element) {
                    return createData(element['name'], element['unit'], element['Solar'],element['Wind'],
                    element['Hybrid Low CUF'],element['Hybrid High CUF'], []);
                  }),
                );
            }
            }
        });
       
    }, []);
  return (
    <Fragment>
      <View style={styles.container3}>
          <Text style={styles.topHead}>Landed Tariff</Text>
      </View>
      <View style={styles.container}>
          <Text style={styles.head1}>Particulars</Text>
          <Text style={styles.head2}>Uom</Text>
          <Text style={styles.head2}>Solar</Text>
          <Text style={styles.head2}>Wind</Text>
          <Text style={styles.head3}>Hybrid Low CUF</Text>
          <Text style={styles.head4}>Hybrid High CUF</Text>
      </View>
      {rows.map(row => (
          <View style={styles.container2}>
            <Text style={styles.col1}>{row.particulars}</Text>
            <Text style={styles.col2}>{row.uom}</Text>
            <Text style={styles.col2}>{row.solar}</Text>
            <Text style={styles.col2}>{row.wind}</Text>
            <Text style={styles.col3}>{row.hybrid1}</Text>
            <Text style={styles.col4}>{row.hybrid2}</Text>
          </View>
          ))}
    </Fragment>
  );
};

export default LandedTariff;

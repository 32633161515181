import React, { useContext } from 'react';
import FooterLogo from './FooterLogo';
import { Box, makeStyles, Grid, Paper, Link, Typography, Divider } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { THEME_TYPES } from '../../../constants/ThemeOptions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paperStyle: {
    backgroundColor: '#1B1B1B',
    boxShadow: 'none',
    margin: '34px',
    color: 'white',
    fontSize: '14px',
  },
  gridStyle: {
    fontSize: '16px',
    backgroundColor: '#1B1B1B',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      width: 900,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1100,
    },
    [theme.breakpoints.up('xl')]: {
      width: 1400,
    },
  },
  linkStyle: {
    color: 'grey',
  },
  linkIconStyle: {
    color: 'green',
    borderRadius: '50%',
    border: '2px solid grey',
    padding: '4px',
    margin: '4px',
  },
  btnRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 11,
    },
  },
}));

const Footer = props => {
  const classes = useStyles();
  const { themeType } = useContext(AppContext);
  const date = new Date();

  return (
    <Box className={classes.root} {...props}>
      <Grid className={classes.gridStyle} container spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paperStyle}>
            Reach out to us @ <br /> <br />
            <Typography variant="h2">
              <a href="mailto: go.green@renew.com" style={{ color: 'dodgerblue' }}>
                <MailOutlineIcon style={{ fontSize: '16px', marginRight: '5px' }} />
                go.green@renew.com
              </a>
            </Typography>
          </Paper>
          <Paper className={classes.paperStyle}>
            <Typography variant="h2">Corporate Office </Typography>
            ReNew Hub, Commercial Block-1, Zone 6, Golf Course Road, DLF City Phase-V, Gurugram-122009, Haryana
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paperStyle}>
            <Typography variant="h3">Company </Typography> <br />
            <Link className={classes.linkStyle} href="https://renew.com/innovative-ppa">
              About ReNew Green Solutions
            </Link>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paperStyle}>
            <Typography variant="h3">Support </Typography> <br />
            <Link className={classes.linkStyle} href="https://renew.com/terms-and-conditions">
              Terms of Use
            </Link>
            <br />
            <Link className={classes.linkStyle} href="https://renew.com/privacy-and-policy">
              Privacy Policy
            </Link>
            <br />
            <Link className={classes.linkStyle} href="https://renew.com/disclaimer-crisil">
              Disclaimer (CRISIL)
            </Link>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper className={classes.paperStyle}>
            <Typography variant="h3">Follow Us </Typography> <br />
            <Link href="https://www.linkedin.com/company/renew-green-solutions">
              <LinkedInIcon className={classes.linkIconStyle} fontSize="medium" />
            </Link>
            <Link href="https://twitter.com/ReNewB2B">
              <TwitterIcon className={classes.linkIconStyle} fontSize="medium" />
            </Link>
          </Paper>
        </Grid>
      </Grid>
      <Divider light />
      <Box display="flex" alignItems="center">
        <Box fontSize={{ xs: 12, sm: 14 }} component="p" color="white">
          Copyright © ReNew {date.getFullYear()} - All Rights Reserved
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

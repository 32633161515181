import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    boxShadow: 'none',
    height: '40px',
    border: '2px solid #dde3eb',
    whiteSpace: 'nowrap',

    '@media (max-width: 469px)': {
      fontSize: '16px',
    },
  },
});

const buttonIcons = {
  getTrendingUpIcon: () => {
    return <TrendingUpIcon />;
  },
  getVisibilityIcon: () => {
    return <VisibilityIcon />;
  },
  getPostAddIcon: () => {
    return <PostAddIcon />;
  },
  getCloseIcon: () => {
    return <CloseIcon />;
  },
  getCloneIcon: () => {
    return <FileCopyOutlinedIcon />;
  },
  getAddIcon: () => {
    return <AddIcon />;
  },
  getAccountBalanceIcon: () => {
    return <AccountBalanceWalletIcon />;
  },
};

export default function CustomButton(props) {
  const classes = useStyles();

  let icon = props.icon === '' ? null : buttonIcons[props.icon]();

  return (
    <Box p={props.p} style={props.style}>
      <Button className={classes.root} variant={props.variant} color={props.color} onClick={props.onClick}>
        {icon}
        {props.children}
      </Button>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Radio from '@mui/material/Radio';
import { FormControlLabel, RadioGroup } from '@mui/material';
import axios from '../../../services/auth/config';
import Button from '@mui/material/Button';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Slide from '@mui/material/Slide';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isPositiveInteger, isPositiveNumber } from '../../../@jumbo/constants/AppConstants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme =>
  createStyles({
    inputtext: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    select: {
      '&.Mui-focused': {
        border: '2px solid #8BD000',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
  }),
);
const AdDrawalDetails = ({ handleNext, handleBack, handleFormData, values }) => {
  const [form, setForm] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const classes = useStyles();
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    handleFormData(field, value);
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateForm = () => {
    const { desired_replaced_quantum_mw, power_purchase_cost, desired_replaced_quantum_mu, connectivity_level, voltage_level, annual_energy_consumption } = values;
    const newErrors = {};

    let maxEnergy =  1 * 8.76;
    if (!!(desired_replaced_quantum_mw)) {
      if (!isPositiveNumber(desired_replaced_quantum_mw) ) {
        newErrors.desired_replaced_quantum_mw = 'OA is not possible as per the Electricity Act for <1MW';
      }else if (desired_replaced_quantum_mw < 1 ) {
        newErrors.desired_replaced_quantum_mw = 'OA is not possible as per the Electricity Act for <1MW';
      } else {
        maxEnergy = parseFloat(desired_replaced_quantum_mw) * 8.76;
      }
    }else{
      newErrors.desired_replaced_quantum_mw = 'Please Enter Desired Replacement Quantum'
    }

    // if (!desired_replaced_quantum_mw || desired_replaced_quantum_mw === '') newErrors.desired_replaced_quantum_mw = 'Please Enter Desired Replacement Quantum';
    // if (typeof desired_replaced_quantum_mw !== 'undefined') {
    //   if (!isPositiveNumber(desired_replaced_quantum_mw) ) {
    //       newErrors.desired_replaced_quantum_mw = 'Please Enter Numerical Data';
    //   }else if (desired_replaced_quantum_mw < 1 ) {
    //     newErrors.desired_replaced_quantum_mw = 'OA is not possible as per the Electricity Act for <1MW';
    //   } else {
    //     maxEnergy = parseFloat(desired_replaced_quantum_mw) * 8.76;
    //   }
    // }
    
   if (!!(desired_replaced_quantum_mu)) {
      if (!isPositiveNumber(desired_replaced_quantum_mu) || parseFloat(desired_replaced_quantum_mu) < 0 || parseFloat(desired_replaced_quantum_mu) == -0.0) {
        newErrors.desired_replaced_quantum_mu = 'Please Enter positive Numerical Data';
      } else if (desired_replaced_quantum_mu < 0 || desired_replaced_quantum_mu > 999) {
        newErrors.desired_replaced_quantum_mu = 'Please Enter Between 0 to 999';
      } else{
        if(desired_replaced_quantum_mu > maxEnergy) {
          newErrors.desired_replaced_quantum_mu = ' Information mentioned is wrong/ incorrect';
        }
      }
    }

    if (!power_purchase_cost || power_purchase_cost === '')
      newErrors.power_purchase_cost = 'Please Enter Power Procurement Cost';
    if (typeof power_purchase_cost !== 'undefined') {
      if (!isPositiveNumber(power_purchase_cost)|| parseFloat(power_purchase_cost) < 0 || parseFloat(power_purchase_cost) == -0.0) {
        newErrors.power_purchase_cost = 'Please Enter positive Numerical Data';
      }
    }

    if (!!(annual_energy_consumption)) {
      if (!isPositiveNumber(annual_energy_consumption) || parseFloat(annual_energy_consumption) < 0 || parseFloat(annual_energy_consumption) == -0.0 ) {
        newErrors.annual_energy_consumption = 'Please Enter positive Numerical Data';
      }
    }else{
      newErrors.annual_energy_consumption = 'Please Enter Annual Energy Consumption';
    }

    if (!connectivity_level || connectivity_level === '') newErrors.connectivity_level = 'Please Choose Connectivity Level';

    if (!voltage_level || voltage_level === '') newErrors.voltage_level = 'Please Choose Voltage Level';

    if (parseInt(desired_replaced_quantum_mu) > parseInt(annual_energy_consumption)) {
      newErrors.desired_replaced_quantum_mu = 'Desired Replaced Quantum(in MU) should be less than Annual Energy Consumption.';
	  }

    return newErrors;
  };

  const [open, setOpen] = useState(false);
  const [message, setmessage] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
   let checkContrctDemand = (e) => {
	const { desired_replaced_quantum_mu, annual_energy_consumption } = values;
    if (parseInt(desired_replaced_quantum_mu) > parseInt(annual_energy_consumption)) {
      setmessage('Desired Replaced Quantum(in MU) should be less than Annual Energy Consumption.');
      setOpen(true);
      return true;
	  }
    return false;
  }

  const handleSubmit = e => {
    e.preventDefault();
    const formErrors = validateForm();
	  // let cdStatus = checkContrctDemand();
    // if( cdStatus ) {
    //   if(Object.keys(formErrors).length > 0) {
    //     setErrors(formErrors);
    //   }
    // } else {
      if(Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        handleNext();
      }
   // }
    
  };

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#8BD000',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
      },
    }),
  );

  const CardStyle = {
    card: {
      variant: 'outlined',
      borderColor: '#E1FFA3',
    },
    cardheader: {
      color: '#081f12',
      background: '#76c04e',
      // background: '#b2df8a',
    },
    primarytext: {
      color: '#4A5339',
      fontWeight: 'regular',
    },
    secondarytext: {
      color: '#38693E',
      fontStyle: 'oblique',
    },
    errormessage: {
      color: '#FF0000',
    },
    optional: {
      fontSize: '0.5rem',
    },
  };

  const [getConnectedAt, setConnectedAt] = React.useState([]);
  const [getVoltageLevel, setVoltageLevel] = React.useState([]);
  
  useEffect(() => {
    axios.get('getformmasterdata', { params: { master_data_type: 0 } }).then(({ data }) => {
      setConnectedAt(data.data.connectivity_level);
      let voltage = data.data.voltage_level;
      voltage = voltage.map((type, index) => {
        type.disabled = false;
        return type;
      })
      setVoltageLevel(voltage);
    });
  }, []);
  const onChangeConectivityLevel = (value)=> {
    setField('connectivity_level', value);
    // let voltage = {};
    if(value == '1') {
      let voltage = getVoltageLevel.map((type, index) => {
        if(type.name == 11 || type.name == 33 || type.name == 66) {
          type.disabled = true;
        }
        return type;
      })
      setVoltageLevel(voltage);
    } else {
      let voltage = getVoltageLevel.map((type, index) => {
        if(type.name == 11 || type.name == 33 || type.name == 66) {
          type.disabled = false;
        }
        return type;
      })
      setVoltageLevel(voltage);
    }
    
  }
  return (
    <Box m={2}>
      <Card style={CardStyle.card} variant="outlined" sx={{ boxShadow: 20 }}>
        <CardHeader
          component={Typography}
         // title={'Drawal Details'}
          title={'Technical Details (1/2)'}
          color={'black'}
          boxShadow={5}
          style={CardStyle.cardheader}
        />
        <CardContent>
          <Stack spacing={2}>
            <Grid container rowSpacing={2} spacing={1}>
                <Grid xs={6}>
                    <Box sx={{ ml: 2, mt: 2 }}>
                      <Typography variant="h6" display="block" style={CardStyle.primarytext} gutterBottom>
                        What is the Annual Energy Consumption?
                      </Typography>
                      <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                        Enter Input in MUs(Million Units){' '}
                      </Typography>
                    </Box>
                    <TextField
                      InputProps={{ className: classes.inputtext }}
                      sx={{ width: 500, height: 40, m: 1, pl: 4 }}
                      value={values.annual_energy_consumption ? values.annual_energy_consumption : form.annual_energy_consumption}
                      onChange={e => setField('annual_energy_consumption', e.target.value)}
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      placeholder="Enter the value"
                    />
                    <Box ml={5} mt={2}>
                      <div style={CardStyle.errormessage}>{errors.annual_energy_consumption}</div>
                    </Box>
                </Grid>
                <Grid xs={6}>
                <Box sx={{ ml: 2, mt: 2 }}>
                  <Typography variant="h5" display="block" gutterBottom style={CardStyle.primarytext}>
                    What is the Desired Replacement Quantum?
                  </Typography>
                  <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                  Enter Input in MW{' '}
                  </Typography>
                </Box>
                  <TextField
                    InputProps={{ className: classes.inputtext }}
                    sx={{ width: 500, height: 40, m: 1, pl: 4 }}
                    value={values.desired_replaced_quantum_mw ? values.desired_replaced_quantum_mw : form.desired_replaced_quantum_mw}
                    onChange={e => setField('desired_replaced_quantum_mw', e.target.value)}
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    placeholder="Enter the value"
                  />
                  <Box ml={5} mt={2} style={CardStyle.errormessage}>
                    {errors.desired_replaced_quantum_mw}
                  </Box>
               </Grid>

                <Grid xs={6}>
                  <Box sx={{ ml: 2, mt: 2 }}>
                    <Typography variant="h6" display="block" style={CardStyle.primarytext} gutterBottom>
                      What is the Desired Replaced Quantum?<span style={CardStyle.optional}>(Optional)</span>
                    </Typography>
                    <Typography sx={{ pl: 3, }} variant="caption" display="block" style={CardStyle.secondarytext}>
                      Enter Input in MUs(Million Units){' '}
                    </Typography>
                  </Box>
                    <TextField
                      InputProps={{ className: classes.inputtext }}
                      sx={{ width: 500, height: 40, m: 1, pl: 4 }}
                      value={values.desired_replaced_quantum_mu ? values.desired_replaced_quantum_mu : form.desired_replaced_quantum_mu}
                      onChange={e => setField('desired_replaced_quantum_mu', e.target.value)}
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      placeholder="Enter the value"
                    />
                    <Box ml={5} mt={2} style={CardStyle.errormessage}>
                      {errors.desired_replaced_quantum_mu}
                    </Box>
                </Grid>
                <Grid xs={6}>
                  <Box sx={{ ml: 2, mt: 2 }}>
                    <Typography variant="h5" display="block" gutterBottom style={CardStyle.primarytext}>
                      What is the Average Cost of Power Procurement?{' '}
                    </Typography>
                    <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                      Enter Input in Rs./kWh{' '}
                    </Typography>
                  </Box>
                  <TextField
                    InputProps={{ className: classes.inputtext }}
                    sx={{ width: 520, height: 40, m: 1, pl: 4 }}
                    value={values.power_purchase_cost ? values.power_purchase_cost : form.power_purchase_cost}
                    onChange={e => setField('power_purchase_cost', e.target.value)}
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    placeholder="Enter the value"
                  />
                  <Box ml={5} mt={2} style={CardStyle.errormessage}>
                    {errors.power_purchase_cost}
                  </Box>
                </Grid>
                    
                <Grid xs={6}>
                  <Box sx={{ ml: 2, mt: 2 }}>
                    <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                      Connected at
                    </Typography>
                    <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                      Select Connectivity level{' '}
                    </Typography>
                  </Box>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.connectivity_level ? values.connectivity_level : form.connectivity_level}
                    onChange={e => onChangeConectivityLevel(e.target.value)}
                    sx={{
                      flexDirection: 'row',
                      m: 1,
                      pl: 3,
                    }}>
                    {getConnectedAt.map((type, index) => {
                      return (
                        <>
                          <FormControlLabel
                            sx={{ pl: index == 0 ? 2 : 3, color: '#4A5339' }}
                            value={type.id}
                            label={type.name}
                            control={<Radio sx={{ ' &.Mui-checked': { color: '#077D3E' } }} />}
                          />{' '}
                          <LightTooltip title={type.description} arrow>
                            <Button>
                              <InfoIcon sx={{ color: '#8BD000' }} fontSize="medium" />
                            </Button>
                          </LightTooltip>
                        </>
                      );
                    })}
                  </RadioGroup>
                  <Box ml={5} style={CardStyle.errormessage}>
                    {errors.connectivity_level}
                  </Box>
                </Grid>

                <Grid xs={6}>
                  <Box sx={{ ml: 2, mt: 2 }}>
                    <Typography variant="h6" display="block" gutterBottom style={CardStyle.primarytext}>
                      Connectivity Voltage Level{' '}
                    </Typography>
                    <Typography sx={{ pl: 3 }} variant="caption" display="block" style={CardStyle.secondarytext}>
                      Select Voltage Level (kV)
                    </Typography>
                  </Box>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.voltage_level ? values.voltage_level : form.voltage_level}
                    onChange={e => setField('voltage_level', e.target.value)}
                    sx={{
                      flexDirection: 'row',
                      m: 1,
                      pl: 3,
                    }}>
                    {getVoltageLevel.map((type, index) => {
                      return (
                        <FormControlLabel
                          sx={{ pl: index == 0 ? 2 : 3, color: '#4A5339' }}
                          value={type.id}
                          control={<Radio sx={{ ' &.Mui-checked': { color: '#077D3E' } }} />}
                          label={type.name}
                          disabled={type.disabled}
                        />
                      );
                    })}
                  </RadioGroup>
                  <Box ml={5} style={CardStyle.errormessage}>
                    {errors.voltage_level}
                  </Box>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                style={{
                  borderRadius: 20,
                  borderColor: '#077D3E',
                  padding: '8px 16px',
                  fontSize: '15px',
                  margin: '10px',
                  color: '#077D3E',
                }}
                variant="outlined"
                onClick={handleBack}
                startIcon={<NavigateBeforeIcon />}>
                {'Back'}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button
                style={{
                  borderRadius: 20,
                  borderColor: '#077D3E',
                  padding: '8px 16px',
                  fontSize: '15px',
                  margin: '10px',
                  color: '#077D3E',
                }}
                variant="outlined"
                onClick={handleSubmit}
                endIcon={<NavigateNextIcon />}>
                {'Next'}
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
	  <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {""}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdDrawalDetails;

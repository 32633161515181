/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AuhMethods } from '../../services/auth/index';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { emailNotValid, requiredMessage } from '../../@jumbo/constants/ErrorMessages';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidEmail } from '../../@jumbo/utils/commonHelper';
import { InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
//import MultiSelectDropdown from '../../@custom/UI/MultiSelectDropdown';
import TextField from '@material-ui/core/TextField';
import AppSelectBox from '../../@jumbo/components/Common/formElements/AppSelectBox';
import AppDatePicker from '../../@jumbo/components/Common/formElements/AppDatePicker';
import moment from 'moment';
import { localStorage_getItem } from '../../@custom/LocalStorage';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    //position: 'relative',
    marginLeft: '0px',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: '#505F79', //theme.palette.common.dark,
    },
    paddingLeft: '0px !important',
  },
  dialogSubTitleRoot: {
    fontSize: 12,
    color: '#505F79',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    //lineHeight:16,
    fontWeight: 500,
  },
  InputLabelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
    width: '130px',
    height: 15,
    lineHeight: '17px',
    paddingBottom: '10px',
  },
}));

const ticketTypeArray = [
  { id: '1', value: 'Ground Fault Module - 4 Alert' },
  // { id: '2', value: 'Ground Fault Module - 4 Alert' },
  // { id: '3', value: 'Ground Fault Module - 4 Alert' },
  // { id: '4', value: 'Ground Fault Module - 4 Alert' },
];

const AssignToUserArray = [
  { id: '1', value: 'Ground Fault Module - 4 Alert' },
  { id: '2', value: 'Ground Fault Module - 4 Alert' },
  { id: '3', value: 'Ground Fault Module - 4 Alert' },
  { id: '4', value: 'Ground Fault Module - 4 Alert' },
];

const AssignTicket = props => {
  const classes = useStyles();
  const [currentTicket, setUpdateTicket] = useState('');
  const [isUpdated, setIsUpdated] = useState('');
  const [dueDateError, setDueDateError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [open, setOpen] = React.useState(props.open);
  const dispatch = useDispatch();
  const company_id = localStorage_getItem('company_id');
  const [assign_ticket_type, setAssignTicketType] = React.useState([]);
  const [user_id, setAssignToUser] = React.useState([]);
  const [description, setDescription] = React.useState('');

  const [userData, setUserData] = useState([]);
  const [userDataArr, setUserDataArr] = useState([]);

  const [ticket_names, setInverterNames] = React.useState(props.ticketDataName);
  const [ticket_ids, setInverterIds] = React.useState(props.ticketDataIds);

  //const [comments, setComments] = useState('');

  let assign_ticket = assign_ticket_type.toString();
  //let assign_to = user_id.toString();

  const [due_date, setSelectedDate] = React.useState('');
  const [open1, setOpen1] = useState(false);
  const [ticketAssignDetail, setTicketAssignDetail] = useState({});

  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };

  const changeDescription = e => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    console.log(' inside useeffect ');
    dispatch(AuhMethods['jwtUserManagement'].userListAccessWise(setUserData, '', company_id));
  }, []);

  useEffect(() => {
    console.log('userData ->', userData);
    var users = [];
    if (userData.length > 0) {
      userData.forEach(element => {
        var user = { id: element.id, value: element.user_name };
        users.push(user);
      });
      console.log(' users ->', users);
      setUserDataArr(users);
    }
  }, [userData]);

  useEffect(() => {
    if (currentTicket) {
      if (props.action === 'assign-ticket') {
        if (currentTicket && currentTicket.status === 'success') {
          //console.log(' currentTicket ->',currentTicket,' props.setdata ->',props.setdata,' ticketAssignDetail ->',ticketAssignDetail)
          props.setdata(prev => {
            //console.log(' prev ->',prev);
            let p = [];
            prev.map(item => {
              let assignedTicketIdsArray = ticketAssignDetail.ticket_ids ? ticketAssignDetail.ticket_ids.split(',') : [];
              //console.log(' assignedTicketIdsArray ->',assignedTicketIdsArray,' condition ->',assignedTicketIdsArray.indexOf(item.id.toString()) > -1)
              if (assignedTicketIdsArray.indexOf(item.id.toString()) > -1) {
                p.push({
                  ...item,
                  assign_to: ticketAssignDetail.user_id,
                  status: '2', // 2 - Assigned
                });
              } else {
                p.push(item);
              }
            });
            return p;
          });
          props.onCloseDialog(false);
        } else if (currentTicket.data && currentTicket.data.status === 'error') {
          let userObj = JSON.parse(currentTicket.config.data);
          setAssignToUser(userObj.user_id);
          //setSelectedDate(userObj.due_date);
        } else {
          setAssignToUser(currentTicket.user_id);
          //setSelectedDate(currentTicket.due_date);
        }
      }
      // else {
      //   if (currentUser && currentUser.data && currentUser.status === 'success') {
      //     console.log(' currentUser on edit success ->', currentUser, ' isUpdated ->', isUpdated);
      //   } else if (currentUser.data && currentUser.data.status === 'error') {
      //     let userObj = JSON.parse(currentUser.config.data);
      //     setUserName(userObj.user_name);
      //     setEmail(userObj.email);
      //     //setPassword(userObj.password);
      //   } else {
      //     setUserName(currentUser.user_name);
      //     setEmail(currentUser.email);
      //   }
      // }
    }
  }, [currentTicket]);

  const [formData, updateFormData] = React.useState();

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const onSubmitClick = e => {
    e.preventDefault();
    console.log(formData);
    if (!user_id) {
      setUserNameError(requiredMessage);
    } else if (!due_date) {
      setDueDateError(requiredMessage);
    } else {
      onTicketAssign();
    }
  };

  const handleDateChange = date => {
    console.log(' e.target.value ->', moment(date).format('YYYY-MM-DD'));
    setSelectedDate(moment(date).format('YYYY-MM-DD'));
    setDueDateError('');
  };

  const onTicketAssign = () => {
    const ticketAssignDetail = {
      ticket_ids,
      user_id,
      due_date,
      //comments,
    };
    console.log(' ticketAssignDetail ->', ticketAssignDetail);
    setTicketAssignDetail(ticketAssignDetail);
    dispatch(AuhMethods['jwtTicketManagement'].assignTicketToUser(ticketAssignDetail, setUpdateTicket));
  };

  return (
    <Box>
      <Dialog open={open}>
        <form>
          <DialogContent style={{ width: '400px' }}>
            <DialogTitle className={classes.dialogTitleRoot}>
              {props.action === 'edit' ? 'Edit User' : 'Assign Ticket'}
            </DialogTitle>
            <IconButton size="small" style={{ position: 'absolute', right: '7.93%', top: '8.93%' }}>
              <CloseIcon
                onClick={() => {
                  props.onCloseDialog(false);
                }}
              />
            </IconButton>
          </DialogContent>
          <DialogContent>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center" mb={{ xs: 6, md: 5 }}>
              <GridContainer>
                <Grid item xs={12} sm={12} md={12}>
                  {/* <InputLabel className={classes.InputLabelText}>Ticket Ids</InputLabel> */}
                  {/* <MultiSelectDropdown
                    //setSelected={setAssignTicketType}
                    selected={props.ticketData}
                    dropdown={props.ticketData}
                  /> */}
                  <TextField style={{ width: '100%' }} value={ticket_names} variant="outlined" />
                </Grid>
              </GridContainer>
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Assign to</InputLabel>
              {console.log(' userDataArr 1 ->', userDataArr)}
              {/* <MultiSelectDropdown setSelected={setAssignToUser} selected={users} dropdown={userDataArr} helperText={userNameError} multiple={false} /> */}
              <AppSelectBox
                name="user_id"
                fullWidth
                data={userDataArr}
                valueKey="id"
                defaultValue=""
                variant="outlined"
                labelKey="value"
                value={user_id}
                placeholder={'Select from the list'}
                onChange={e => {
                  setAssignToUser(e.target.value);
                  setUserNameError('');
                }}
                helperText={userNameError}
                style={{ lineHeight: '34px' }}
              />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Due Date</InputLabel>
              {/* <TextField
                type="date"
                id="date"
                style={{ width: '100%' }}
                placeholder="Select a date"
                value={due_date}
                onChange={e => {
                  setSelectedDate(e.target.value);
                  setDueDateError('');
                }}
                variant="outlined"
                helperText={dueDateError}
              /> */}
              <AppDatePicker
                placeholder="Select a date "
                helperText={dueDateError}
                fullWidth={true}
                // variant="inline"
                format="DD/MM/YYYY"
                size={'small'}
                style={{
                  border: '1px solid #D1DEEB',
                  minHeight: '55px',
                  paddingLeft: 10,
                  paddingTop: 10,
                }}
                value={due_date}
                //  onChange={date => {
                //   //setSelectedDate(e.target.value);
                //   setDueDateError('');
                //   console.log(' e.target.value ->',moment(date).format('DD-MM-YYYY'))
                // }}
                onChange={handleDateChange}
                className="form-control"
                autoOk={true}
                minDate={new Date()}
                disable={true}
                // inputVariant={'outlined'}
                variant={'dialog'}
                // TextFieldComponent={(props) => (
                //   <TextField {...props} onClick={(e) => setOpen1(true)} />
                // )}
                //{...other}
              />
            </Box>
            {/* <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Comments</InputLabel>
              <TextField
                style={{ width: '100%' }}
                value={description}
                onChange={changeDescription}
                placeholder="Add your comments"
                multiline
                variant="outlined"
              />
            </Box> */}
          </DialogContent>
          <DialogContent>
            <Box mb={{ xs: 0, md: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
                style={{ width: '100%', background: '#056C45', color: '#FFFFFF' }}>
                {props.action === 'edit' ? 'Update' : 'Assign'}
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Box>
  );
};

export default AssignTicket;

AssignTicket.prototype = {
  open: PropTypes.bool.isRequired,
  //onCloseDialog: PropTypes.func,
  onCloseDialog: PropTypes.bool.isRequired,
};

import React, { useEffect, useState } from 'react';
import BiaxialBarChartApexChart from './BiaxialBarChartApexChart';
import StackedBarGraphApexChart from './StackedBarGraph';
import { Card, Grid } from '@material-ui/core';
import { Box } from '@mui/system';
import axios from '../../services/auth/config';
import ApexCharts from 'apexcharts';

function ReplacementAndSaving({ decryptedData, displayStyle, showStyle}) {
  let [greeningAcheivedGraph, setGreeningAcheived] = useState([]);
  let [biaxialBarChartData, setBiaxialBarChartData] = useState({});
  const [topMargin, setTopMargin] = useState('40px');

  useEffect(() => {
    if(showStyle == 'block') {
      setTopMargin('0px');
    }
    axios.get('/outputform/getgraphdata/' + decryptedData.uid).then(({ data }) => {
      if (data.status == true) {
        let InputUserData = data.data;
        if (InputUserData.length != 0) {
          let greeningAcheived = InputUserData.greening_acheived[0];
          let savingOutput = InputUserData.savings[0];
          let carbonSavedOutput = InputUserData.carbonSaved[0];
          greeningAcheived['Solar'] =
            parseInt(greeningAcheived['Solar']) > parseInt(greeningAcheived['Existing'])
              ? parseInt(greeningAcheived['Solar']) - parseInt(greeningAcheived['Existing'])
              : parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Solar']);

          greeningAcheived['Wind'] =
            parseInt(greeningAcheived['Wind']) > parseInt(greeningAcheived['Existing'])
              ? parseInt(greeningAcheived['Wind']) - parseInt(greeningAcheived['Existing'])
              : parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Wind']);

          greeningAcheived['Hybrid Low CUF'] =
            parseInt(greeningAcheived['Hybrid Low CUF']) > parseInt(greeningAcheived['Existing'])
              ? parseInt(greeningAcheived['Hybrid Low CUF']) - parseInt(greeningAcheived['Existing'])
              : parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Hybrid Low CUF']);

          greeningAcheived['Hybrid High CUF'] =
            parseInt(greeningAcheived['Hybrid High CUF']) > parseInt(greeningAcheived['Existing'])
              ? parseInt(greeningAcheived['Hybrid High CUF']) - parseInt(greeningAcheived['Existing'])
              : parseInt(greeningAcheived['Existing']) - parseInt(greeningAcheived['Hybrid High CUF']);
          setGreeningAcheived({
            series: [
              {
                name: 'Existing',
                data: [
                  greeningAcheived['Existing'],
                  greeningAcheived['Existing'],
                  greeningAcheived['Existing'],
                  greeningAcheived['Existing'],
                ],
              },
              {
                name: 'Calculated',
                data: [
                  greeningAcheived['Solar'],
                  greeningAcheived['Wind'],
                  greeningAcheived['Hybrid Low CUF'],
                  greeningAcheived['Hybrid High CUF'],
                ],
              },
            ],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                id: 'green_acheived',
                events: {
                  animationEnd: function(chartContext, config) {
                    getDataUri('green_acheived');
                  }
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    total: {
                      enabled: true,
                      offsetX: 0,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900,
                      },
                    },
                    formatter: function(val) {
                      return val + '%';
                    },
                  },
                },
              },
              dataLabels: {
                formatter: function(val) {
                  return val + '%';
                },
              },
              stroke: {
                width: 1,
                colors: ['#fff'],
              },
              title: {
                text: 'Greening Achieved(%)',
                align: 'center',
              },
              xaxis: {
                categories: ['Solar', 'Wind', 'Hybrid Low CUF', 'Hybrid High CUF'],
              },
              yaxis: {
                title: {
                  text: undefined,
                },
              },
              tooltip: {
                y: {
                  formatter: function(val) {
                    return val + '%';
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                markers: {
                  radius: 12,
                },
              },
            },
          });

          setBiaxialBarChartData({
            series: [
              {
                name: 'Savings(Rs Cr)',
                type: 'column',
                data: [
                  savingOutput['Solar'],
                  savingOutput['Wind'],
                  savingOutput['Hybrid Low CUF'],
                  savingOutput['Hybrid High CUF'],
                ],
              },
              {
                name: 'Carbon Saved(Tonnes/Y)',
                type: 'line',
                data: [
                  carbonSavedOutput['Solar'],
                  carbonSavedOutput['Wind'],
                  carbonSavedOutput['Hybrid Low CUF'],
                  carbonSavedOutput['Hybrid High CUF'],
                ],
              },
            ],
            options: {
              plotOptions: {
                bar: {
                  dataLabels: {
                    position: 'bottom',
                  },
                  columnWidth: '50%',
                  barHeight: '90%',
                },
              },
              colors: ['#008FFB', '#ED7D31'],
              chart: {
                height: 550,
                type: 'line',
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                id: 'biaxial_bar',
                events: {
                  animationEnd: function(chartContext, config) {
                    getDataUri('biaxial_bar');
                  }
                }
              },
              stroke: {
                width: [0, 4],
              },
              title: {
                text: 'Economic & Carbon Savings',
                align: 'center',
              },
              dataLabels: {
                enabled: true,
                formatter: function(value) {
                  return value.toLocaleString('en-US');
                },
              },
              labels: ['Solar', 'Wind', ['Hybrid Low', 'CUF'], ['Hybrid High', 'CUF']],
              xaxis: {
                type: 'string',
              },
              yaxis: [
                {
                  title: {
                    text: 'Savings',
                  },
                  style: {
                    fontWeight: '500',
                  },
                  labels: {
                    formatter: function(value) {
                      return value.toLocaleString('en-US');
                    },
                  },
                },
                {
                  opposite: true,
                  title: {
                    text: 'Carbon Saved',
                    rotate: 90,
                  },
                  labels: {
                    formatter: function(value) {
                      return value.toLocaleString('en-US');
                    },
                  },
                },
              ],
            },
          });
        }
      }
    });

  }, []);

  const getDataUri = async (chartId) => {
    return await ApexCharts.exec(chartId, "dataURI").then(({ imgURI }) => {
      if(chartId == 'green_acheived') {
        localStorage.removeItem('green_acheived_bar');
        localStorage.setItem('green_acheived_bar',imgURI)
      }
      if(chartId == 'biaxial_bar') {
        localStorage.removeItem('biaxial_bar');
        localStorage.setItem('biaxial_bar',imgURI)
      }
      displayStyle();
      return imgURI;
    });
  }
  return (
    <Box style={{ marginTop: topMargin }}>
      <Grid container spacing={2}>
        {Object.keys(biaxialBarChartData).length != 0 ? (
          <Grid item xs={6}>
            <BiaxialBarChartApexChart data={biaxialBarChartData} />
          </Grid>
        ) : (
          <Grid item xs={6}></Grid>
        )}
        {Object.keys(greeningAcheivedGraph).length != 0 ? (
          <Grid item xs={6}>
            <StackedBarGraphApexChart data={greeningAcheivedGraph} />
          </Grid>
        ) : (
          <Grid item xs={6}></Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ReplacementAndSaving;

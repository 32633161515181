import React, { useEffect, useState } from 'react';

const Company = (props) => {
    const [activeClassName, setActiveClassName] = useState();
    const [form, setForm] = useState({});
    const [sectorToggle, setSectorToggle] = useState(false);
    const [plantTypeToggle, setPlantTypeToggle] = useState(false);
    const [stateToggle, setStateToggle] = useState(false);
    const [getSectors, setSectors] = React.useState([]);
    const [getPlantType, setPlantType] = React.useState([]);
    const [getPlantLocations, setPlantLocations] = React.useState([]);
    const [getSelectedSectors, setSelectedSector] = React.useState('');
    const [getSelectedPlantType, setSelectedPlantType] = React.useState('');
    const [geSelectedtPlantLocations, setSelectedPlantLocations] = React.useState('');
    let property = props;

    useEffect(() => {
        setActiveClassName('form-section '+ props.active)
        setSectors(props.apiData.sectors);
        setPlantType(props.apiData.plant_types);
        setPlantLocations(props.apiData.plant_location);
    }, [property.active]);

    const setField = (field, value) => {
      setForm({
        ...form,
        [field]: value,
      });
      props.handleData(field,value);
    };

    const handleDropDown = (field,value,text) =>{
        if(field == 'sector') {
            setSelectedSector(text);
        }else if(field == 'plant_type') {
            setSelectedPlantType(text);
        }else if(field == 'plant_location') {
            setSelectedPlantLocations(text);
            getPlantLocations.map((type, index) => {
                if (type.id == value) {
                  setField('plant_location_type', type.type)
                }
            });
        }
        setSectorToggle(false);
        setPlantTypeToggle(false);
        setStateToggle(false);
        setField(field,value);
    }

    return (
        <div className={activeClassName}>
        <h2 className="form_section_head">Company Details</h2>
        <div className="form_wrapper_section_grid">
            <div className="form_wrapper_section_col" id="form_renew">
            <div className="label_lg">
                <img src={"../../imagesNew/calc_form_ico1.svg"} />
                Company Name
            </div>
            <label htmlFor="firstname">ENTER THE NAME OF THE COMPANY</label>
            <input type="text" id="name_field" placeholder="Company Name"
                className="form-control" 
                value = {!!props.data.company_name ? props.data.company_name : form.company_name}
                onChange= {e => setField('company_name',e.target.value)}
            />
            <span className="errormessage">{props.error.company_name}</span>
            </div>
            <div className="form_wrapper_section_col">
            <div className="label_lg">
                <img src={"../../imagesNew/calc_form_ico2.svg"} />
                Sectors
            </div>
            <label htmlFor="firstname">SELECT THE SECTOR APLLICABLE</label>

            <div className="select-dropdown">
                <button role="button" data-value="" className="select-dropdown__button"
                 onClick={(e) => {e.preventDefault(); setSectorToggle(!sectorToggle)}} >
                    <span className={!!getSelectedSectors? 'place_custom place_custom_dark': 'place_custom'}>{!!getSelectedSectors? getSelectedSectors: 'Select Sector'}</span> <i className="zmdi zmdi-chevron-down"></i>
                    <div className="drop_cust_chev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.333" height="5.545" viewBox="0 0 10.333 5.545">
                        <path
                        id="Polygon_44"
                        data-name="Polygon 44"
                        d="M5.167,0l5.167,5.545H0Z"
                        transform="translate(10.333 5.545) rotate(180)"
                        fill="#707070"
                        />
                    </svg>
                    </div>
                </button>
                {sectorToggle&&(<ul className="select-dropdown__list active"
                onClick={(e) => handleDropDown('sector',e.target.value, e.target.id)}>
                {getSectors.map((type, index) => {
                    return <li value={type.id} id={type.name} className={type.name == getSelectedSectors? "select-dropdown__list-item active": "select-dropdown__list-item"}>
                        {type.name}
                    </li>;
                })}
                </ul>)}
            </div>
            <span className="errormessage">{props.error.sector}</span>
            </div>
            <div className="form_wrapper_section_col">
            <div className="label_lg">
                <img src={"../../imagesNew/calc_form_ico3.svg"} />
                Type
            </div>
            <label htmlFor="firstname">SELECT THE TYPE OF INDUSTRY APPLICABLE</label>
            <div className="select-dropdown2">
                <button role="button" data-value="" className="select-dropdown__button2"
                 onClick={(e) => {e.preventDefault(); setPlantTypeToggle(!plantTypeToggle)}}>
                    <span className={!!getSelectedPlantType? 'place_custom2 place_custom_dark2': 'place_custom2'}>{!!getSelectedPlantType? getSelectedPlantType: 'Select Type Of Industry'}</span> <i className="zmdi zmdi-chevron-down"></i>
                    <div className="drop_cust_chev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.333" height="5.545" viewBox="0 0 10.333 5.545">
                        <path
                        id="Polygon_44"
                        data-name="Polygon 44"
                        d="M5.167,0l5.167,5.545H0Z"
                        transform="translate(10.333 5.545) rotate(180)"
                        fill="#707070"
                        />
                    </svg>
                    </div>
                </button>
                {plantTypeToggle&&(<ul className="select-dropdown__list2 active"
                 onClick={(e) => handleDropDown('plant_type',e.target.value, e.target.id)}>
                {getPlantType.map((type, index) => {
                    return <li value={type.id} id={type.name} className={type.name == getSelectedPlantType? "select-dropdown__list-item2 active": "select-dropdown__list-item2"}>
                        {type.name}
                    </li>;
                })}
                </ul>)}

            </div>
            <span className="errormessage">{props.error.plant_type}</span>
            </div>

            <div className="form_wrapper_section_col">
            <div className="label_lg">
                <img src={"../../imagesNew/calc_form_ico4.svg"} />
                State
            </div>
            <label htmlFor="firstname">SELECT THE LOCATION OF PLANT APPLICABLE</label>
            <div className="select-dropdown3">
                <button role="button" data-value="" className="select-dropdown__button3"
                onClick={(e) => {e.preventDefault(); setStateToggle(!stateToggle)}}>
                    <span className={!!geSelectedtPlantLocations? 'place_custom2 place_custom_dark2': 'place_custom3'}>{!!geSelectedtPlantLocations ? geSelectedtPlantLocations : 'Select Location'} </span> <i className="zmdi zmdi-chevron-down"></i>
                    <div className="drop_cust_chev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.333" height="5.545" viewBox="0 0 10.333 5.545">
                        <path
                        id="Polygon_44"
                        data-name="Polygon 44"
                        d="M5.167,0l5.167,5.545H0Z"
                        transform="translate(10.333 5.545) rotate(180)"
                        fill="#707070"
                        />
                    </svg>
                    </div>
                </button>
                {stateToggle&&(<ul className="select-dropdown__list active"
                onClick={(e) => {handleDropDown('plant_location', e.target.value, e.target.id)}}>
                {getPlantLocations.map((type, index) => {
                    return <li value={type.id} id={type.name} className={type.name == geSelectedtPlantLocations? "select-dropdown__list-item3 active": "select-dropdown__list-item3"}>
                        {type.name}
                    </li>;
                })}
                </ul>)}
            </div>
            <span className="errormessage">{props.error.plant_location}</span>
            </div>
        </div>
        </div>
    );
};


export default Company;
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import AppTextInput from '../../@jumbo/components/Common/formElements/AppTextInput';
import { AuhMethods } from '../../services/auth/index';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import MultiSelectDropdown from '../../@custom/UI/MultiSelectDropdown';
import { requiredMessage } from '../../@jumbo/constants/ErrorMessages';
import AppSelectBox from '../../@jumbo/components/Common/formElements/AppSelectBox';

const useStyles = makeStyles(theme => ({
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: '#505F79',
    },
    paddingLeft: '0px !important',
  },

  dialogSubTitleRoot: {
    fontSize: 12,
    color: '#505F79',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    //lineHeight:16,
    fontWeight: 500,
  },

  InputLabelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
    width: '130px',
    height: 15,
    lineHeight: '17px',
    paddingBottom: '10px',
  },
}));

const verticalDropdown = [
  { label: 'Solar', value: 'Solar' },
  { label: 'Wind', value: 'Wind' },
  { label: 'Hydro', value: 'Hydro' }, // multiselect component only has option.value but no label in it
];

const levelArray = [
  { title: 'Plant', value: 'plant' },
  { title: 'Asset', value: 'asset' },
];

const AddTag = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  let method = 'OnBoardingManagement';
  const classes = useStyles();

  const [open, setOpen] = useState(props.open);
  const [tagName, setTagName] = useState('');
  const [description, setDescription] = useState('');
  const [level, setLevel] = useState('');
  const [selectedVertical, setSelectedVertical] = useState([]); //This is for type
  const [group_id, setgroup_id] = useState(props.groupId); //This is for TAG ID
  const [currentGroup, setUpdateGroup] = useState('');
  const [objectPath, setObjectPath] = useState([]);
  const [nameError, setNameError] = useState('');
  const [verticalError, setVerticalError] = useState('');
  const [isUpdated, setIsUpdated] = useState(0);

  //get current group data on initial load in edit view
  useEffect(() => {
    if (props.action === 'edit') {
      dispatch(AuhMethods[method].getOptimaxTag(group_id, setUpdateGroup, setIsUpdated));
    }
  }, []);

  // initailizing value of all the field
  useEffect(() => {
    if (currentGroup && props.action === 'edit') {
      if (currentGroup && currentGroup.data && currentGroup.status === 'success') {
        console.log(' currentGroup ->', currentGroup);
        setgroup_id(currentGroup.data.id);
        setTagName(currentGroup.data.alias); //input: alias but api ko name field milega
        setDescription(currentGroup.data.description);
        setLevel(currentGroup.data.level ? currentGroup.data.level : '');
        setSelectedVertical(
          !!currentGroup.data.type ? currentGroup.data.type.split(',').map(w => w.charAt(0).toUpperCase() + w.slice(1)) : [],
        ); //either capitalise each word or ask jyoti to do it
        setObjectPath(currentGroup.data.object_path);
      }
    }
  }, [currentGroup]);

  // on submit click
  const onSubmitClick = e => {
    e.preventDefault();
    let isValid = true;
    if (!tagName) {
      setNameError(requiredMessage);
      isValid = false;
    }
    if (selectedVertical.length === 0) {
      setVerticalError(requiredMessage);
      isValid = false;
    }
    if (isValid) {
      onGroupSave();
    }
  };

  const onGroupSave = () => {
    const groupDetail = {
      name: tagName,
      description: description,
      level: level,
      type: selectedVertical.toString().toLowerCase(),
    };
    // console.log(groupDetail);
    // props.onCloseDialog(false); // This is an outside effect and therefore should be inside useEffect to prevent mem leaks
    if (props.action === 'edit') {
      dispatch(AuhMethods[method].updateOptimaxTag(group_id, groupDetail, setIsUpdated));
    } else {
      dispatch(AuhMethods[method].addNewOptimaxTag(groupDetail, setIsUpdated, setgroup_id));
    }
    // props.callbackFunc();
  }; //IMP

  useEffect(() => {
    if (isUpdated === 1) {
      if (props.action === 'edit') {
        props.setdata(prev => {
          let p = [];
          prev.forEach(item => {
            if (item.id === group_id) {
              p.push({
                ...item,
                name: tagName.split(' ').join('_'),
                alias: tagName,
                type: selectedVertical.toString(),
                level: level,
                description: description,
                object_path: objectPath.toLowerCase(),
              });
            } else {
              p.push(item);
            }
          });
          return p;
        });
      } else {
        props.setdata(prev => {
          let p = [...prev];
          p.push({
            id: group_id, //imp otherwise on edit of new tag it will get undefined without id
            name: tagName.split(' ').join('_'),
            alias: tagName,
            type: selectedVertical.toString(),
            level: level,
            description: description,
            createdAt: new Date(),
            updatedAt: new Date(), //maybe dont insert these 2
            object_path: objectPath.toLowerCase(),
          });
          return p;
        });
      }
      props.onCloseDialog(false);
    }
    // props.callbackFunc(); //update table first and callback here because it causes outside effect
  }, [isUpdated]);

  return (
    <Box>
      <Dialog open={open}>
        <form>
          <DialogContent style={{ width: '311px' }}>
            <DialogTitle className={classes.dialogTitleRoot}>
              {props.action === 'edit' ? 'Edit Tag ' : 'Add New Tag'}
            </DialogTitle>
            <IconButton
              size="small"
              style={{ position: 'absolute', right: '7.93%', top: '6.93%' }}
              onClick={() => {
                props.onCloseDialog(false);
              }}>
              <CloseIcon />
            </IconButton>
          </DialogContent>

          <DialogContent>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Tag Name *</InputLabel>
              <AppTextInput
                type="text"
                required
                name="group_name"
                fullWidth
                variant="outlined"
                placeholder="Tag Name"
                value={tagName}
                onChange={e => {
                  setTagName(e.target.value);
                  setObjectPath(`optimax.${e.target.value.replace(/\s/g, '')}`);
                  setNameError('');
                }}
                helperText={nameError}
              />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Description</InputLabel>
              <TextField
                style={{ width: '100%' }}
                value={description}
                onChange={e => {
                  setDescription(e.target.value);
                }}
                placeholder="Description of the Tag"
                multiline
                variant="outlined"
              />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Level</InputLabel>
              <AppSelectBox
                fullWidth
                data={levelArray}
                valueKey="value"
                name="level"
                variant="outlined"
                labelKey="title"
                value={level}
                onClick={e => setLevel(e.target.value)}
              />
            </Box>
            <Box mb={{ xs: 6, md: 5 }}>
              <InputLabel className={classes.InputLabelText}>Type *</InputLabel>
              <MultiSelectDropdown
                setSelected={setSelectedVertical}
                selected={selectedVertical}
                dropdown={verticalDropdown}
                error={verticalError}
                onChangeFun={() => {
                  // setSelectedState([]);
                  console.log(selectedVertical);
                  // setSelectedSite([]);
                }}
              />
            </Box>
          </DialogContent>

          <DialogContent>
            <Box mb={{ xs: 0, md: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
                style={{ width: '100%', background: '#056C45', color: '#FFFFFF' }}>
                {props.action === 'edit' ? 'Update' : 'Submit'}
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Box>
  );
});

export default AddTag;

AddTag.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.bool.isRequired,
};

import React, { useState, useEffect } from 'react';
import Windmil from '../SideBar/windmil';
import SideBar from '../SideBar/sideBar';
import Main from './Main';
const MainContent = (props) => {
  const[getClssName, setClssName] = useState("grid-row");
  const[getStepCompleted, setStepCompleted] = useState(0);
  useEffect(()=> {
    if(props.getDisplay == 'none') {
      setClssName("");
    }
  },[props.getDisplay]);

  const handleStepComplition = (val) => {
    setStepCompleted(val);
  }
  return (
    <section className="calc_main_wrapper">
        <Windmil getDisplay={props.getDisplay}></Windmil>
      <div className="container-fluid">
        <div className={getClssName}>
            <SideBar getDisplay={props.getDisplay} stepCompleted={getStepCompleted}></SideBar>
            <Main display={props.display} handleStepComplition={handleStepComplition}></Main>
        </div>
      </div>
    </section>
  );
};

export default MainContent;

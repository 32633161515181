import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { darken, Hidden, Toolbar, Button } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from '../../services/auth/config';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

theme.typography.body2 = {
  fontSize: '0.7rem',
};
theme.typography.h6 = {
  fontSize: '0.875rem',
};

const OutputForm = ({ decryptedData }) => {
  let [rows, setRows] = useState([]);
  useEffect(() => {
    axios.post('formdata/fetch', { user_id: decryptedData.uid }).then(({ data }) => {
      if (data.status == true) {
        let InputUserData = data.data;
        InputUserData = InputUserData[0];
        let network = InputUserData.network;
        let state = InputUserData.injection_state;
        let injection_level = 'STU';
        if (network == 'Inter-state') {
          state = 'Maharashtra';
          injection_level = 'CTU';
        }
        setRows([
          createData(
            'Annual Energy Consumption',
            InputUserData.annual_energy_consumption,
            'Plant Location',
            InputUserData.plant_location,
          ),
          createData('Average Cost of Power procurement', InputUserData.power_purchase_cost, 'Injection State', state),
          createData('Connected at', InputUserData.connectivity_level, 'Injection Network', InputUserData.network),
          createData('Connectivity Voltage Level', InputUserData.voltage_level, 'Injection Level', injection_level),
          createData('Desired Replacement Quantum (in MU)', InputUserData.desired_replaced_quantum_mu, '-', '-'),
          createData('Desired Replacement Quantum (in MW)', InputUserData.desired_replaced_quantum_mw, '-', '-'),
          createData('Open Acess Mode', InputUserData.oa_mode, '-', '-'),
        ]);
      }
    });
  }, []);

  function createData(tname, tDetails, ntname, ntDetails) {
    return {
      tname,
      tDetails,
      ntname,
      ntDetails,
    };
  }

  let body = rows;
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      // backgroundColor: '#F3FDE8',
      backgroundColor: '#FFF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#b2df8a',
      padding: 5,
      // backgroundImage: "linear-gradient(greenyellow, navy)",
      fontFamily: 'Lato-Bold',
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontFamily: 'Lato-Regular',
      fontSize: 15,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        sx={{
          boxShadow: 22,
        }}
        component={Paper} style={{marginTop: '30px'}} >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead component="tr">
            <TableRow rowSpan={2} style={{ height: '55px' }}>
              <StyledTableCell
                style={{ backgroundColor: '#98D066', width: '10%' }}
                component="td"
                align="center"
                colSpan={2}>
                <Typography variant="h6" fontWeight="fontWeightMedium">
                Technical
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#98D066',
                  width: '10%',
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                }}
                component="td"
                align="center"
                colSpan={2}>
                <Typography variant="h6" fontWeight="fontWeightMedium">
                  Non-Technical
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow style={{ width: 10, height: '40px' }}>
              <StyledTableCell style={{ backgroundColor: '#98D066', width: '10%' }} component="td" align="center">
                <Typography variant="h6" fontWeight="fontWeightMedium">
                  Input Data
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: '#98D066', width: '10%' }} component="td" align="center">
                <Typography variant="h6" fontWeight="fontWeightMedium">
                  Details
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                style={{
                  backgroundColor: '#98D066',
                  width: '10%',
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                }}
                component="td"
                align="center">
                <Typography variant="h6" fontWeight="fontWeightMedium">
                  Input Data
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ backgroundColor: '#98D066', width: '10%' }} component="td" align="center">
                <Typography variant="h6" fontWeight="fontWeightMedium">
                  Details
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <StyledTableRow style={{ width: '10%',height: '40px' }} key={row.name}>
                <StyledTableCell component="ul" scope="row" align="center" style={{ width: '12%' }}>
                  <Typography variant="body2">{row.tname}</Typography>
                </StyledTableCell>
                <StyledTableCell component="i" scope="row" align="center" style={{ width: '10%' }}>
                  <Typography variant="body2"> {row.tDetails}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  component="ul"
                  scope="row"
                  align="center"
                  style={{
                    width: '10%',
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  }}>
                  <Typography variant="body2">{row.ntname}</Typography>
                </StyledTableCell>
                <StyledTableCell component="i" scope="row" align="center" style={{ width: '10%' }}>
                  <Typography variant="body2"> {row.ntDetails}</Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default OutputForm;

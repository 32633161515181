import React, { useEffect, useState } from 'react';
import { AuhMethods } from '../../services/auth/index';
import GridContainer from '../../@jumbo/components/GridContainer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../@jumbo/components/Common/formElements/AppSelectBox';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { InputLabel, Box, Grid, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { requiredMessage } from '../../@jumbo/constants/ErrorMessages';
import { localStorage_getItem } from '../../@custom/LocalStorage';
import CustomButton from '../../@custom/UI/CustomButton';
import FileUploadProgress from '../../@custom/UI/FileUploadProgress';

const useStyles = makeStyles(theme => ({
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: '#505F79', //theme.palette.common.dark,
    },
  },

  InputLabelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
    width: 'auto',
    height: 15,
    lineHeight: '17px',
    paddingBottom: '10px',
  },

  InputLabelTextDesc: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
    width: '135px',
    height: 15,
    lineHeight: '17px',
    paddingBottom: '10px',
  },
}));

const AddDataUpload = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let method = 'jwtDataUploadManagement';

  const [isUpdated, setIsUpdated] = useState('');
  let formDataDateUpload = new FormData();

  const [businessTypeError, setBusinessTypeError] = useState('');
  const [siteNameError, setSiteNameError] = useState('');
  const [modelTypeError, setModelTypeError] = useState('');
  const [fileTypeError, setFileTypeError] = useState('');
  const [fileNameError, setFileNameError] = useState('');

  const [open1, setOpen] = React.useState(props.open);

  const [businessTypeArray, setBusinessTypeArray] = useState([
    { title: 'Solar', slug: 'solar' },
    { title: 'Wind', slug: 'wind' },
    { title: 'Hydro', slug: 'hydro' },
  ]);
  const [businessType, setBusinessType] = useState('solar');

  const [modelTypeArray, setModelTypeArray] = useState([{ title: 'CBMC', slug: 'cbmc' }]);
  const [modelType, setModelType] = useState('cbmc');

  const [fileTypeArray, setFileTypeArray] = useState([
    { title: 'DGR', slug: 'dgr' },
    { title: 'STATIC', slug: 'static' },
  ]);
  const [fileType, setFileType] = useState('dgr');

  const [fileName, setFileName] = useState('');

  let isSampleTemplate =
    fileType === 'dgr' ? '/documents/data-upload/dgr-sample-data.csv' : '/documents/data-upload/static-sample-data.csv';

  const [sampleTemplate, setSampleTemplate] = useState(isSampleTemplate);

  /* Start Added Dynamic site and state data */
  var access_list_local = localStorage_getItem('access_list');
  var siteArr = [];
  var sitesSelected = [];
  let site_nameToID = {};
  if (access_list_local.user_site.length > 0) {
    access_list_local.user_site.forEach(element => {
      let objVal = { slug: element.site_id, title: element.site_name };
      siteArr.push(objVal);
      sitesSelected.push(element.site_name);
      site_nameToID[element.site_name] = element.site_id;
    });
  } else {
    site_nameToID = [];
  }
  /* End */

  const [siteNameArray, setSiteNameArray] = useState(siteArr);
  const [siteName, setSiteName] = useState(site_nameToID[sitesSelected]);
  const [dataId, setDataId] = useState('');
  // const fileReader = new FileReader();
  // const [file, setFile] = useState('');
  // const [to_redirect, setto_redirect] = useState(false);
  // const [isClosed, setIsClosed] = useState(false);
  // const [is_removed, setIsRemoved] = useState(true);
  // const [array, setArray] = useState([]);

  const onSubmitClick = e => {
    e.preventDefault();
    formDataDateUpload = new FormData();
    let isValid = true;
    if (!businessType) {
      setBusinessTypeError(requiredMessage);
      isValid = false;
    }
    if (!siteName) {
      setSiteNameError(requiredMessage);
      isValid = false;
    }
    if (!modelType) {
      setModelTypeError(requiredMessage);
      isValid = false;
    }
    if (!fileType) {
      setFileTypeError(requiredMessage);
      isValid = false;
    }
    if (!fileName) {
      setFileNameError(requiredMessage);
      isValid = false;
    }
    console.log(
      '  businessType ->',
      businessType,
      ' & siteName ->',
      siteName,
      ' & modelType ->',
      modelType,
      ' & fileType ->',
      fileType,
      ' & fileName ->',
      fileName,
    );
    console.log(' isValid ->', isValid);

    formDataDateUpload.append('user_id', localStorage_getItem('user_id'));
    formDataDateUpload.append('b_type', businessType);
    formDataDateUpload.append('model_type', modelType);
    formDataDateUpload.append('type', fileType);
    formDataDateUpload.append('site_name', siteName);
    formDataDateUpload.append('file_upload', fileName);

    if (isValid) {
      setFileNameError('');
      onUserSave();
    }
  };

  useEffect(() => {
    if (isUpdated === 1) {
      props.setdata(prev => {
        let p = [...prev];
        p.push({
          filename: fileName.name,
          site_name: siteName,
          b_type: businessType,
          model_type: modelType,
          createdAt: new Date(),
          status: 'Pending',
          progress: 0,
          user_id: localStorage_getItem('user_id'),
          attachment: fileName.preview,
          id: dataId,
        });
        return p;
      });
      props.onCloseDialog(false);
    }
  }, [isUpdated]);

  const onUserSave = () => {
    dispatch(AuhMethods[method].addNewDataUpload(formDataDateUpload, setDataId, setIsUpdated));
  };

  const getCsvFiles = files => {
    if (files.length !== 0) {
      const inputFile = files[0];
      setFileName(inputFile);
      //setFile(inputFile);
      // fileReader.onload = function(event) {
      //   const text = event.target.result;
      //   // console.log('texttexttext',text);
      //   if (!isClosed) {
      //     csvFileToArray(text);
      //   }
      // };
      // fileReader.readAsText(inputFile);
      //setIsRemoved(true);
    } else {
      //setIsRemoved(false);
      setFileName('');
    }
  };

  /*const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');
    for (let i = 0; i < csvHeader.length; ++i) {
      csvHeader[i] = csvHeader[i].replace(/(\r\n|\n|\r)/gm, '');
    }
    for (let i = 0; i < csvRows.length; ++i) {
      csvRows[i] = csvRows[i].replace(/(\r\n|\n|\r)/gm, '');
    }
    const array_rows = csvRows.map(i => {
      const values = i.split(',');

      const values1 = ['Albert Flores 5', 'Darlene Rober. LA 5', 'Testing 5'];

      const obj = csvHeader.reduce((object, header, index) => {
        // console.log('indexindex',index);
        if (csvHeader.length - 1 === index) {
          // console.log('Last item');
          let lastIndex = csvHeader.length - 1;
          let lastValueFrom = values[lastIndex];
          // console.log('values[index]',lastValueFrom);
        }
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    // console.log("input", string);
    // console.log("final", array_rows);
    setArray(array_rows);
  };*/

  return (
    <Box>
      <Dialog open={open1}>
        <form>
          <DialogTitle className={classes.dialogTitleRoot}>{'Add Data Upload'}</DialogTitle>
          <IconButton
            size="small"
            style={{ position: 'absolute', right: '3.93%', top: '1.93%' }}
            onClick={() => {
              props.onCloseDialog(false);
            }}>
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <GridContainer style={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={12}>
                <InputLabel className={classes.InputLabelText}>Business Type *</InputLabel>
                <AppSelectBox
                  fullWidth
                  data={businessTypeArray}
                  valueKey="slug"
                  name="business_type"
                  variant="outlined"
                  labelKey="title"
                  value={businessType}
                  error={businessTypeError}
                  onChange={e => {
                    setBusinessType(e.target.value);
                    // handleChange(e);
                    setBusinessTypeError('');
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className={classes.InputLabelText}>Model Type *</InputLabel>
                <AppSelectBox
                  fullWidth
                  data={modelTypeArray}
                  valueKey="slug"
                  name="model_type"
                  variant="outlined"
                  labelKey="title"
                  value={modelType}
                  error={modelTypeError}
                  onChange={e => {
                    setModelType(e.target.value);
                    // handleChange(e);
                    setModelTypeError('');
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel className={classes.InputLabelText}>Select file type to upload *</InputLabel>
                <AppSelectBox
                  fullWidth
                  data={fileTypeArray}
                  valueKey="slug"
                  name="file_type"
                  variant="outlined"
                  labelKey="title"
                  value={fileType}
                  error={fileTypeError}
                  onChange={e => {
                    setFileType(e.target.value);
                    // handleChange(e);
                    setFileTypeError('');
                    let isSampleTemplate =
                      e.target.value === 'dgr'
                        ? '/documents/data-upload/dgr-sample-data.csv'
                        : '/documents/data-upload/static-sample-data.csv';
                    setSampleTemplate(isSampleTemplate);
                  }}
                />
              </Grid>
            </GridContainer>

            <div style={{ width: '100%', marginBottom: '6px' }}>
              <Box item xs={12} sm={12} display="flex" alignItems={'center'}>
                <Box pr={1} flexGrow={1}>
                  <InputLabel className={classes.InputLabelText}>Site Name *</InputLabel>
                  <AppSelectBox
                    fullWidth
                    data={siteNameArray}
                    valueKey="slug"
                    name="site_name"
                    variant="outlined"
                    labelKey="title"
                    value={siteName}
                    error={siteNameError}
                    onChange={e => {
                      setSiteName(e.target.value);
                      // handleChange(e);
                      setSiteNameError('');
                    }}
                  />
                </Box>
                <Box pl={1}>
                  <InputLabel className={classes.InputLabelText}>Check Template</InputLabel>
                  <CustomButton p={1} variant={'outlined'} color={'default'} icon={'getAccountBalanceIcon'}>
                    <a href={sampleTemplate}>Download</a>
                  </CustomButton>
                </Box>
              </Box>
            </div>

            <GridContainer>
              <Grid item xs={12} sm={12}>
                <FileUploadProgress getCsv={getCsvFiles} isDataUpload={true} error={fileNameError} acceptType={'text/csv'} />
              </Grid>
            </GridContainer>
          </DialogContent>

          <DialogContent style={{ textAlign: 'center' }}>
            <Box mb={{ xs: 0, md: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitClick}
                style={{
                  textAlign: 'center',
                  background: '#056C45',
                  color: '#FFFFFF',
                }}>
                {'Submit'}
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Box>
  );
};

export default AddDataUpload;

AddDataUpload.prototype = {
  open: PropTypes.bool.isRequired,
  //onCloseDialog: PropTypes.func,
  onCloseDialog: PropTypes.bool.isRequired,
};

import './style.css';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const CustomizedLabel = ({ x, y, stroke, value }) => (
  <text x={x} y={y} dy={-10} dx={12} fill={stroke} textAnchor="middle">
    {value.toLocaleString('en-US')}
  </text>
);
const BiaxialBarChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data} margin={{ top: 20, right: 10, left: 50, bottom: 0 }}>
      <XAxis dataKey="name"/>
      <YAxis
        yAxisId="left"
        label={{
          value: 'Carbon Saved',
          angle: -90,
          position: 'insideCenter',
          dx: -40,
          fill: '#666666',
        }}
        orientation="left"
        stroke="#ED7D31"
      />
      <YAxis
        yAxisId="right"
        label={{ 
          value: 'Saving', 
          angle: -90, 
          position: 'insideCenter', 
          dx: 20, fill:'#666666',
        }}
        orientation="right"
        stroke="#4472C4"
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip formatter={(value) => value.toLocaleString('en-US')} labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={true} />
      <Legend />
      <defs>
        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#6200EE" stopOpacity={1} />
          <stop offset="95%" stopColor="#B819D2" stopOpacity={1} />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#1ABBDE" stopOpacity={1} />
          <stop offset="95%" stopColor="#09BCA7" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Bar yAxisId="left" dataKey="carbon_saved" fill={'#ED7D31'} name="Carbon Saved(Tonnes/Y)" label={CustomizedLabel} />
      <Bar yAxisId="right" dataKey="saving" fill={'#4472C4'} name="Saving(Rs Cr)"  label={CustomizedLabel} />
      
    </BarChart>
  </ResponsiveContainer>
);

export default BiaxialBarChart;

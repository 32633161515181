import { getFormattedDate } from '../@jumbo/utils/dateHelper';
import { addDays, addMonths, addYears } from 'date-fns';
import { startOfMonth, endOfMonth } from 'date-fns';

export const cardColor = ['Purple', 'Indigo', 'Blue', 'Green', 'Yellow', 'Orange', 'Red'];

export const cardIcon = [
  'People',
  'Person',
  'Flash',
  'Settings',
  'Bell',
  'Bolt',
  'Alarm',
  'Flag',
  'Sun',
  'Bulb',
  'Plug',
  'No Flash',
  'Incandescent',
  'Dollar',
  'Dollar Alternate',
  'Power',
];

export const AnalysisLayoutDesign = {
  'Layout-8': [
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
  ],
  'Layout-6': [
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'none',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'none',
    },
  ],
  'Layout-5': [
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'none',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'none',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'none',
    },
  ],
};

export const MonitorLayoutDesign = {
  'Layout-5': [
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'block',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
    {
      xs: 12,
      sm: 4,
      md: 4,
      display: 'block',
    },
  ],
  'Layout-3': [
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'block',
    },
    {
      xs: 12,
      sm: 12,
      md: 12,
      display: 'none',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'block',
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      display: 'none',
    },
  ],
};

export const chartType = {
  'Actual Power Vs Budgeted Power': 'combination',
  'Actual Power vs Budgeted Generation': 'combination',
  'Average GHI': 'single',
  'PLF Trend %': 'single',
  'PR Trend': 'single',
  'Soiling Loss Trend': 'single',
  'Flagged inverter Overview': 'combination',
  'Mean Inverter Efficiency': 'single',
  'Inverter Flagging Summary: Model wise': 'combination',
  'Inverter efficiency Trend': 'single',
  'Resource availability vs Grid availability vs  Plant availability Trend': 'single',
  'Actual Revenue vs Budgeted Revenue': 'single',
  'Inverter Flagging Summary': 'single',
  'Inverter Derating Loss Trend': 'combination',
  'Curtailment loss Trend': 'single',
  'Soiling Loss Trend Assets': 'single',
  'Wind Speed Direction': 'Polar',
  'Total generation vs CUF vs PR trend': 'combination2',
  'GHI Trend': 'single',
  'GTI Trend': 'single',
  'Soiling loss': 'single',
  'Average Soiling loss': 'single',
  'Rain and cleaning cycle vs soiling loss': 'combination2',
  'Cleaning cost vs soiling loss': 'combination2',
  'WCPR vs cleaning trend': 'combination2',
  '% Model Adherence': 'single',
  '% Data Adherence': 'single',
  'Total Generation': 'single',
  'Active Power': 'single',
  'Mean inverter efficiency-asset wise trend': 'single',
  'Active power-asset wise trend': 'single',
};

export const plotType = {
  single: [
    ['line', 'Line Chart'],
    ['spline', 'Spline Chart'],
    ['bar', 'Horizontal Bar Chart'],
    ['column', 'Column Chart'],
    ['area', 'Area'],
  ],
  combination: [
    ['0', 'Line + Line Chart'],
    ['1', 'Spline + Spline Chart'],
    // ['2', 'Line + Bar Chart'],
    // ['3', 'Bar + Line Chart'],
    ['4', 'Line + Column Chart'],
    ['5', 'Column + Line Chart'],
    // ['6', 'Line + Area Chart'],
    // ['7', 'Spline + Bar Chart'],
    // ['8', 'Bar + Spline Chart'],
    // ['9', 'Area + Spline Chart'],
    // ['10', 'Spline + Area Chart'],
    ['11', 'Horizontal Bar + Horizontal Bar Chart (Without Stacked)'],
    // ['12', 'Bar + Bar Chart (With Stacked)'],
    // ['13', 'Bar + Area Chart'],
    // ['14', 'Area + Bar Chart'],
    // ['15', 'Spline + Column Chart'],
    // ['16', 'Column + Spline Chart'],
    // ['17', 'Area + Line Chart'],
    // ['18', 'Area + Area Chart'],
    // ['19', 'Area + Column Chart'],
    // ['20', 'Column + Area Chart'],
    ['21', 'Column + Column Chart (Without Stacked)'],
    // ['22', 'Column + Column Chart (With Stacked)'],
  ],
  combination2: [
    ['23', 'Column + Column + Line Chart'],
    // ['24', 'Column + Column + Line Chart'],
    // ['25', 'Column + Column + Line Chart'],
    // ['26', 'Column + Column + Line Chart'],
  ],
  Polar: [['column', 'Column Chart']],
};

export const combinationArray = [
  ['line', 'line'],
  ['spline', 'spline'],
  ['line', 'bar'],
  ['bar', 'line'],
  ['line', 'column'],
  ['column', 'line'],
  ['line', 'area'],
  ['spline', 'bar'],
  ['bar', 'spline'],
  ['area', 'spline'],
  ['spline', 'area'],
  ['bar', 'bar'],
  ['bar', 'bar'],
  ['bar', 'area'],
  ['area', 'bar'],
  ['spline', 'column'],
  ['column', 'spline'],
  ['area', 'line'],
  ['area', 'area'],
  ['area', 'column'],
  ['column', 'area'],
  ['column', 'column'],
  ['column', 'column'],
  ['column', 'column', 'line'],
  ['column', 'column', 'line'],
  ['column', 'column', 'line'],
  ['column', 'column', 'line'],
];

export function formatNumber(n) {
  n = parseFloat(n);
  var ranges = [
    { divider: 1e18, suffix: 'P' },
    { divider: 1e15, suffix: 'E' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
    { divider: 1, suffix: '' },
    // { divider: 1e-3, suffix: 'm' },
    // { divider: 1e-6, suffix: 'µ' },
    // { divider: 1e-9, suffix: 'n' },
    // { divider: 1e-12, suffix: 'p' },
  ];
  for (var i = 0; i < ranges.length; i++) {
    if (Math.abs(n) >= ranges[i].divider) {
      return (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix;
    }
  }
  return n.toFixed(2).toString();
}

export function CurrencyFormatNumber(n) {
  n = parseFloat(n);
  var ranges = [
    { divider: 1e18, suffix: 'P' },
    { divider: 1e15, suffix: 'E' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e7, suffix: 'Cores' },
    { divider: 1e5, suffix: 'Lakhs' },
    { divider: 1e3, suffix: 'Thoushand' },
    { divider: 1, suffix: '' },
    // { divider: 1e-3, suffix: 'm' },
    // { divider: 1e-6, suffix: 'µ' },
    // { divider: 1e-9, suffix: 'n' },
    // { divider: 1e-12, suffix: 'p' },
  ];
  for (var i = 0; i < ranges.length; i++) {
    if (Math.abs(n) >= ranges[i].divider) {
      return (n / ranges[i].divider).toFixed(2).toString() + ranges[i].suffix;
    }
  }
  return n.toFixed(2).toString();
}

export function extractFilterOptionForModal(filter_option) {
  let state = '';
  let site = '';
  let dateRange = 'Last 6 months';
  let alert = '';
  filter_option.forEach(element => {
    if (element.filter_type === 'date_range') {
      dateRange = element.value;
    } else if (element.filter_type === 'sites_count') {
      site = element.value.split(',');
    } else if (element.filter_type === 'state_count') {
      state = element.value.split(',');
    } else if (element.filter_type === 'alert_count') {
      alert = element.value.toString();
    }
  });
  return [state, site, dateRange, alert];
}

export function extractFilterOption(filter_option) {
  let state = '';
  let site = '';
  let from_date = '';
  let to_date = '';
  filter_option.forEach(element => {
    if (element.filter_type === 'date_range') {
      [from_date, to_date] = extractFromAndToDate(element.value);
    } else if (element.filter_type === 'sites_count') {
      site = element.value.toString();
    } else if (element.filter_type === 'state_count') {
      state = element.value.toString();
    }
  });
  return [state, site, from_date, to_date];
}

export function extractRecommendationFilterOption(filter_option) {
  let state = '';
  let site = '';
  let from_date = '';
  let to_date = '';
  let alert = '';
  [from_date, to_date] = extractFromAndToDate('Last 6 months');
  filter_option.forEach(element => {
    // console.log(filter_option);
    if (element.filter_type === 'date_range') {
      [from_date, to_date] = extractFromAndToDate(element.value);
    } else if (element.filter_type === 'sites_count') {
      site = element.value.toString();
    } else if (element.filter_type === 'state_count') {
      state = element.value.toString();
    } else if (element.filter_type === 'alert_count') {
      alert = element.value.toString();
    }
  });
  return [state, site, from_date, to_date, alert];
}

export function extractFromAndToDate(range) {
  //console.log(' range ->', range);
  let obj = {
    startDate: new Date(),
    endDate: new Date(),
  };
  if (range === 'Today') {
  } else if (range === 'Last 7 days') {
    obj.startDate = addDays(obj.endDate, -6);
  } else if (range === 'Last 14 days') {
    obj.startDate = addDays(obj.endDate, -13);
  } else if (range === 'Last month') {
    obj.startDate = addDays(obj.endDate, -29);
  } else if (range === 'Last 6 months') {
    obj.startDate = addMonths(obj.endDate, -6);
  } else if (range === 'Last 14 days') {
    obj.startDate = addDays(obj.endDate, -13);
  } else if (range === 'Last 12 months') {
    obj.startDate = addYears(obj.endDate, -1);
    // } else if (range === 'Year to Date') {
    //   obj.startDate = financial_year(new Date());
    // } else if (range === 'Month to Date') {
    //   obj.startDate = month_date(new Date());
  } else {
    let arr = range.split(' to ');
    obj.startDate = new Date(arr[0]);
    obj.endDate = new Date(arr[1]);
  }
  //console.log(' obj.startDate ->', getFormattedDate(obj.startDate), ' & obj.endDate ->', getFormattedDate(obj.endDate));
  return [getFormattedDate(obj.startDate), getFormattedDate(obj.endDate)];
}

export const chartFunctionKey = {
  'Actual Power Vs Budgeted Power': 'actualVsBudgeted',
  'Average GHI': 'averageGhi',
  'PLF Trend %': 'plfTrend',
  'PR Trend': 'prTrend',
  'Mean Inverter Efficiency': 'meanInverterEfficiency',
  'Inverter Derating Loss Trend': 'inverterDerating',
  'Soiling Loss Trend': 'soilingLossTrend',
  'Inverter Flagging Summary: Model wise': 'inverterFlaggingModelData',
  'Inverter Flagging Summary': 'averageGhi',
  'Flagged inverter Overview': 'inverterFLaggedEfficiencyData',
  'Actual Power vs Budgeted Generation': 'actualVsBudgeted',
  'Inverter efficiency Trend': 'inverterEfficiency',
  'Soiling Loss Trend Assets': 'soilingLossTrendAssets',
  'Wind Speed Direction': 'windSpeedDirection',
  'Total generation vs CUF vs PR trend': 'totalGenerationvsCUFvsPRTrend',
  'GHI Trend': 'ghiTrend',
  'GTI Trend': 'gtiTrend',
  'Soiling loss': 'soilingLoss',
  'Average Soiling loss': 'averageSoilingLoss',
  'Rain and cleaning cycle vs soiling loss': 'rainAndCleaningCycleVsSoilingLoss',
  'Cleaning cost vs soiling loss': 'cleaningCostVsSoilingLoss',
  'WCPR vs cleaning trend': 'wcprVsCleaningTrend',
  '% Model Adherence': 'percentageModelAdherence',
  '% Data Adherence': 'percentageDataAdherence',
  'Total Generation': 'totalGeneration',
  'Active Power': 'activePower',
  'Mean inverter efficiency-asset wise trend': 'meanInverterEfficiencySiteAssetTrends',
  'Active power-asset wise trend': 'activePowerAssetWiseTrend',
};

export const chartInterval = {
  'Actual Power Vs Budgeted Power': 60000, // ---
  'Average GHI': 60000, // ---
  'PLF Trend %': 60000, //  ---
  'PR Trend': 60000, //  ---
  'Mean Inverter Efficiency': 2147483647,
  'Inverter Derating Loss Trend': 2147483647, // 1 month
  'Soiling Loss Trend': 60000, // ---
  'Inverter Flagging Summary: Model wise': 60000, // ---
  'Inverter Flagging Summary': 60000, // ---
  'Flagged inverter Overview': 24 * 60 * 60000, // ---
  'Actual Power vs Budgeted Generation': 60000, // ---
  'Inverter efficiency Trend': 60000, // ---
  'Soiling Loss Trend Assets': 2147483647, // 1 month
  'Wind Speed Direction': 60 * 60000, // 1 hour
  'Total generation vs CUF vs PR trend': 60 * 60000, // 1 hour
  'GHI Trend': 60000, // 1 min
  'GTI Trend': 60000, // 1 min
  'Soiling loss': 2147483647, // 1 month
  'Average Soiling loss': 2147483647, // 1 month
  'Rain and cleaning cycle vs soiling loss': 2147483647, // 1 month
  'Cleaning cost vs soiling loss': 2147483647, // 1 month
  'WCPR vs cleaning trend': 24 * 60 * 60000, // 1 day
  '% Model Adherence': 24 * 60 * 60000, // 1 day
  '% Data Adherence': 24 * 60 * 60000, // 1 day
  'Total Generation': 60 * 60000, // 1 hour
  'Active Power': 60000, // 1 min
  'Mean inverter efficiency-asset wise trend': 2147483647, // 1 month
  'Active power-asset wise trend': 2147483647, // 1 month
};

export const chartDefaultDateRange = {
  'Actual Power Vs Budgeted Power': 'Last 6 months', // ---
  'Average GHI': 'Last 6 months', // ---
  'PLF Trend %': 'Last 6 months', //  ---
  'PR Trend': 'Last 6 months', //  ---
  'Inverter Derating Loss Trend': 'Last 6 months',
  'Mean Inverter Efficiency': 'Last 6 months',
  'Soiling Loss Trend': 'Last 6 months', // ---
  'Inverter Flagging Summary: Model wise': 'Last 6 months', // ---
  'Inverter Flagging Summary': 'Last 6 months', // ---
  'Flagged inverter Overview': 'Last 6 months', // ---
  'Actual Power vs Budgeted Generation': 'Last 6 months', // ---
  'Inverter efficiency Trend': 'Last 6 months', // ---
  'Soiling Loss Trend Assets': 'Last 6 months', //
  'Wind Speed Direction': 'Today',
  'Total generation vs CUF vs PR trend': 'Last 6 months',
  'GHI Trend': 'Last 7 days',
  'GTI Trend': 'Last 7 days',
  'Soiling loss': 'Last 6 months',
  'Average Soiling loss': 'Last 6 months',
  'Rain and cleaning cycle vs soiling loss': 'Last 6 months',
  'Cleaning cost vs soiling loss': 'Last 6 months',
  'Total Generation': 'Last 7 days',
  'Active Power': 'Last 7 days',
  'WCPR vs cleaning trend': 'Last 6 months',
  '% Model Adherence': 'Last 6 months',
  '% Data Adherence': 'Last 6 months',
  'Mean inverter efficiency-asset wise trend': 'Last 6 months',
  'Active power-asset wise trend': 'Last 6 months',
};

export const cardFunctionKey = {
  GHI: 'ghiCard',
  CUF: 'cufCard',
  'Total Generation': 'totalGenerationCard',
  'Scheduled Energy': 'scheduledEnergyCard',
  'Alarm count': 'alarmCountCard',
  'Active Power': 'activePowerCard',
  'Active Alarm': 'activeAlarmCard',
  'Actual Energy': 'alarmCountCard',
  'Soiling Loss': 'soilingLossCard',
  GTI: 'gtiCard',
  'Ambient Temperature': 'ambientTemperatureCard',
  'Inverter Efficiency Mean': 'inverterEfficiencyMeanCard',
  'PR Trend': 'prTrendCard',
  'Active Power-Asset Level': 'activePowerAssestCard',
  'Total Generation-Asset Level': 'totalGenerationAssetCard',
  'Asset Availability': 'assestAvailabilityCard',
};

export const cardInterval = {
  GHI: 60000, // 1 min
  CUF: 60000, // 1 min
  'Total Generation': 60000, // 1 min
  'Scheduled Energy': 60000, // 1 min ---
  'Alarm count': 60000, // 1 min ---
  'Active Power': 60000, // 1 min
  'Active Alarm': 24 * 60 * 60000, // 1 day
  'Actual Energy': 60000, // 1 min ---
  'Soiling Loss': 2147483647, // 1 month
  GTI: 60000, // 1 min ---
  'Ambient Temperature': 60000, // 1 min
  'Inverter Efficiency Mean': 24 * 60 * 60000, // 1 day
  'PR Trend': 60000, // 1 min
  'Active Power-Asset Level': 60000, // 1 min
  'Total Generation-Asset Level': 60000, // 1 min
  'Asset Availability': 60000 * 15, // 15 min
};

export const cardDefaultDateRange = {
  GHI: 'Today',
  CUF: 'Today',
  'Total Generation': 'Today',
  'Scheduled Energy': 'Today', // ---
  'Alarm count': 'Today', // ---
  'Active Power': 'Today',
  'Active Alarm': 'Last 6 months',
  'Actual Energy': 'Today', // ---
  'Soiling Loss': 'Last 6 months',
  GTI: 'Today', // ---
  'Ambient Temperature': 'Today',
  'Inverter Efficiency Mean': 'Last 6 months',
  'PR Trend': 'Today',
  'Active Power-Asset Level': 'Today',
  'Total Generation-Asset Level': 'Today',
  'Asset Availability': 'Today',
};

export const getDateRange = (from_date, to_date, date) => {
  let start_date = startOfMonth(date);
  let end_date = endOfMonth(date);
  from_date = new Date(from_date);
  to_date = new Date(to_date);
  return [
    getFormattedDate(from_date > start_date ? from_date : start_date),
    getFormattedDate(to_date < end_date ? to_date : end_date),
  ];
};

export const recommendFunctionKey = {
  'Total Alarms': 'getTotalAlarms',
  'Pending Alarms': 'getPendingAlarms',
  'Resolved Alams': 'getResolvedAlarms',
  'Under Maintaintance': 'getMantainanceAlarms',
};

export function extractFilterKeyArrayOperation(titleArray) {
  let titleKeyArray = [];
  titleArray.forEach(element => {
    titleKeyArray.push(operationsFunctionKey[element]);
  });
  return titleKeyArray;
}

export function extractFilterKeyArray(titleArray) {
  let titleKeyArray = [];
  titleArray.forEach(element => {
    titleKeyArray.push(recommendFunctionKey[element]);
  });
  return titleKeyArray;
}

export const operationsFunctionKey = {
  'Total Alarms': 'operations/getTotalAlarms',
  'Under Maintaintance': 'operations/getMantainanceAlarms',
  'Resolved Alams': 'operations/getResolvedAlarms',
  'Pending Alarms': 'operations/getPendingAlarms',
  'Due date': 'operations/getDueDates',
};

export function checkPageLevelAccess(accessList, userType, tabLabel) {
  if (userType !== null && userType == 1) {
    return true;
  } else if (
    typeof accessList === 'object' &&
    typeof accessList.edit_page_level_access !== 'undefined' &&
    accessList.edit_page_level_access.length > 0
  ) {
    return accessList.edit_page_level_access.includes(`${tabLabel}`);
  } else {
    return false;
  }
}

export const urlPatternValidation = URL => {
  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  return regex.test(URL);
};

export const tableFunctionKey = {
  'State-level Monitoring': 'getMonitorStateTable',
  'Site-level Monitoring': 'getMonitorSiteTable',
};

//['primary','primary_hover','secondary','secondary_hover','mis'];
export const theme_obj = {
  Default: {
    color: ['#ffffff', '#EAEBEB', '#056C45', '#018786', '#B2DF8A'],
  },
  Light: {
    // color: ['#FFD166', '#FFE7AF', '#c58882', '#df928e', '#1d201f'],
    // color: ['#ccd5ae', '#e9edc9', '#faedcd', '#fefae0', '#d4a373'],
    // color: ['#089DD9', '#C3F7FE', '#00a896', '#02c39a', '#f0f3bd'],
    color: ['#f7cac9', '#ffdcdb', '#91a8d0', '#d4c8ea', '#fff'],
  },
  Dark: {
    color: ['#000000', '#00000033', '#4f5b66', '#c0c5ce', '#65737e'],
    // color: ['#20123c', '#271e4b', '#241056', '#3b2763', '#55417b'],
  },
};
// {
//   name: 'Modern',
//   color: ['#1e1e1e', '#3858e9', '#33f078'],
// },
// {
//   name: 'Blue',
//   color: ['#096484', '#4796b3', '#52accc', '#74B6CE'],
// },
// {
//   name: 'Coffee',
//   color: ['#46403c', '#59524c', '#c7a589', '#9ea476'],
// },
// {
//   name: 'Ectoplasm',
//   color: ['#413256', '#523f6d', '#a3b745', '#d46f15'],
// },
// {
//   name: 'Midnight',
//   color: ['#25282b', '#363b3f', '#69a8bb', '#e14d43'],
// },
// {
//   name: 'Ocean',
//   color: ['#627c83', '#738e96', '#9ebaa0', '#aa9d88'],
// },
// {
//   name: 'Sunrise',
//   color: ['#b43c38', '#cf4944', '#dd823b', '#ccaf0b'],
// },
// ];

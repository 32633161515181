import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadExcel } from 'react-export-table-to-excel';
import { styled } from '@mui/material/styles';
import { Button } from '@material-ui/core';
import OutputFormtwo from './OutputFormtwo';
import OutputFormOne from './OutputFormOne';
import OutputForm from './OutputForm';
import OutputFormThree from './OutputFormThree';
import * as queryString from 'query-string';
import * as CryptoJS from 'crypto-js';
import * as AppConstants from '../../@jumbo/constants/AppConstants';
import axios from '../../services/auth/config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{height:'500px'}}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const [strin, setStr] = useState();
  const [isDisplayed, setIsDisplayed] = useState(false);
    useEffect(() => {
      setInterval(() => {
        setIsDisplayed(true);
      }, 1000);
    }, []);
  let params = queryString.parse(window.location.search);
  let str = decodeURIComponent(params.str);
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(str, 'adsfghlkjwasdxfgyuiolkjhgfdsfghjklmjnb');
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  axios.post('/formdata/fetch', { user_id: decryptedData.uid }).then(({ data }) => {
    if (data.status == true) {
      setStr(data.data[0].output_url);
    }
  });
  let param = queryString.parse(strin);
  let stri = decodeURIComponent(param['/reform/result?str']);
  const StyledTabs = styled(props => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        style: { height: 7 },
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 400,
      width: '100%',

      backgroundColor: '#235F2E',
    },
  });
  const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }));

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  if (stri != str) {
    return (
      <Typography sx={{ m: 3, mt: 2 }} align="center" color="red" variant="h4" gutterBottom>
      {isDisplayed &&
        <>
        This Page is not Accessible anymore.
        </>
      }
      </Typography> 
    );
  } else {
    return (
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 20,
          width: '75%',
          borderRadius: '10px',
          alignSelf: 'center',
          height: '550px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
        <AppBar style={{ background: '#378243' }} sx={{ borderRadius: '10px' }} position="static">
          <StyledTabs value={value} onChange={handleChange} textColor="inherit" variant="fullWidth">
            <Tab
              sx={{
                fontSize: '15px',
                fontWeight: 'small',
                textTransform: 'none',
              }}
              label="User Details"
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: 'white' }}
              sx={{
                fontSize: '15px',
                fontWeight: 'small',
                textTransform: 'none',
              }}
              label="Landed Tariff"
              {...a11yProps(1)}
            />
            <Tab
              sx={{
                fontSize: '15px',
                fontWeight: 'small',
                textTransform: 'none',
              }}
              label="Replacement & Savings"
              {...a11yProps(2)}
            />
            <Tab
              sx={{
                fontSize: '15px',
                fontWeight: 'small',
                textTransform: 'none',
              }}
              label="Assumptions"
              {...a11yProps(3)}
            />
            {/* <Tab sx={{ fontSize: '18px', fontWeight: 'bold' }} label="Output 4" {...a11yProps(3)} /> */}
          </StyledTabs>
        </AppBar>
        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <OutputForm decryptedData={decryptedData} />  
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <OutputFormOne decryptedData={decryptedData} /> 
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <OutputFormtwo decryptedData={decryptedData} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <OutputFormThree />
          </TabPanel>
        </SwipeableViews>
      </Box>
    );
  }
}

import React, { useEffect, useState } from 'react';
import OutputFormUi from '../../component/OutputForm/OutputForm';

const OutputFormPage = () => {
    return(
    <div>
        <OutputFormUi />
    </div>
    );
}

export default OutputFormPage;
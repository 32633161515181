import React from 'react';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser, setCurrentUserProfile } from '../../../redux/actions/Auth';
import axios from '../config';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('auth/login', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            if (data.status) {
              localStorage.setItem('token', data.token);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
              dispatch(fetchSuccess());
              // dispatch(setCurrentUserProfile(data.user));
              localStorage.setItem('profile', JSON.stringify(data.user));
              dispatch(JWTAuth.getAuthUser(true, data.token));
            } else {
              dispatch(fetchError(data.message));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  // onLogout: () => {
  //   return dispatch => {
  //     dispatch(fetchStart());
  //     axios
  //       .post('auth/logout')
  //       .then(({ data }) => {
  //         if (data.result) {
  //           dispatch(fetchSuccess());
  //           localStorage.removeItem('token');
  //           dispatch(setAuthUser(null));
  //         } else {
  //           dispatch(fetchError(data.error));
  //         }
  //       })
  //       .catch(function(error) {
  //         dispatch(fetchError(error.message));
  //       });
  //   };
  // },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      localStorage.removeItem('token');
      localStorage.removeItem('profile');
      dispatch(fetchSuccess());
      dispatch(setAuthUser(null));
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .post('auth/me', {
          token: token,
        })
        .then(({ data }) => {
          if (data.data) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());
      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;

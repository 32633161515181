import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import configureStore, { history } from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "nouislider/dist/nouislider.css";
import './css/style.css';
import './css/table.css';
import './css/custom.css';
import './css/responsive.css';
import './css/timeline.css';
import './css/aos.css';
import './css/reset.css';
import './css/common.css';
import  'bootstrap/dist/js/bootstrap.bundle';
import Routes from './routes';

export const store = configureStore();

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Routes />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default App;

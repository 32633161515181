import React from "react";

const Breadcum = (props) => {
    return(
        <section className="who-we-are-breadcrumb inner-banner jumbotron text-center" style={{display:props.getDisplay}}>
        <div className="container-fluid py-5">
            <div className="row justify-content-center">
                <div className="col-12">
                    <ol className="breadcrumb justify-content-center ">
                        <li className="breadcrumb-item"><a className="white-text" href="https://www.renew.com">Home</a></li>
                        <li className="breadcrumb-item active">Decarbonization Calculator</li>
                    </ol>
                    <div className="text-center">
                        <h2 className="inner-heading-title text-center">Decarbonization Calculator</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}
export default Breadcum;
import React,{ useEffect, useState }from 'react';
import axios from '../../services/auth/config';
import { format, compareAsc } from 'date-fns'
import { RENEW_NEWS_DETAIL, RENEW_NEWS, RENEW_PRESS_RELEASE } from 'v2/constant/constant';

const Footer = (props) => {
  const [news, setNews] = useState({});
  const [press, setPress] = useState({});

  //useEffect(() => {
    //axios.get('/getnews').then(({ data }) => {
      //if (data.status == true) {
        //setNews(data.news[0]);
        //setPress(data.media[0]);
      //}
    //});
  //}, []);
  const createNewsDate = (date) => {
    let temp = date.split('-');
    return format(new Date(temp[0], temp[1]-1, temp[2]), 'MMMM')+' '+
    format(new Date(temp[0], temp[1], temp[2]), 'yyyy'); 
  }
  return (
    <footer id="footer" style={{display:props.getDisplay}}>
      <div className="container-fluid padding-7">
        <div className="row">
          <div className="col-md-12 col-lg-3">
            <div className="first-part">
              <a href="https://www.renew.com">
            <img src={"../../imagesNew/renew_logo.png"} className="img-fluid" alt="ReNew Power Logo" />
              </a>
              <p>
                ReNew is the leading decarbonisation solutions company listed on Nasdaq (Nasdaq: RNW, RNWWW). ReNews clean
                energy portfolio of ~13.4 GWs on a gross basis as of February, 2023 is one of the largest globally. In
                addition to being a major independent power producer in India, we provide end-to-end solutions in a just and
                inclusive manner in the areas of clean energy, green hydrogen, value-added energy offerings through
                digitalisation, storage, and carbon markets that increasingly are integral to addressing climate change.
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="footer-menu footer-memu-new">
              <div className="accordion accordion-grid-template" id="accordionExample">
                <div className="acordian-col-6">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne">
                        About Us
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hone">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cone"
                              aria-expanded="false"
                              aria-controls="cone">
                              {' '}
                              Our Entrepreneurial Journey{' '}
                            </button>
                          </h2>
                          <div id="cone" className="accordion-collapse collapse" aria-labelledby="hone">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/who-we-are"> Who We Are</a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/the-future-of-energy"> The Future of Energy</a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/over-decade-of-transformation"> A Decade Of Transformation </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/global-presence"> Global Footprint</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hone1">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cone1"
                              aria-expanded="false"
                              aria-controls="cone1">
                              {' '}
                              Our Team{' '}
                            </button>
                          </h2>
                          <div id="cone1" className="accordion-collapse collapse" aria-labelledby="hone">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/leadership"> Leadership</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="htwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ctwo"
                              aria-expanded="false"
                              aria-controls="ctwo">
                              {' '}
                              Research & Development{' '}
                            </button>
                          </h2>
                          <div id="ctwo" className="accordion-collapse collapse" aria-labelledby="htwo">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/our-partner"> Our Partners</a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/centre-of-excellence"> Center Of Excellence</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hthree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cthree"
                              aria-expanded="false"
                              aria-controls="cthree">
                              Press Room
                            </button>
                          </h2>
                          <div id="cthree" className="accordion-collapse collapse" aria-labelledby="hthree">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/news"> News</a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/press-release"> Press Release</a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/coverage-and-events">Events</a>
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/awards"> Awards</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                        Decarbonization Solutions
                      </button>
                    </h2>

                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hfour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cfour"
                              aria-expanded="false"
                              aria-controls="cfour">
                              {' '}
                              Energy Generation{' '}
                            </button>
                          </h2>

                          <div id="cfour" className="accordion-collapse collapse" aria-labelledby="hfour">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/wind-energy">Utility Scale Wind </a>
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/solar-energy">Utility Scale Solar</a>{' '}
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/hydropower-energy">Hydropower</a>
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/hybrid-energy"> Firm Power - Round-The-Clock </a>{' '}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hfive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cfive"
                              aria-expanded="false"
                              aria-controls="cfive">
                              {' '}
                              Net Zero Solutions For Corporates{' '}
                            </button>
                          </h2>

                          <div id="cfive" className="accordion-collapse collapse" aria-labelledby="hfive">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/corporate-power-purchase-agreements-ppa">Innovative PPA</a>
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/green-credits"> Green Credits</a>{' '}
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/energy-management-solutions"> Energy Management Solution </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/energy-battery-storage">Storage </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hsix">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#csix"
                              aria-expanded="false"
                              aria-controls="csix">
                              {' '}
                              Green Hydrogen
                            </button>
                          </h2>
                          <div id="csix" className="accordion-collapse collapse" aria-labelledby="hsix">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/green-hydrogen">Overview </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                        {' '}
                        Leading Sustainably
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hseven">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cseven"
                              aria-expanded="false"
                              aria-controls="cseven">
                              {' '}
                              Our Approach
                            </button>
                          </h2>

                          <div id="cseven" className="accordion-collapse collapse" aria-labelledby="hseven">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/esg-environment-social-governance">
                                Approach To Sustainability
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight"
                              aria-expanded="false"
                              aria-controls="ceight">
                              {' '}
                              Advocacy & Partnership
                            </button>
                          </h2>

                          <div id="ceight" className="accordion-collapse collapse" aria-labelledby="height">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/advocacy-and-partnership"> Advocacy & Partnership</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height1">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight1"
                              aria-expanded="false"
                              aria-controls="ceight1">
                              {' '}
                              Social
                            </button>
                          </h2>

                          <div id="ceight1" className="accordion-collapse collapse" aria-labelledby="height1">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/csr-corporate-social-responsibility"> Social Responsibility</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height2">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight2"
                              aria-expanded="false"
                              aria-controls="ceight2">
                              {' '}
                              Reports & Disclosures
                            </button>
                          </h2>

                          <div id="ceight2" className="accordion-collapse collapse" aria-labelledby="height2">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/disclosuers-and-reporting"> Reports & Disclosures</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour">
                        {' '}
                        Investor Relations
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height3">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight3"
                              aria-expanded="false"
                              aria-controls="ceight3">
                              {' '}
                              Governance
                            </button>
                          </h2>
                          <div id="ceight3" className="accordion-collapse collapse" aria-labelledby="height3">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/corporate-profile"> Company Profile </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/leadership"> Leadership </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/governance"> Corporate Governance </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height4">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight4"
                              aria-expanded="false"
                              aria-controls="ceight4">
                              {' '}
                              Financing/Debt Capital Market
                            </button>
                          </h2>
                          <div id="ceight4" className="accordion-collapse collapse" aria-labelledby="height4">
                            <div className="accordion-body">
                              <a href=""> Green Financing </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://renewpower.in/bondholder-information/" target="_blank">
                                {' '}
                                Bondholder Information{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height5">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight5"
                              aria-expanded="false"
                              aria-controls="ceight5">
                              {' '}
                              IR Materials
                            </button>
                          </h2>

                          <div id="ceight5" className="accordion-collapse collapse" aria-labelledby="height4">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/sec-filings"> SEC Filings </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/quarterly-results"> Quarterly Results </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/annual-reports"> Annual Report </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/other-presentations"> Other Presentations </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/investor-faqs"> Investor FAQs </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/historical-financials"> Historical Financials </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height6">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight6"
                              aria-expanded="false"
                              aria-controls="ceight6">
                              {' '}
                              Stock
                            </button>
                          </h2>

                          <div id="ceight6" className="accordion-collapse collapse" aria-labelledby="height4">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/stock-quote-chart"> Stock Quote And Chart </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/analyst-coverage"> Analyst Coverage </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height7">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight7"
                              aria-expanded="false"
                              aria-controls="ceight7">
                              {' '}
                              News And Events
                            </button>
                          </h2>
                          <div id="ceight7" className="accordion-collapse collapse" aria-labelledby="height4">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/news"> News </a>
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/investor-events"> Events </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="height8">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#ceight8"
                              aria-expanded="false"
                              aria-controls="ceight8">
                              {' '}
                              Alerts And Contact US
                            </button>
                          </h2>
                          <div id="ceight8" className="accordion-collapse collapse" aria-labelledby="height4">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/contact-ir"> Email Alerts </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="acordian-col-6">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive">
                        Work With Us
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hten">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cten"
                              aria-expanded="false"
                              aria-controls="ctens">
                              {' '}
                              Life At ReNew
                            </button>
                          </h2>
                          <div id="cten" className="accordion-collapse collapse" aria-labelledby="hten">
                            <div className="accordion-body">
                              {' '}
                              <a href="https://www.renew.com/culture-at-renew">Culture @ ReNew</a>{' '}
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/continuous-learning"> Continuous Learning</a>{' '}
                            </div>

                            <div className="accordion-body">
                              {' '}
                              <a href="#"> Rewards & Recognition </a>
                            </div>

                            <div className="accordion-body">
                              {' '}
                              <a href="https://www.renew.com/employee-engagement">Employee Engagement</a>{' '}
                            </div>
                            <div className="accordion-body">
                              {' '}
                              <a href="https://www.renew.com/growth-opportunities">Growth Opportunities</a>{' '}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hten1">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cten1"
                              aria-expanded="false"
                              aria-controls="ctens1">
                              {' '}
                              Campus Engagemement
                            </button>
                          </h2>

                          <div id="cten1" className="accordion-collapse collapse" aria-labelledby="hten1">
                            <div className="accordion-body">
                              <a href="https://www.renew.com/renews-programs"> ReNew's Programs</a>{' '}
                            </div>
                            <div className="accordion-body">
                              {' '}
                              <a href="https://www.renew.com/re-lead">Re-Lead</a>{' '}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-body">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="hten8">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#cten8"
                              aria-expanded="false"
                              aria-controls="ctens8">
                              {' '}
                              Diversity, Equity & Integrity
                            </button>
                          </h2>

                          <div id="cten8" className="accordion-collapse collapse" aria-labelledby="hten8">
                            <div className="accordion-body">
                              {' '}
                              <a href="https://www.renew.com/diversity-inclusion">Diversity & Inclusion</a>{' '}
                            </div>

                            <div className="accordion-body">
                              <a href="https://www.renew.com/initiatives"> Initiatives</a>{' '}
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/initiatives"> Policies(Posh)</a>{' '}
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/initiatives"> Alumni Connect</a>{' '}
                            </div>
                            <div className="accordion-body">
                              <a href="https://www.renew.com/safety-culture-renew"> Safety Culture Renew</a>{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix">
                        RE - Insights
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <h4>
                          <a href="https://www.renew.com/re-glossary">RE Glossary</a>
                        </h4>
                      </div>
                      <div className="accordion-body">
                        <h4>
                          <a href="https://www.renew.com/for-corporates">For Corporates</a>
                        </h4>
                      </div>
                      <div className="accordion-body">
                        <h4>
                          <a href="https://www.renew.com/whitepapers-resources">Whitepapers & Resources</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven">
                        {' '}
                        Business Enquiries
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <h4>
                          <a href="https://www.renew.com/contact-us">Send Your Enquiry</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 desktop-img">
            <div className="latest-comment">
                <h3 className="latest-comment-title mb-3">Recent News</h3>
                  <div className="comments">
                                                      
                            <a href={Object.keys(news).length != 0 ? RENEW_NEWS_DETAIL+'/'+news.slug : ''} className="media-item btn-container" target="_blank">
                            
                            <h4 className="font-18">{news.title}</h4>
                            <span className="comment-date">
                              {Object.keys(news).length != 0 ? 
                                createNewsDate(news.created_at_date)
                              :
                              ''
                              }
                              </span>
                        </a>
                        <a href={RENEW_NEWS} className="btn see_all_footer">See All</a>
                  </div>
                  <hr className="divide1"></hr>
            </div>
           
            <div className="latest-comment">
                  <h3 className="latest-comment-title mb-3">Recent Press Releases</h3>
                  <div className="comments">                               
                    <a href={Object.keys(press).length != 0 ? RENEW_PRESS_RELEASE+'/'+press.slug : ''}  target="_blank">      
                        <h4 className="font-18">{press.title}</h4>
                        <span className="comment-date">
                          {Object.keys(press).length != 0 ? 
                              createNewsDate(press.created_at_date)
                            :
                              ''
                          }
                          </span>
                    </a>
                    <a href={RENEW_PRESS_RELEASE} className="btn  see_all_footer">See All</a>
                  </div>
                  <hr className="divide1"></hr>
            </div>
          </div>
        </div>
        <div className="row justify-content-between middle-div">
          <div className="col-lg-8 col-md-12">
            <div className="footer-title-and-social-icons">
              <h2 className="footer-title text-left">Follow us on</h2>
              <ul className="footer-social_wrapper social-btns">
                <li>
                  <a className="btn facebook" href="https://www.facebook.com/ReNew4Earth/" target="_blank">
                    <img src={"../../imagesNew/homepage/icons/facebook-white.svg"} />
                  </a>
                </li>
                <li>
                  <a className="btn instagram" href="https://www.instagram.com/renewcorp/" target="_blank">
                <img src={"../../imagesNew/homepage/icons/instagram-white.svg"} />
                  </a>
                </li>
                <li>
                  <a className="btn twitter" href="https://twitter.com/ReNewCorp" target="_blank">
                    <img src={"../../imagesNew/homepage/icons/twitter-white.svg"} />
                  </a>
                </li>
                <li>
                  <a className="btn linkedin" href="https://in.linkedin.com/company/renew" target="_blank">
                    <img src={"../../imagesNew/homepage/icons/linkedin-white.svg"} />
                  </a>
                </li>
                <li>
                  <a className="btn youtube" href="https://www.youtube.com/ReNew_Power" target="_blank">
                    <img src={"../../imagesNew/homepage/icons/youtube-white.svg"} />
                  </a>
                </li>
              </ul>
              <script src="https://www.google.com/recaptcha/api.js?hl=&render=6LenRLYlAAAAANzKuLQeWwaLDyg6Wt7xcDSMQVpJ"></script>
            </div>
          </div>
        </div>
        <hr className="divide1"></hr>
        <div className="container-fluid padding-7">
          <div className="row">
            <div className="col-lg-12 cl-md-12">
              <div className="copyright copyright-new">
                <p>
                  Copyright © ReNew 2023 - All Rights Reserved <span>|</span>
                </p>
                <p>
                  <a href="#">Sitemap </a>
                  <span>|</span>
                </p>
                <p>
                  <a href="https://www.renew.com/sitemap.xml">XML</a>
                  <span>|</span>
                </p>
                <p>
                  <a href="https://www.renew.com/terms-and-conditions">Terms of Use </a>
                  <span>|</span>
                </p>
                <p>
                  <a href="https://www.renew.com/privacy-and-policy">Privacy & Policy </a>
                  <span>|</span>
                </p>
                <p>
                  <a href="https://www.renew.com/disclaimer-crisil">Disclaimer (CRISIL) </a>
                  <span>|</span>
                </p>
                <p>
                  <a href="https://renewpower.in/renew-power-india/">ReNew India</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="cookies" id="cookie-banner" style={{display:'none'}}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-11 col-md-12">
                <div className="row gx-5">
                  <div className="col-md-12">
                    <div className="cookie-banner">
                      <p>
                    <img src={"../../imagesNew/cookies.svg"} className="img-fluid cookie-img" />
                        We use cookies to improve your journey and to personalize your web experiences . By continuing to use
                        this site, you are accepting our <a href="https://www.renew.com/cookie-policy">cookies policy.</a>
                      </p>
                      <div className="cookies-btns-group">
                        <button
                          className="cookie-close cookie-close-bg-change"
                          data-bs-toggle="modal"
                          data-bs-target="#cookie_settings_modal">
                          Cookie Settings
                        </button>
                        <button className="cookie-close" id="accept-cookies">
                          Allow All Cookies
                        </button>
                        <button className="cookie-close" 
                        // onclick="setCookieConsent();"
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    
  );
};

export default Footer;

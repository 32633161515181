import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SamplePage from './Pages/SamplePage';
// import ReCalc from './ReCalc/index';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import UserManagement from './UserManagement/index';
import ReFormResult from './ReCalc/ReFormResult';
import AdvanceFormSubmit from './AdvanceForm/AdvanceFormSubmit';
import AdvanceReCal from './ReCalAdvance';
import PreResult from './preResult';
import Success from './Success';
import { VersionPrefix } from 'v1/@jumbo/constants/AppConstants';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${VersionPrefix}signin`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  if (authUser) {
    // if user is authenticated user it comes here
    if (location.pathname === `${VersionPrefix}` || location.pathname === `${VersionPrefix}`) {
      return <Redirect to={`${VersionPrefix}admin`} />;
    } else if (location.pathname === `${VersionPrefix}signin`) {
      return <Redirect to={`${VersionPrefix}admin`} />;
    }
  } else {
    // if user is not a authenticated user means null it comes here
    let arr = [
      `${VersionPrefix}signin`,
      `${VersionPrefix}signup`,
      `${VersionPrefix}forgot-password`,
      `${VersionPrefix}reform`,
      '/formResult',
      `${VersionPrefix}reform/result`,
      '/adv-reform',
      `${VersionPrefix}reform/verifyuser`,
      `${VersionPrefix}success/user`,
    ];
    if (location.pathname === '' || location.pathname === `${VersionPrefix}`) {
      return <Redirect to={`${VersionPrefix}`} />;
    } else if (!arr.includes(location.pathname)) {
      /* if user is not authenticated and open any url not related to
      login functionalityit comes here */
      return <Redirect to={`${VersionPrefix}signin`} />;
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${VersionPrefix}dashboard`} component={SamplePage} />
        <Route path={`${VersionPrefix}signin`} component={Login} />
        <Route path={`${VersionPrefix}admin`}component={UserManagement} />
        <Route path={`${VersionPrefix}signup`} component={Register} />
        <Route path={`${VersionPrefix}forgot-password`} component={ForgotPasswordPage} />
        <Route path={`${VersionPrefix}reform/verifyuser`} render={props => <PreResult {...props} />} />
        <Route path={`${VersionPrefix}reform/result`} component={AdvanceFormSubmit} />
        <Route path={`${VersionPrefix}success/user`} component={Success} />
        <Route path={`${VersionPrefix}reform`} component={AdvanceReCal} />
        <Route path={`${VersionPrefix}formResult`} component={ReFormResult} />
        <Route path={`${VersionPrefix}`} component={SamplePage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;

import React from 'react';
import { PostAdd } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';
import HomeMenu from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import { VersionPrefix } from 'v1/@jumbo/constants/AppConstants';

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'RE Calculator'} />,
    labelName: 'RE Calculator',
    type: 'item',
    link: `${VersionPrefix}reform`,
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'Dashboard'} />,
    type: 'item',
    labelName: 'Dashboard',
    icon: <HomeMenu />,
    active_tabs: [`${VersionPrefix}dashboard`],
    link: `${VersionPrefix}dashboard`,
  },

  {
    name: <IntlMessages id={'User Management'} />,
    type: 'item',
    labelName: 'User Management',
    icon: <PeopleIcon />,
    active_tabs: [`${VersionPrefix}admin`],
    link: `${VersionPrefix}admin`,
  },
  // {
  //   name: 'User Management',
  //   type: 'item',
  //   link: '/admin',
  // },
  // {
  //   name: 'RE Calculator',
  //   type: 'item',
  //   link: '/deactive',
  // },
  /*{
    name: 'Knowledge Hub',
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
      {
        name: 'Item',
        type: 'item',
        icon: <PostAdd />,
        link: '/default',
      },
      {
        name: 'Item 2',
        type: 'item',
        icon: <PostAdd />,
        link: '/sample',
      },
    ],
  },
  {
    name: 'Our Portfolio',
    type: 'item',
    link: '/deactive',
  },
  {
    name: 'About RGS',
    type: 'item',
    link: '/deactive',
  },
  {
    name: 'News and Media',
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
      {
        name: 'Item 2',
        type: 'item',
        icon: <PostAdd />,
        link: '/sample',
      },
    ],
  },*/
];

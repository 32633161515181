import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { CardContent } from '@material-ui/core';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import Image from './assets/conf1.gif';
import { useState, useEffect } from 'react';
import axios from '../../services/auth/config';
import queryString from 'query-string';
import * as CryptoJS from 'crypto-js';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

  theme.typography.h5 = {
    fontSize: '0.875rem',
  };
  theme.typography.h4 = {
    fontSize: '0.875rem',
  };
  theme.typography.h6 = {
    fontSize: '0.875rem',
  };

  theme.typography.subtitle2 = {
    fontSize: '0.5rem',
  }

const Success = ({ uid, str }) => {
  const [strin, setStr] = useState();
  let params = queryString.parse(window.location.search);
  let q = decodeURIComponent(params.q);
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(q, 'adsfghlkjwasdxfgyuiolkjhgfdsfghjklmjnb');
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  useEffect(() => {
    let url = 'user/change-status/' + decryptedData.uid + '/' + 1;
    axios.get('user/status/' + decryptedData.uid).then(({ data }) => {
      if (data.user_status == false) {
        axios.put(url).then(({ data }) => {});
        axios
          .post('formdata/resultemail', {
            to: decryptedData.email,
          })
          .then(({ data }) => {});
      }
    });
  
    axios.post('/formdata/fetch', { user_id: decryptedData.uid }).then(({ data }) => {
      if (data.status == true) {
        setStr(data.data[0].output_url);
      }
    });
   
  }, []);
  const onSubmit = () => {
    axios.get('user/status/' + decryptedData.uid).then(({ data }) => {
      if (data.user_status == true) {
        let pathname = strin;
        window.location.replace(pathname);
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 3,width:'40%',height:'90%', marginLeft:'30%' }} display="flex" alignItems="center" justifyContent="center">
        <Card sx={{ boxShadow: 10, borderRadius: 4, border: '1px solid green' }} variant="outlined">
          <Box sx={{ height: 200, backgroundColor: 'green' }} align="center">
            {/* <CheckCircleOutlineIcon style={{ color: 'white' }} sx={{ fontSize: 120, mt: 5 }} /> */}
            <img src={Image} alt="Logo" loading="lazy" style={{maxWidth:"25%"}} />
            <Typography align="center" color="white" variant="h4" gutterBottom>
              User Activated.
            </Typography>
          </Box>
          <CardContent style={{ backgroundColor: 'white' }}>
            <Typography sx={{ m: 3, mt: 1 }} align="center" color="green" variant="h4" gutterBottom>
              Thank you for your interest in our services.
            </Typography>
            <Typography sx={{ m: 1, mt: 1 }} align="center" color="green" variant="h5" gutterBottom>
              Please check your email for result link or click the button below.
            </Typography>
            <Typography sx={{ m: 1, mt: 1 }} align="center" color="green" variant="h5" gutterBottom>
              For any support please contact
            </Typography>
            <Typography sx={{ m: 1, mt: 1 }} align="center" color="red" variant="h5" gutterBottom>
              Note: Please check SPAM folder in case you haven’t received the email in your Inbox
            </Typography>
            <Box align="center">
              <Button
                sx={{ height: 45, width: 200 }}
                style={{
                  borderRadius: 20,
                  borderColor: '#9c1c16',
                  padding: '8px 16px',
                  fontSize: '0.7em',
                  margin: '10px',
                  color: '#9c1c16',
                  borderRadius: 20,
                  fontWeight: 700,
                }}
                variant="outlined"
                onClick={onSubmit}>
                Click here to proceed
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
};
export default Success;

import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
// import Nouislider from "nouislider-react";
// import "nouislider/dist/nouislider.css";

const TechDetail2 = props => {
  const [activeClassName, setActiveClassName] = useState();
  const [getInjectionLevel, setInjectionLevel] = React.useState([]);
  const [getOaMode, setOaMode] = React.useState([]);
  const [getOaDuration, setOaDuration] = React.useState([]);
  const [form, setForm] = useState({});
  const [ByDefaultActive, setByDefaultActive] = useState('active');

  let property = props;

  useEffect(() => {
    setActiveClassName('form-section ' + props.active);
    if (!!props.apiData.injection_level) {
      setInjectionLevel(props.apiData.injection_level);
    }
    if (!!props.apiData.oa_modes) {
      setOaMode(props.apiData.oa_modes);
    }
    if (!!props.apiData.oa_durations) {
      setOaDuration(props.apiData.oa_durations);
      setField('oa_duration', 1);
    }
  }, [property.active]);

  useEffect(() => {
    getInjectionLevel.map((type, index) => {
      let str = props.data.plant_location_type == type.id ? setField('injection_level', type.id) : '';
    });
  }, [getInjectionLevel]);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    props.handleData(field, value);
  };

  const handleGreenPowerBar = (field, value, e) => {
    setField(field, value / 100);
    setByDefaultActive('');
    e.target.className = 'active';
  };
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 70,
      label: '70',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 90,
      label: '90',
    },
    {
      value: 100,
      label: '100 %',
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  return (
    <div className={activeClassName}>
      <h2 className="form_section_head">Technical Details (2/2)</h2>
      <div className="form_wrapper_section_grid">
        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/calc_form_ico1.svg'} />
            OA Duration
          </div>
          <label htmlFor="firstname">SELECT CONNECTIVITY LEVEL</label>
          <div className="radio_inpt_grid">
            {Object.keys(getOaDuration).length > 0
              ? getOaDuration.map((type, index) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        name="radio3"
                        value={type.id}
                        disabled={type.id == 1 ? true : false}
                        defaultValue={1}
                        onChange={e => setField('oa_duration', e.target.value)}
                        defaultChecked
                      />
                      <span>
                        {type.name} <img src={'../../imagesNew/info-circle.svg'} />
                        <span className="tooltip_hover_form">{type.description}</span>
                      </span>
                    </label>
                  );
                })
              : ''}
          </div>
          <span className="errormessage">{props.error.oa_duration}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/info-circle.svg'} />
            Injection Level
          </div>
          {/* value={values.injection_level ? values.injection_level : form.injection_level}
                onChange={e => setField('injection_level', e.target.value)} */}
          <label htmlFor="firstname">SELECT INJECTION LEVEL</label>

          <div className="radio_inpt_grid2">
            {Object.keys(getInjectionLevel).length > 0
              ? getInjectionLevel.map((type, index) => {
                  //  let str = props.data.plant_location_type == type.id ? setField('injection_level', type.id):'';
                  return (
                    <label>
                      <input
                        type="radio"
                        name="radio5"
                        value={type.id}
                        disabled={props.data.plant_location_type != type.id ? true : false}
                        checked={props.data.plant_location_type == type.id}
                        onChange={e => setField('injection_level', e.target.value)}
                      />
                      <span>{type.name}</span>
                    </label>
                  );
                })
              : ''}
          </div>
          {props.data.plant_location_type == 1 ? (
            <span ml={4}>
              **We are proposing an inter-state open access due to the absence of ReNew plant in this state.
            </span>
          ) : (
            <span>**We are proposing an intra-state open access due to the presence of ReNew plant in this state</span>
          )}
          <span className="errormessage">{props.error.injection_level}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/info-circle.svg'} />
            OA mode
          </div>
          <label htmlFor="firstname">SELECT THE MODE OF OA</label>
          <div className="radio_inpt_grid2">
            {Object.keys(getOaMode).length > 0
              ? getOaMode.map((type, index) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        name="radio6"
                        value={type.id}
                        onChange={e => setField('oa_mode', e.target.value)}
                      />
                      <span>{type.name}</span>
                    </label>
                  );
                })
              : ''}
          </div>
          <span className="errormessage">{props.error.oa_mode}</span>
        </div>

        <div className="form_wrapper_section_col" id="form_renew">
          <div className="label_lg">
            <img src={'../../imagesNew/info-circle.svg'} />
            Share of Existing Green Consumption
          </div>
          <label htmlFor="firstname">SELECT THE CURRENT % OF GREEN POWER BEING PROCURED</label>
          {/* <div id="year-slider" className="no-ui-slider">
              <div className="calc_slider"></div>
              <ul className="slider-labels eleven-columns"
               onClick={(e) => handleGreenPowerBar('overall_green_target',e.target.value,e)}
              >
                <li className={ByDefaultActive}>0</li>
                <li value={10} className="">10</li>
                <li value={20} className="">20</li>
                <li value={30} className="">30</li>
                <li value={40} className="">40</li>
                <li value={50} className="">50</li>
                <li value={60} className="">60</li>
                <li value={70} className="">70</li>
                <li value={80} className="">80</li>
                <li value={90} className="">90</li>
                <li value={100} className="">100</li>
              </ul>
            </div>  */}
          <Slider
            defaultValue={0}
            value={props.data.overall_green_target ? (props.data.overall_green_target * 100).toFixed(2) : form.overall_green_target}
            onChange={e => setField('overall_green_target',  (e.target.value.toFixed(2))/100)}
            valueLabelDisplay="auto"
            step={1}
            getAriaValueText={valuetext}
            marks={marks}
            min={0}
            max={100}
            sx={{
              m: 1,
              width: 350,
              height: 10,
              color: '#72BF44',
            }}
          />
          <span className="errormessage">{props.error.overall_green_target}</span>
        </div>
      </div>
    </div>
  );
};

export default TechDetail2;

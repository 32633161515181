import React from 'react';

const SideBar = (props) => {
  return (
    <div className="grid_col" style={{display:props.getDisplay}}>
      <div className="calc_steps_wrapper">
        <div className={props.stepCompleted > 0 ? "calc_steps_content_grid step_completed":"calc_steps_content_grid"} id="company_details">
          <p className="text-right">Company Details</p>
          <div className="stepper_ico">
            <img src={"../../imagesNew/calc_ico1.svg"} />
          </div>
          <span className="stepper_pro_bullets"></span>
        </div>

        <div className={props.stepCompleted > 1 ? "calc_steps_content_grid step_completed":"calc_steps_content_grid"} id="transaction_details1">
          <p>
            Transaction Details
            <br />
            (1/2)
          </p>
          <div className="stepper_ico">
            <img src={"../../imagesNew/calc_ico2.svg"} />
          </div>
          <span className="stepper_pro_bullets"></span>
        </div>

        <div className={props.stepCompleted > 2 ? "calc_steps_content_grid step_completed":"calc_steps_content_grid"} id="transaction_details2">
          <p>
            Transaction Details
            <br />
            (2/2)
          </p>
          <div className="stepper_ico">
            <img src={"../../imagesNew/calc_ico3.svg"} />
          </div>
          <span className="stepper_pro_bullets"></span>
        </div>

        <div className={props.stepCompleted > 3 ? "calc_steps_content_grid step_completed":"calc_steps_content_grid"} id="user_details">
          <p className="text-right">User Details</p>
          <div className="stepper_ico">
            <img src={"../../imagesNew/calc_ico4.svg"} />
          </div>
          <span className="stepper_pro_bullets"></span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;

import React, { useEffect } from 'react';
import { Box, darken, Hidden, Toolbar, Button } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Logo from '../../partials/Logo';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AuhMethods } from '../../../../../services/auth';
import { useDispatch } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
import AppsMenu from '../../partials/Header/AppsMenu';
import HeaderMessages from '../../partials/Header/HeaderMessages';
import HeaderNotifications from '../../partials/Header/HeaderNotifications';
import LanguageSwitcher from '../../partials/LanguageSwitcher';
import SearchPopover from '../../partials/SearchPopover';
import UserDropDown from '../../partials/UserDropDown';
import CmtHorizontal from '../../../../../@coremat/CmtNavigation/Horizontal';
import { horizontalDefaultNavs } from '../../partials/menus';
import { green } from '@material-ui/core/colors';
import { Redirect, Route, Switch } from 'react-router';
import { VersionPrefix } from 'v1/@jumbo/constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    minHeight: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    '& .Cmt-horizontalNavMenu': {
      position: 'static',
      '& .Cmt-navMegaBtn, & > .Cmt-navCollapse > .Cmt-navCollapseBtn': {
        minHeight: 64,
        whiteSpace: 'nowrap',
      },
    },
  },
  button: {
    margin: 15,
    color: 'green',
    padding: '0.3rem 2rem',
    fontWeight: '800',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    },
  },
  calc: {
    marginRight: 16,
    color: 'green',
    alignItems: 'center',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    padding: '5px 5px 5px 10px',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
}));

const MainHeader = () => {
  const classes = useStyles();
  const location = useLocation();
  let token;
  const [showResults, setShowResults] = React.useState(false); // for enable login button it is true

  useEffect(() => {
    token = localStorage.getItem('token');
    if (token) {
      setShowResults(false);
    }
  }, []);

  return (
    <Toolbar className={classes.root}>
      {/* <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" /> */}
      <Logo mr={{ xs: 2, sm: 4, lg: 6, xl: 8 }} color="white" />
      <Hidden mdDown>
        {/* <CmtHorizontal menuItems={horizontalDefaultNavs} /> */}
        <Button className={classes.calc} component={Link} to={`${VersionPrefix}reform`}>
          Re Calculator
        </Button>
      </Hidden>
      {showResults ? (
        <Button className={classes.button} variant="outlined" style={{ marginLeft: 'auto' }} component={Link} to={`${VersionPrefix}signin`}>
          Login <ArrowForwardIcon style={{ fontSize: '17px', marginLeft: '8px' }} />
        </Button>
      ) : null}
      {/* <Box display="flex" alignItems="center" ml="auto">
      <SearchPopover iconClassName={clsx(classes.searchIcon, 'Cmt-searchIcon')} />
      <AppsMenu />
      <HeaderMessages />
      <HeaderNotifications />
      <Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')}>
        <LanguageSwitcher />
      </Box>
      <UserDropDown />
    </Box> */}
    </Toolbar>
  );
};

export default MainHeader;

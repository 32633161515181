import React, { useState } from 'react';
import {
  TextField,
  AppBar,
  Box,
  makeStyles,
  Typography,
  Grid,
  Paper,
  ButtonGroup,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormLabel,
  Hidden,
  Card,
  Button,
  IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import CmtImage from '../../../@coremat/CmtImage';
import CloseIcon from '@material-ui/icons/Close';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(theme => ({
  labelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 2,
    display: 'flex',
    alignItems: 'center',
    color: '#55698C',
  },
  root: {
    //backgroundColor: theme.palette.background.paper,
    width: 500,
    marginLeft: 29,
  },
  OnBoardingHeaderTitleTwoCross: {
    width: 15,
    height: 15,
    position: 'absolute',
    right: '4.93%',
    top: '4.93%',
  },
  buttonNext: {
    background: '#056C45',
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    display: 'flex',
    alignItems: 'center',
    width: 77,
  },
}));
const OEMListFormPopup = props => {
  const classes = useStyles('default');
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [isModalClose, setIsModalClose] = useState(false);
  const handleChange = value => {
    setIsModalClose(value);
    props.parentCallback(value);
    // console.log(' value ->', value);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: 544,
          height: 491,
          top: 164,
          left: 649,
          background: '#FFFFFF',
          position: 'absolute',
          borderRadius: 8,
        }}>
        <Grid container spacing={2} justifyContent="center">
          {/* <Hidden mdDown={true}>
                  <Grid  Item xs={2} md={2} sm={12} lg={2} />
                </Hidden> */}
          <Grid Item xs={12} md={12} sm={12} lg={12}>
            <Grid Item lg={3}>
              <Typography
                variant="h1"
                component="h2"
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: '600',
                  fontSize: '18px',
                  lineHeight: '21px',
                  color: '#22334A',
                  width: 153,
                  height: 21,
                  position: 'absolute',
                  top: 21,
                  left: 19,
                }}>
                Upload OEM Lists
              </Typography>
            </Grid>
            <Grid Item lg={6} />
            <Grid Item lg={3}>
              <IconButton size="small" className={classes.OnBoardingHeaderTitleTwoCross}>
                <CloseIcon onClick={() => handleChange(false)} />
              </IconButton>
            </Grid>
            <Box
              className={classes.root}
              sx={{
                width: 496,
                height: 110,
                backgroundColor: '#F6F8FA',
                border: '1px dashed #A9C2DB',
                boxSizing: 'border-box',
                borderRadius: 6,
                marginTop: 72,
              }}>
              <Grid container spacing={2}>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 204,
                      height: 40,
                      marginLeft: 20,
                      fontSize: 12,
                      height: 100,
                      display: 'flex',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#22334A',
                    }}>
                    Drag your documents, photos, or videos here to start uploading
                  </Typography>
                </Grid>
                <Grid Item lg={2}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 22,
                      height: 20,
                      marginLeft: 20,
                      fontSize: 16,
                      height: 100,
                      display: 'flex',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#22334A',
                    }}>
                    OR
                  </Typography>
                </Grid>
                <Grid Item lg={5}>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      width: 138,
                      height: 41,
                      marginTop: 28,
                      fontSize: 14,
                      display: 'flex',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#22334A',
                      background: '#FFFFFF',
                    }}>
                    Browse Files
                    <input type="file" hidden />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classes.root}
              mt={15}
              sx={{
                width: 464,
                height: 58,
                backgroundColor: '#F5F7F9',
                border: '1px solid #D8E6F4',
                boxSizing: 'border-box',
                borderRadius: 6,
                marginTop: 20,
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}>
              <Grid container spacing={2}>
                <Grid Item lg={2}>
                  <Card
                    style={{
                      width: 18,
                      height: 24,
                      margin: 20,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      alignItems: 'center',
                    }}>
                    <CmtImage src={'/images/onboarding/vector.png'} />
                  </Card>
                </Grid>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 71,
                      height: 63,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Helvetica',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#000000',
                    }}>
                    passport.png
                  </Typography>
                </Grid>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 71,
                      height: 63,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Helvetica',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#000000',
                    }}>
                    5.7MB
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classes.root}
              mt={15}
              sx={{
                width: 464,
                height: 58,
                backgroundColor: '#F5F7F9',
                border: '1px solid #D8E6F4',
                boxSizing: 'border-box',
                borderRadius: 6,
                marginTop: 20,
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}>
              <Grid container spacing={2}>
                <Grid Item lg={2}>
                  <Card
                    style={{
                      width: 18,
                      height: 24,
                      margin: 20,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      alignItems: 'center',
                    }}>
                    <CmtImage src={'/images/onboarding/vector.png'} />
                  </Card>
                </Grid>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 71,
                      height: 63,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Helvetica',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#000000',
                    }}>
                    passport.png
                  </Typography>
                </Grid>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 71,
                      height: 63,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Helvetica',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#000000',
                    }}>
                    5.7MB
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classes.root}
              mt={15}
              sx={{
                width: 464,
                height: 58,
                backgroundColor: '#F5F7F9',
                border: '1px solid #D8E6F4',
                boxSizing: 'border-box',
                borderRadius: 6,
                marginTop: 20,
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}>
              <Grid container spacing={2}>
                <Grid Item lg={2}>
                  <Card
                    style={{
                      width: 18,
                      height: 24,
                      margin: 20,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      alignItems: 'center',
                    }}>
                    <CmtImage src={'/images/onboarding/vector.png'} />
                  </Card>
                </Grid>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 71,
                      height: 63,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Helvetica',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#000000',
                    }}>
                    passport.png
                  </Typography>
                </Grid>
                <Grid Item lg={5}>
                  <Typography
                    variant="body1"
                    style={{
                      width: 71,
                      height: 63,
                      fontSize: 12,
                      display: 'flex',
                      fontFamily: 'Helvetica',
                      fontStyle: 'normal',
                      alignItems: 'center',
                      color: '#000000',
                    }}>
                    5.7MB
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              className={classes.root}
              mt={15}
              sx={{
                width: 464,
                height: 58,
                boxSizing: 'border-box',
                borderRadius: 6,
                marginTop: 20,
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                <Hidden mdDown={false}>
                  <Grid md={9} lg={9} sm={12} />
                </Hidden>
                <Grid md={3} lg={3} sm={12}>
                  <Button className={classes.buttonNext} fullWidth={true} size="large">
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default OEMListFormPopup;

import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

const theme = createTheme();

theme.typography.body2 = {
  // fontSize: '0.7rem',
  fontSize: '0.875rem',
  color: '#000',
};
theme.typography.h6 = {
  // fontSize: '0.875rem',
  fontSize: '0.975rem',
  color: '#000',
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    // backgroundColor: '#F3FDE8',
    backgroundColor: '#FFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#b2df8a',
    padding: 5,
    // backgroundImage: "linear-gradient(greenyellow, navy)",
    // fontFamily: 'Lato-Bold',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    // fontFamily: 'Lato-Regular',
    // fontSize: 15,
  },
}));

export default function CollapsibleTable({}) {
  let [rows, setRows] = useState([]);
  let [tableRows, setTableRows] = useState([]);
  let temp = [];

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 600,
          maxWidth: 800,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <Table aria-label="collapsible table" size="small" style={{ height: '302px', marginTop: '40px' }} stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  backgroundColor: '#98D066',
                  width: '15%',
                  height: '45px',
                }}
                component="td"
                align="center">
                <Typography variant="h6" fontWeight="bold">
                  Assumptions
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow />
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell style={{ backgroundColor: '#fff', width: '15%', height: '40px' }} component="td" align="Left">
                <Typography variant="body2">
                  * Please note that Power Purchase Agreement assumes a tenure of 25 years.
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ width: '15%', height: '40px' }} component="td" align="Left">
                <Typography variant="body2">
                  * To learn more about Captive Investment, please contact the team at go.green@renew.com
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell style={{ width: '15%', height: '40px' }} component="td" align="Left">
                <Typography variant="body2">
                  * Carbon Emission Factor of 930.5 g per kWh has been considered. Source: CEA database, Version 17.0
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}

import './style.css';
import React from 'react';
import Chart from "react-apexcharts";

const StackedBarGraphApexChart = ({ data }) => (
    <Chart options={data.options} series={data.series} type="bar" stacked="true"  height={350}/>
    
);

export default StackedBarGraphApexChart;


import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CompanyDetails from './SectionComponents/CompanyDetails';
import DrawalDetails from './SectionComponents/DrawalDetails';
import InjectionDetails from './SectionComponents/InjectionDetails';
import UserAuth from './SectionComponents/UserAuth';
import { useState } from 'react';
import axios from '../../services/auth/config';
import { Redirect } from 'react-router';
import PreResult from '../preResult';
import { convertToObject } from 'typescript';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: '-40px',
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    //color: 'red',
  },
}));
// const steps = ['Company Details', 'Drawal Details', 'Injection Details', 'Authorization'];
const steps = ['Company Details', 'Technical Details (1/2)', 'Technical Details (2/2)', 'User Details'];

const AdvanceReCal = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  let [formData, setFormData] = useState({});
  const [id, setId] = useState();
  let [compnt, setCompnt] = useState();
  const classes = useStyles();
  const [showLoader, setLoader] = useState(false);

  const theme = createTheme();

  theme.typography.h5 = {
    fontSize: '0.875rem',
  };
  theme.typography.h4 = {
    fontSize: '0.875rem',
  };
  theme.typography.h6 = {
    fontSize: '0.875rem',
  };

  theme.typography.subtitle2 = {
    fontSize: '0.5rem',
  };

  function renderStepContent(activeStep) {
    switch (activeStep) {
      case 1:
        return (
          <ThemeProvider theme={theme}>
            <CompanyDetails handleNext={handleNext} handleFormData={handleInputData} values={formData} />{' '}
          </ThemeProvider>
        );
      case 2:
        return (
          <ThemeProvider theme={theme}>
            <DrawalDetails
              handleNext={handleNext}
              handleBack={handleBack}
              handleFormData={handleInputData}
              values={formData}
            />
          </ThemeProvider>
        );
      case 3:
        return (
          <ThemeProvider theme={theme}>
            <InjectionDetails
              handleNext={handleNext}
              handleBack={handleBack}
              handleFormData={handleInputData}
              values={formData}
            />
          </ThemeProvider>
        );
      case 4:
        return (
          <ThemeProvider theme={theme}>
            <UserAuth handleNext={handleNext} handleBack={handleBack} handleFormData={handleInputData} values={formData} />
          </ThemeProvider>
        );
      default:
        return <Box>Not Found</Box>;
    }
  }

  const callThirdPartyApi = async () => {
    try {
      setFormData({
        // Updating Formdata
        ...formData,
      });
      let updatedFormData;
      axios
        .post('api/getPayloadWithLabel', {
          data: formData,
        })
        .then(({ data }) => {
          updatedFormData = data;
          axios
            .post('api/thirdpartyapi', {
              data: updatedFormData,
            })
            .then(({ data }) => {
              if (data.status) {
                let resp = data.data;
                formData['tarifs'] = resp;
                setFormData({
                  // Updating Formdata
                  ...formData,
                });
                saveClientResponse(resp);
              } else {
                setLoader(false);
                alert('Technical error occurred');
              }
            });
        });
    } catch (err) {
      setLoader(false);
      return false;
    }
  };

  const saveClientResponse = async tarifs => {
    try {
      axios
        .post('formdata', formData, {
          headers: {
            'Content-type': 'multipart/form-date',
          },
        })
        .then(({ data }) => {
          let response = data;
          if (response.status == true) {
            setId(response.id);
            setCompnt(<PreResult uid={response.id} str={response.str} email={response.email} />);
            //callThirdPartyApi();
            axios.get('user/status/' + response.id).then(({ data }) => {
              //    console.log(data);
              if (data.user_status == false) {
                axios
                  .post('formdata/activationemail', {
                    to: formData.email,
                  })
                  .then(({ data }) => {});
              }
            });
            setLoader(false);
            setActiveStep(prevActiveStep => prevActiveStep + 1);
          } else {
            setLoader(false);
            alert(response.message);
            return false;
          }
        });
    } catch (err) {
      return false;
    }
  };

  const handleNext = () => {
    if (activeStep == 3) {
      setLoader(true);
      let thirdpartyStatus = callThirdPartyApi();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleInputData = (field, value) => {
    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };
  return (
    <Box sx={{ width: '100%', boxShadow: 25 }}>
      {activeStep === steps.length ? (
        <React.Fragment>
          {showLoader == true && (
            <div className={classes.circularProgressRoot}>
              <CircularProgress />
            </div>
          )}
          {/* <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Redirect
            to={{
              pathname: '/reformpreresult',
              state: { property_id: id },
            }}
          /> */}
          {compnt}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {showLoader == true && (
            <div className={classes.circularProgressRoot}>
              <CircularProgress />
            </div>
          )}
          <Stepper sx={{ m: 4 }} activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step
                  sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      //  color: '#087D3D ', // circle color (COMPLETED)
                      color: '#056c45',
                    },
                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                      color: 'common.white', // Just text label (COMPLETED)
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      // color: '#8BD000', // circle color (ACTIVE)
                      color: '#76c04e',
                    },
                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                      color: 'black', // Just text label (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: 'white', // circle's number (ACTIVE)
                    },
                  }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ mt: 2, mb: 1 }}> {renderStepContent(activeStep + 1)}</Box>

          {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ m: 4 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button sx={{ m: 4 }} onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box> */}
        </React.Fragment>
      )}
    </Box>
  );
};

export default AdvanceReCal;

let result = window.location.pathname.match("/v1");
if(!!result){
 result = result[0];
}
result === '/v1' ? require("./v1/index") : require('./v2/index');

// let result = window.location.pathname.match("/v2");
// if(!!result){
//  result = result[0];
// }
// result === '/v2' ? require("./v2/index") : require('./v1/index');


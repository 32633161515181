import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import AdvanceReCal from './ReCalculator';
import PreResult from './preResult';
import Success from './Success';
import OutputFormPage from './OutputFormPage';

const Routes = () => {
  const location = useLocation();
  const VersionPrefix = '/';
  if (location.pathname === '' || location.pathname === VersionPrefix) {
    return <Redirect to={'/reform'} />;
  }
  return (
    <React.Fragment>
      <Switch>
        <Route path= {`${VersionPrefix}reform/verifyuser`} render={props => <PreResult {...props} />} />
        <Route path={`${VersionPrefix}reform/result`} component={OutputFormPage} />
        <Route path={`${VersionPrefix}success/user`} component={Success} />
        <Route path={`${VersionPrefix}reform`} component={AdvanceReCal} />
        {/* <Route path="/" component={SamplePage} /> */}
        {/* <Route component={Error404} /> */}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;

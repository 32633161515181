import React from 'react';
import UserDetails from './UserDetails';
import LandedTariff from './LandedTariff';
import ReplacementSaving from './ReplacementSaving';
import Assumptions from './Assumption';
import { Page, View, Document, StyleSheet, Text, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    marginBottom: 20,
    borderBottomColor: '#ccc',
    alignItems: 'center',
},
  companyLogo: {
    height: 40,
    width: 450,
    marginBottom: 10,
  },
  rgsLogo: {
    height: 20,
    width: 350,
    marginBottom: 10,
    marginLeft:  330,
  }


});

const OutputFormPdf = props => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        <Image src='/images/B2B_GreenSolutionslogo-01.jpg' style={styles.companyLogo}/>
        <Image src='/imagesNew/redLogo.png' style={styles.rgsLogo}/>
      </View>
      <View>
        <UserDetails decryptedData={props.decryptedData} />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
    <View style={styles.container}>
        <Image src='/images/B2B_GreenSolutionslogo-01.jpg' style={styles.companyLogo}/>
        <Image src='/imagesNew/redLogo.png' style={styles.rgsLogo}/>
      </View>
      <View>
        <LandedTariff decryptedData={props.decryptedData} />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
    <View style={styles.container}>
        <Image src='/images/B2B_GreenSolutionslogo-01.jpg' style={styles.companyLogo}/>
        <Image src='/imagesNew/redLogo.png' style={styles.rgsLogo}/>
      </View>
      <View>
        <ReplacementSaving decryptedData={props.decryptedData} />
      </View>
    </Page>
    <Page size="A4" style={styles.page}>
    <View style={styles.container}>
        <Image src='/images/B2B_GreenSolutionslogo-01.jpg' style={styles.companyLogo}/>
        <Image src='/imagesNew/redLogo.png' style={styles.rgsLogo}/>
      </View>
      <View>
        <Assumptions />
      </View>
    </Page>
  </Document>
);
export default OutputFormPdf;

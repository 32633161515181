import React, { useEffect, useState } from 'react';
import Header from '../../component/Header/header';
import Footer from '../../component/Footer/footer';
import Urji from '../../component/Footer/Urji';
import Breadcum from '../../component/Breadcum/breadcum';
import MainContent from '../../component/MainContent';

const ReCalculator = () => {
    const[getDisplay,setDisplay] = useState("block");
    const handleDisplay = (str) => {
        setDisplay(str);
    }
    return(
    <div>
        <Header getDisplay={getDisplay}></Header>
        <Breadcum getDisplay={getDisplay}></Breadcum>
        <MainContent display={handleDisplay} getDisplay={getDisplay}></MainContent>
        <Urji getDisplay={getDisplay}></Urji>
        <Footer getDisplay={getDisplay}></Footer>
    </div>
    );
}

export default ReCalculator;
import * as CryptoJS from 'crypto-js';

export const RENEW_URL = 'https://renewpower.in/';
export const RENEW_CAREER = 'https://careers.renewpower.in/';
export const RENEW_NEWS_DETAIL = 'https://www.renew.com/news-detail';
export const RENEW_NEWS = 'https://www.renew.com/news';
export const RENEW_PRESS_RELEASE = 'https://www.renew.com/press-release';


export const isPositiveInteger = (n) => {
    return (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n));
  };
  
export const isPositiveNumber = (n) => {
return (!isNaN(parseFloat(n)) && 0 <= ~~n);
};

export const decryptData = (encryptData) => {
    // Decrypt
    encryptData = decodeURIComponent(encryptData);
    var bytes = CryptoJS.AES.decrypt(encryptData, 'adsfghlkjwasdxfgyuiolkjhgfdsfghjklmjnb');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
    
};
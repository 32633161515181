import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CardMedia from '@mui/material/CardMedia';
import { Grid } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Image from './assets/conf1.gif';
import { useState, useEffect } from 'react';
import axios from '../../services/auth/config';
import { Redirect } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

  theme.typography.h5 = {
    fontSize: '0.875rem',
  };
  theme.typography.h4 = {
    fontSize: '0.875rem',
  };
  theme.typography.h6 = {
    fontSize: '0.875rem',
  };

  theme.typography.subtitle2 = {
    fontSize: '0.5rem',
  }
const PreResult = ({ uid, str, email }) => {
  axios.get('user/status/' + uid).then(({ data }) => {
    if (data.user_status == true) {
      axios
            .post('formdata/resultemail', {
              to: email,
            })
            .then(({ data }) => {});
    }
  });
  const onSubmit = () => {
    axios.get('user/status/' + uid).then(({ data }) => {
      if (data.user_status == true) {
        let pathname = str;
        /***
         * This is added for output screen for version2
         * start
         */
        pathname = '/v2'+pathname;
        /***
         * This is added for output screen for version2
         * end
         */
        window.location.replace(pathname);
      } else {
        alert('Click the link sent to your email to activate account');
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ mt: 3, width:'40%',height:'90%', marginLeft:'30%' }} display="flex" alignItems="center" justifyContent="center">
      <Card sx={{ boxShadow: 10, borderRadius: 4, border: '1px solid green' }} variant="outlined">
        <Box sx={{ height: 200, backgroundColor: 'green' }} align="center">
          {/* <CheckCircleOutlineIcon style={{ color: 'white' }} sx={{ fontSize: 120, mt: 5 }} /> */}
          <img src={Image} alt="Logo" loading="lazy" style={{maxWidth:'25%'}}/>
          <Typography align="center" color="white" variant="h4" gutterBottom>
            Form Submitted Successfully.
          </Typography>
        </Box>
        <CardContent style={{ backgroundColor: 'white' }}>
          <Typography sx={{ m: 3, mt: 1 }} align="center" color="green" variant="h4" gutterBottom>
            Thank you for your interest in our services.
          </Typography>
          <Typography sx={{ m: 1, mt: 1 }} align="center" color="green" variant="h5" gutterBottom>
            User authentication is required to view the optimized green power procurement results.
          </Typography>
          <Typography sx={{ m: 1, mt: 1 }} align="center" color="green" variant="h5" gutterBottom>
            Please verify your email by clicking the link you would have received in your mailbox.
          </Typography>
          <Typography sx={{ m: 1, mt: 1 }} align="center" color="green" variant="h5" gutterBottom>
            For any support please contact
          </Typography>
          <Typography sx={{ m: 1, mt: 1 }} align="center" color="red" variant="h5" gutterBottom>
            Note: Please check SPAM folder in case you haven’t received the email in your Inbox
          </Typography>
          <Box align="center">
            <Button
              sx={{ height: 45, width: 200 }}
              style={{
                borderRadius: 20,
                borderColor: '#9c1c16',
                padding: '8px 16px',
                fontSize: '0.7em',
                margin: '10px',
                color: '#9c1c16',
                fontWeight: 700,
              }}
              variant="outlined"
              onClick={onSubmit}>
              Click here to proceed
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
    </ThemeProvider>
  );
};
export default PreResult;

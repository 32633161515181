export const requiredMessage = 'This field is required!';
export const emailNotValid = 'Email is not valid!';
export const passwordMisMatch = 'Password did not match!';
export const passwordRulesMisMatch = 'Password does not follow under written rules!';
export const phoneOnlyNumericals = 'Phone no. is invalid!';
export const noSpaceMessage = 'No spaces allowed';
export const multipleFilesUpload = 'Only 1 image is allowed to drag here';
export const mailDomainNotValid = 'Mail Domain is not valid!';
export const companyUrlMatch = 'Company url is not valid!';
export const permissionErrorMsg = 'View and Edit permission is not allowed for all module!';
export const onlyPdfImagesAllowed = 'Only pdf and Images are Allowed!';

/* eslint-disable no-unused-vars */
import React, { useState, memo } from 'react';
import { forwardRef } from 'react';
import { CsvBuilder } from 'filefy';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import './OptimaxTable.css';
import { isObject, isUndefined } from 'lodash';
import { getFormattedDate } from '../../@jumbo/utils/dateHelper';
import MaterialTable, { MTableToolbar } from 'material-table';
import { TablePagination, TablePaginationProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import moment from 'moment';

function PatchedPagination(props: TablePaginationProps) {
  const { ActionsComponent, onChangePage, onChangeRowsPerPage, ...tablePaginationProps } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={subprops => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent {...actionsComponentProps} onChangePage={onPageChange} />
        );
      }}
    />
  );
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} style={{ color: '#E00930' }} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => (
    <React.Fragment>
      <SaveAlt {...props} ref={ref} color="secondary" />{' '}
      <span
        style={{
          paddingLeft: '5px',
          fontSize: '14px',
          fontWeight: '500',
          color: '#22334A',
        }}>
        Export
      </span>
    </React.Fragment>
  )),

  Filter: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => (
    <React.Fragment>
      <ViewColumn {...props} ref={ref} />
      <span
        style={{
          paddingLeft: '5px',
          fontSize: '14px',
          fontWeight: '500',
          color: '#22334A',
        }}>
        Customize table
      </span>
    </React.Fragment>
  )),
};

function OptimaxTable(props) {
  const [isModaLOpen, setIsModalOpen] = useState(false);

  const useStyles = makeStyles(theme => ({
    table: {
      // 'tbody tr': "{ color: '#005635', background-color: '#b2df8a' }",
      // 'tbody td nth-of-type(3)': {
      // textAlign: 'right',
      // },
    },
    toolbarWrapper: {
      '& .MuiToolbar-root.MuiToolbar-regular': {
        // eslint-disable-next-line no-useless-computed-key
        ['@media (max-width: 469px)']: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
        },
      },
    },
    // 'tbody tr first-child td': { color: '#005635', backgroundColor: '#b2df8a' },
  }));
  const classes = useStyles();

  let action = props.actions;
  // console.log(' actions', action);
  const [customColumn, setcustomColumn] = React.useState('none');
  let detailPanel = isUndefined(props.detailPanel) ? [] : props.detailPanel;

  const handleClick = event => {
    if (customColumn === 'none') {
      setcustomColumn('block');
    } else {
      setcustomColumn('none');
    }
  };

  const handleClose = () => {
    setcustomColumn('none');
  };

  return (
    <React.Fragment>
      <div className={classes.table}>
        {props.isdetailPanel ? (
          <MaterialTable
            style={{ padding: '20px' }}
            icons={tableIcons}
            title={props.title}
            columns={props.column}
            data={props.data}
            options={{
              columnsButton: true,
              emptyRowsWhenPaging: false,
              padding: 'dense',
              selection: !!props.selection ? true : false,
              //selectionProps: props.selectionProps ? props.selectionProps : '',
              selectionProps: rowData => ({
                disabled: rowData['Inverter Name'] === 'INV1_1',
                color: 'primary',
              }),
              headerStyle: {
                backgroundColor: '#B2DF8A',
                color: '#005635',
                whiteSpace: 'nowrap',
                zIndex: 0,
              },

              exportButton: {
                csv: true,
                pdf: false,
              },
              exportCsv: (columns, data) => {
                const columnTitles = columns.map(columnDef => columnDef.title);

                const csvData = data.map(rowData =>
                  columns.map(columnDef => {
                    if (isObject(rowData[columnDef.field])) {
                      return rowData[columnDef.field].props.children;
                    } else if (columnDef.type === 'date') {
                      return getFormattedDate(rowData[columnDef.field], 'YYYY-MM-DD HH:mm:ss');
                    } else {
                      return rowData[columnDef.field];
                    }
                  }),
                );
                const builder = new CsvBuilder(`${props.title}.csv`)
                  .setColumns(columnTitles)
                  .addRows(csvData)
                  .exportFile();

                return builder;
              },
              rowStyle: (rowData, index) => ({
                backgroundColor: index % 2 === 0 ? '#fff' : '#F2F6F8',
              }),
              actionsColumnIndex: -1,
              detailPanelColumnAlignment: 'right',
              filtering: props.filter,
              showSelectAllCheckbox: true,
              search: props.search,
            }}
            components={{
              Pagination: PatchedPagination,
              Toolbar: props => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            localization={{
              toolbar: { searchPlaceholder: props.searchPlaceholder },
            }}
            actions={action.length !== 0 ? [...action] : []}
            detailPanel={[...detailPanel]}
          />
        ) : (
          <MaterialTable
            style={{ padding: '20px' }}
            tableRef={props.tableRef}
            icons={tableIcons}
            title={props.title}
            columns={props.column}
            data={props.data}
            options={{
              columnsButton: true,
              emptyRowsWhenPaging: false,
              padding: 'dense',
              selection: !!props.selection ? true : false,
              selectionProps: props.selectionProps,
              headerStyle: {
                backgroundColor: '#B2DF8A',
                color: '#005635',
                whiteSpace: 'nowrap',
                zIndex: 0,
              },
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportCsv: (columns, data) => {
                const columnTitles = columns.map(columnDef => columnDef.title);
                const csvData = data.map(rowData =>
                  columns.map(columnDef => {
                    if (isObject(rowData[columnDef.field])) {
                      return rowData[columnDef.field].props.children;
                    } else if (columnDef.type === 'date') {
                      console.log();
                      return moment(rowData[columnDef.field]).format('DD/MM/YYYY');
                    } else {
                      return rowData[columnDef.field];
                    }
                  }),
                );
                const builder = new CsvBuilder(`${props.title}.csv`)
                  .setColumns(columnTitles)
                  .addRows(csvData)
                  .exportFile();

                return builder;
              },
              rowStyle: (rowData, index) => ({
                backgroundColor: index % 2 === 0 ? '#fff' : '#F2F6F8',
              }),
              actionsColumnIndex: -1,
              filtering: props.filter,
              search: props.search,
            }}
            components={{
              Pagination: PatchedPagination,
              Toolbar: props => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            localization={{
              toolbar: { searchPlaceholder: props.searchPlaceholder },
            }}
            actions={action.length !== 0 ? [...action] : []}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default memo(OptimaxTable);
